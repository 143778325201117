import {useEffect, useRef} from "react"

export default function InputAutosize({minWidth, defaultWidth, ...props}) {
    const inputRef = useRef()

    const updateSize = (el) => {
        if (!el) return
        if (el.value) {
            el.style.width = minWidth
            el.style.width = el.scrollWidth + "px"
        } else el.style.width = defaultWidth
    }

    useEffect(() => {
        updateSize(inputRef.current)
    }, [])
    useEffect(() => {
        updateSize(inputRef.current)
    }, [props.value])

    useEffect(() => {
        window.addEventListener("resize", () => updateSize(inputRef.current))
        return () => window.removeEventListener("resize", () => updateSize(inputRef.current))
    }, [])

    return <input {...props} style={{...props.style, minWidth, width: defaultWidth, transition: "none"}} ref={inputRef}
                  onChange={(e) => {
                      !props.value && updateSize(e.currentTarget)
                      props.onChange && props.onChange(e)
                  }}/>
}