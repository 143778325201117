import { Component } from 'react'
import { COLORS } from '../constants'

export default class ErrorBoundary extends Component {
	state = {
		error: null,
	}
	static getDerivedStateFromError(error) {
		return { error }
	}
	render() {
		const { error } = this.state

		if (error) {
			console.error(error)
			return (
				<div className='m-auto py-5 h-50 justify-content-center text-center'>
					<p
						style={{
							color: COLORS.primary,
							fontSize: '120px',
							fontWeight: '400',
						}}
					>
						Упс
					</p>
					<p
						style={{ color: COLORS.text, fontSize: '24px', fontWeight: '300' }}
					>
						На сайте технические неполадки!
					</p>
					<p
						className='mb-5'
						style={{ color: COLORS.text, fontSize: '24px', fontWeight: '300' }}
					>
						Мы работаем над устранением этой проблемы.
					</p>
					<a
						href='/'
						className='btn btn-md p-3 mx-auto mt-3'
						style={{
							maxWidth: '250px',
							backgroundColor: COLORS.secondary,
							color: COLORS.primary,
						}}
					>
						Перейти на главную
					</a>
				</div>
			)
		}
		return this.props.children
	}
}
