import {SOURCE} from "../constants"
import * as yup from "yup"
import {showErrorMsg} from "../store/slices/alertSlice"

export const CreateAiMessageSchema = yup.object({
    question: yup
        .string()
        .required()
        .min(4, 'сообщение должно быть не менее 4 символов')
        .max(4000, 'максимальная длина сообщения - 4000 символов'),
})

export async function createAiChat(token, applicantId) {
    try {
        const response = await fetch(
            `${SOURCE.url}applicantAiChats`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({
                    applicantId: applicantId
                })
            }
        )
        if (!response.ok) return false
        return await response.json()
    } catch (error) {
        console.error(error)
        return false
    }
}

export async function createAiMessage(token, chatId, question, dispatch) {
    try {
        try {
            await CreateAiMessageSchema.validate({ question: question })
        } catch (err) {
            dispatch(showErrorMsg(err.errors))
            return false
        }

        const response = await fetch(
            `${SOURCE.url}applicantAiChatMessages`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({
                    chatId: chatId,
                    question: question
                })
            }
        )
        if (!response.ok) {
            await dispatch(showErrorMsg('Ошибка'))
            return false
        }
        return await response.json()
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getAiChat = async (token, chatId, queryObj = {}) => {
    try {
        let query = '?'

        let include = queryObj.include || []
        include.forEach(el => {
            if (query !== '?') query += '&'
            query += 'include=' + el
        })
        const response = await fetch(
            `${SOURCE.url}applicantAiChats/` + chatId +  query,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
            }
        )

        if (!response.ok) return null
        return await response.json()
    } catch (e) {
        console.error(e)
        return null
    }
}