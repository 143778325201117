import ProfileResumeBlock from "../../../components/ProfileResumeBlock/ProfileResumeBlock"
import ProfileMeetingsBlock from "../../../components/ProfileMeetingsBlock/ProfileMeetingsBlock"
import ProfileOffersBlock from "../../../components/ProfileOffersBlock/ProfileOffersBlock"
import {useSelector} from "react-redux"
import {useEffect, useState} from "react"
import { SOURCE } from "../../../constants"
import {getFullOffers} from "../../../api/offers"

export default function ProfilePage() {
    const user = useSelector(state => state.userSlice.user)
    const meetings = useSelector(state => state.userSlice.meetingsData.meetings)
    const resume = useSelector(state => state.resumeSlice.resume)
    const [state, setState] = useState({
        offers: [],
    })

    useEffect(() => {
        getFullOffers(user.access_token, { include: ['vacancyResponse.vacancy.employer'] }).then(data => data && setState(p => ({...p, offers: data})))
    }, [user.access_token])


    return <div className="row gap-2">
        <div className="col ">
            <div className="bg-white rounded-3 p-sm-5 p-4 w-sm-max d-flex flex-column gap-3 gap-sm-4">
                <ProfileResumeBlock resume={resume || {}} user={user} avatarUrl={SOURCE.url + `applicants/${user.id}/avatar`} />
            </div>
        </div>
        <div className="col-xl-3 d-flex flex-column gap-sm-4 gap-3">
            <span className="fs-3 fw-semibold">Встречи</span>
            <ProfileMeetingsBlock meetings={meetings.filter(m => m.status === "PLANNED")} />
            <span className="fs-3 fw-semibold mt-3">Приглашения</span>
            <ProfileOffersBlock offers={ state.offers || []} setParentState={setState} />
        </div>
    </div>
}