import { useSelector } from 'react-redux'
import { AppCheckbox } from '../../../components/ui/AppCheckbox'
import { AppSelect } from '../../../components/ui/AppSelect'
import { AppSwitch } from '../../../components/ui/AppSwitch/AppSwitch'
import InputAutosize from '../../../components/ui/InputAutosize'
import { COLORS } from '../../../constants'
import { resetFilters } from '../../../store/slices/vacanciesSlice'

export const FiltersBlock = ({ filters, changeFilter, dispatch, header = true }) => {
	const vacanciesInfo = useSelector(state => state.vacanciesSlice.vacanciesInfo)
	return (
		<div className='bg-white rounded p-sm-4'>
			{header && (
				<div className='d-flex flex-wrap align-items-center mb-lg-5 mb-3'>
					<p className='fs-3 fw-bolded me-auto'>Фильтр</p>
					<p
						className='text-decoration-underline'
						style={{ cursor: 'pointer' }}
						onClick={() => dispatch(resetFilters())}
					>
						Сбросить фильтры
					</p>
				</div>
			)}

			<p className='fs-5 fw-bolded mb-2'>Локация</p>
			<AppSelect
				value={filters.city}
				options={vacanciesInfo.cities}
				selectClassName=' p-2 rounded fs-6'
				onChange={e => changeFilter({ city: e.target.value })}
			/>

			<p className='fs-5 mt-md-5 mb-3 mt-4 fw-bolded'>Занятость</p>
			<AppCheckbox
				label='Полная занятость'
				checked={filters.employmentType.includes('FULL_TIME')}
				className='mb-sm-3 mb-2'
				size='1.4em'
				onChange={e =>
					changeFilter({
						employmentType: filters.employmentType.includes('FULL_TIME')
							? filters.employmentType.filter(t => t !== 'FULL_TIME')
							: [...filters.employmentType, 'FULL_TIME'],
					})
				}
			/>
			<AppCheckbox
				label='Частичная занятость'
				checked={filters.employmentType.includes('PART_TIME')}
				className='mb-sm-3 mb-2'
				size='1.4em'
				onChange={e =>
					changeFilter({
						employmentType: filters.employmentType.includes('PART_TIME')
							? filters.employmentType.filter(t => t !== 'PART_TIME')
							: [...filters.employmentType, 'PART_TIME'],
					})
				}
			/>
			<AppCheckbox
				label='Стажировка'
				checked={filters.employmentType.includes('PROBATION')}
				className='mb-3'
				size='1.4em'
				onChange={e =>
					changeFilter({
						employmentType: filters.employmentType.includes('PROBATION')
							? filters.employmentType.filter(t => t !== 'PROBATION')
							: [...filters.employmentType, 'PROBATION'],
					})
				}
			/>
			<div className='d-flex align-items-center'>
				<AppSwitch
					value={filters.workplaceModel.indexOf('REMOTE') >= 0}
					className='me-3'
					onChange={() =>
						changeFilter({
							workplaceModel:
								filters.workplaceModel.indexOf('REMOTE') < 0
									? [...filters.workplaceModel, 'REMOTE']
									: filters.workplaceModel.filter(el => el !== 'REMOTE'),
						})
					}
				/>
				<p className='fs-6'>Можно удалённо</p>
			</div>
			<p className='fs-5 mt-md-5 mb-3 mt-4 fw-bolded'>Опыт работы</p>
			<AppCheckbox
				label='Нет опыта работы'
				checked={filters.experience.includes('NO_EXPERIENCE')}
				className='mb-sm-3 mb-2'
				size='1.4em'
				onChange={e =>
					changeFilter({
						experience: filters.experience.includes('NO_EXPERIENCE')
							? filters.experience.filter(t => t !== 'NO_EXPERIENCE')
							: [...filters.experience, 'NO_EXPERIENCE'],
					})
				}
			/>
			<AppCheckbox
				label='от 1-го до 3-х лет'
				checked={filters.experience.includes('BETWEEN_1_AND_3')}
				className='mb-sm-3 mb-2'
				size='1.4em'
				onChange={e =>
					changeFilter({
						experience: filters.experience.includes('BETWEEN_1_AND_3')
							? filters.experience.filter(t => t !== 'BETWEEN_1_AND_3')
							: [...filters.experience, 'BETWEEN_1_AND_3'],
					})
				}
			/>
			<AppCheckbox
				label='от 3-х до 6-ти лет'
				checked={filters.experience.includes('BETWEEN_3_AND_6')}
				className='mb-sm-3 mb-2'
				size='1.4em'
				onChange={e =>
					changeFilter({
						experience: filters.experience.includes('BETWEEN_3_AND_6')
							? filters.experience.filter(t => t !== 'BETWEEN_3_AND_6')
							: [...filters.experience, 'BETWEEN_3_AND_6'],
					})
				}
			/>
			<AppCheckbox
				label='Более 6 лет'
				checked={filters.experience.includes('MORE_THAN_6')}
				size='1.4em'
				onChange={e =>
					changeFilter({
						experience: filters.experience.includes('MORE_THAN_6')
							? filters.experience.filter(t => t !== 'MORE_THAN_6')
							: [...filters.experience, 'MORE_THAN_6'],
					})
				}
			/>

			<p className='fs-5 mt-md-5 mt-4 mb-3 fw-bolded'>Зарплата</p>
			<div className='row gy-2'>
				<div className='col-auto'>
					<p className='fs-6'>
						от
						<span className='mx-2'>
							<InputAutosize
								defaultWidth='4.5ch'
								minWidth='4.5ch'
								value={filters.salary.gte.toLocaleString()}
								className='rounded px-1 text-center'
								style={{ border: '0.5px solid ' + COLORS.header_text }}
								onChange={e =>
									changeFilter({
										salary: {
											...filters.salary,
											gte: parseInt(e.target.value.replaceAll(/\s/g, '')) || '',
										},
									})
								}
							/>
						</span>
						тыс.
					</p>
				</div>
				<div className='col'>
					<p className='fs-6'>
						до
						<span className='mx-2'>
							<InputAutosize
								defaultWidth='4.5ch'
								value={filters.salary.lte.toLocaleString()}
								minWidth='4.5ch'
								className='rounded px-1 text-center'
								style={{ border: '0.5px solid ' + COLORS.header_text }}
								onChange={e =>
									changeFilter({
										salary: {
											...filters.salary,
											lte: parseInt(e.target.value.replaceAll(/\s/g, '')) || '',
										},
									})
								}
							/>
						</span>
						тыс.
					</p>
				</div>
			</div>
			<div className='d-flex align-items-center mt-4'>
				<AppSwitch
					value={filters.isStartup}
					className='me-3'
					onChange={() => changeFilter({ isStartup: !filters.isStartup })}
				/>
				<p className='fs-6'>Только стартапы</p>
			</div>
		</div>
	)
}
