import {SOURCE} from "../constants"

export const createToken = async (data) => {
    try {
        const response = await fetch(SOURCE.url + "auth/accessToken?role=APPLICANT", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        if (!response.ok && response.status !== 401) return false
        return await response.json()
    } catch {
        return false
    }
}

export const createApplicant = async (data) => {
    try {
        const response = await  fetch(SOURCE.url + "auth/applicant", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        return response.status
    } catch {
        return false
    }

}

export async function importHHResume(access_token, id) {
    try {
        return await fetch(`${SOURCE.url}hh/resumes/${id}/import`, {
            method: "POST",
            headers: {
                "Authorization": access_token
            },
        })
    } catch {
        return false
    }
}

export async function googleAuth(googleToken) {
    try {
        const response = await fetch(SOURCE.url + "auth/withGoogle", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({role: "APPLICANT", googleToken})
        })
        if (!response.ok) return false
        return await response.json()
    } catch {
        return false
    }
}

export async function googleRegisterApplicant(data) {
    try {
        const response = await fetch(SOURCE.url + "auth/withGoogle/applicant", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        if (!response.ok) return false
        return await response.json()
    } catch {
        return false
    }
}

export async function hhAuth(authorizationCode) {
    try {
        const response = await fetch(SOURCE.url + "auth/withHH", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({authorizationCode, role: "APPLICANT"})
        })
        return [await response.json(), response.status]
    } catch {
        return false
    }
}

export async function gazAuth(authorizationCode) {
    try {
        const response = await fetch(SOURCE.url + "auth/withGazprom", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({authorizationCode, role: "APPLICANT"})
        })
        return [await response.json(), response.status]
    } catch {
        return false
    }
}

export async function hhRegisterApplicant(data) {
    try {
        const response = await fetch(SOURCE.url + "auth/withHH/applicant", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        return [await response.json(), response.status]
    } catch {
        return false
    }
}

export async function gazRegisterApplicant(data) {
    try {
        const response = await fetch(SOURCE.url + "auth/withGazprom/applicant", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        return [await response.json(), response.status]
    } catch {
        return false
    }
}

export const getHHAuthUrl = async () => {
    try {
        const res = await fetch(SOURCE.url + 'auth/HhAuthorizeUrl')
        if (!res.ok) return ""
        return await res.json()
    } catch (error) {
        console.error(error)
        return null
    }
}

export const getGazpromAuthUrl = async () => {
    try {
        const res = await fetch(SOURCE.url + 'auth/GazpromAuthorizeUrl')
        if (!res.ok) return ""
        return await res.json()
    } catch (error) {
        console.error(error)
        return null
    }
}

export const getEmailEnterCode = async (mail) => {
    try {
        const response = await fetch(SOURCE.url + 'auth/getEmailCode', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: mail,
                role: 'APPLICANT',
            }),
        })
        return response.ok
    } catch (error) {
        console.error(error)
        return false
    }
}

export const submitEmailEnterCode = async (mail, code) => {
    try {
        const response = await fetch(SOURCE.url + 'auth/withEmailCode', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: mail,
                code: code,
                role: 'APPLICANT',
            }),
        })
        if (!response.ok) return false
        const result = await response.json()
        return result.token
    } catch (error) {
        console.error(error)
        return false
    }
}