import html2pdf from 'html2pdf.js'
import { useState } from 'react'
import { renderToString } from 'react-dom/server'
import { COLORS } from '../../constants'
import { PageOne } from '../PdfResumePages/pageOne/PageOne'
import { PageThree } from '../PdfResumePages/pageThree/PageThree'
import { PageTwo } from '../PdfResumePages/pageTwo/PageTwo'
import styles from './styles.module.css'

export const SaveResumeButton = ({ resume, user }) => {
  const [visible, setVisible] = useState(false)

  function openDropdownClickHandler() {
    setVisible(true)
  }

  function closeDropdownClickHandler() {
    setVisible(false)
  }

  function downloadPdfClickHandler(pageDownload) {
    const elem = renderToString(pageDownload)
    html2pdf()
      .from(elem)
      .set({
        filename: `${user.firstName} ${user.lastName}.pdf`,
        margin: [20, 4],
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
      })
      .save()
  }

  return (
    <>
      <div className={`col-12 col-sm ${styles.dropdown}`}>
        {console.log(resume)}
        <button
          className='w-100 px-3 py-2 rounded-1 fs-6'
          style={{ backgroundColor: COLORS.neutral }}
          onClick={() =>
            downloadPdfClickHandler(
              <PageThree props={resume} id={resume.id} user={user} />
            )
          }
        >
          Скачать резюме
        </button>
        {visible && (
          <ul
            className={styles.list}
            onMouseLeave={closeDropdownClickHandler}
            style={{ marginTop: '-100px' }}
          >
            <li
              className={`btn w-60 ${styles.item}`}
              onClick={() =>
                downloadPdfClickHandler(
                  <PageOne props={resume} id={resume.id} user={user} />
                )
              }
            >
              <span>Простой</span>
            </li>
            <li
              className={`btn w-60 ${styles.item}`}
              onClick={() =>
                downloadPdfClickHandler(
                  <PageTwo props={resume} id={resume.id} user={user} />
                )
              }
            >
              <span>Современный</span>
            </li>
            <li
              className={`btn w-60 ${styles.item}`}
              onClick={() =>
                downloadPdfClickHandler(
                  <PageThree props={resume} id={resume.id} user={user} />
                )
              }
            >
              <span>Профессиональный</span>
            </li>
            <button
              className={`btn-close ${styles.closeBtn}`}
              type='button'
              aria-label='Close'
              onClick={closeDropdownClickHandler}
            ></button>
          </ul>
        )}
      </div>
    </>
  )
}
