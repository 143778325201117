import { GoogleLogin } from '@react-oauth/google'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import {
    createApplicant,
    createToken,
    googleAuth,
    googleRegisterApplicant,
    getHHAuthUrl,
    hhAuth,
    hhRegisterApplicant,
    gazRegisterApplicant,
		getGazpromAuthUrl,
		gazAuth
} from '../../../api/auth'
import { AppInput } from '../../../components/ui/AppInput'
import { COLORS, Validation } from '../../../constants'
import { ReactComponent as CheckLineSvg } from '../../../icons/check_line.svg'
import { ReactComponent as GoogleLogoSvg } from '../../../icons/google-logo.svg'
import { showErrorMsg } from '../../../store/slices/alertSlice'
import { setAuth } from '../../../store/slices/userSlice'

export const AuthLayout = () => {
	const props = useLocation()
	const [state, setState] = useState({
		hasAccount: props.state !== null,
		hhAuthUrl: '#',
		gazAuthUrl: '#',
		access_token: '',
		fio: '',
		email: props.state !== null ? props.state.email : '',
		contact: '',
		birthDate: '',
		password: '',
		hhToken: null,
		gazToken: null,
		googleToken: null,
		googleEmailRequired: false,
	})
	const navigate = useNavigate()
	const dispatch = useDispatch()

	useEffect(() => {
		if (state.googleToken)
			googleLoginAttempt(state, setState, navigate, dispatch)
	}, [state.googleToken])

	useEffect(() => {
		getHHAuthUrl().then(data => setState(p => ({ ...p, hhAuthUrl: data })))
		if (props.state != null && props.state.hhCode)
			hhLoginAttempt(props.state.hhCode, setState, navigate, dispatch)
	}, [])

	useEffect(() => {
		getGazpromAuthUrl().then(data => setState(p => ({ ...p, gazAuthUrl: data })))
		console.log(props.state)
		if (props.state != null && props.state.gazCode)
			gazLoginAttempt(props.state.gazCode, setState, navigate, dispatch, state)
	}, [])

	console.log('STATE:', state)

	return (
		<form
			onSubmit={e => e.preventDefault()}
			className='w-100 d-flex flex-column mx-auto'
			style={{ maxWidth: '750px' }}
		>
			<p className='text-center fs-1 mb-3' style={{ fontWeight: '400' }}>
				{state.hasAccount ? 'Вход' : 'Регистрация'}
			</p>
			{/* <p className="text-center my-3" style={{ fontSize: '20px', fontWeight: '200' }}>{ state.hasAccount ? 'Давно не виделись! Самое время искать работу, не так ли?' : 
            'Мы рады, что ты согласился найти работу с нами. Мы обещаем не подвести тебя и сделать всё максимально быстро, а пока введи адрес своей электронной почты'}
        </p> */}

			{!state.hasAccount && (
				<AppInput
					className=''
					label='Фамилия, Имя и Отчество'
					value={state.fio}
					showAlert={!Validation.validFio(state.fio)}
					onChange={v => setState(prev => ({ ...prev, fio: v }))}
				/>
			)}
			<div className='row'>
				{!state.hasAccount && (
					<div className='col-sm'>
						<AppInput
							label='Способ связи'
							value={state.contact}
							showAlert={state.contact === ''}
							onChange={v => setState(prev => ({ ...prev, contact: v }))}
						/>
					</div>
				)}
				{(state.googleToken === null || state.googleEmailRequired) && (
					<div className='col'>
						<AppInput
							label='Электронная почта'
							value={state.email}
							showAlert={!Validation.validMail(state.email)}
							onChange={v => setState(prev => ({ ...prev, email: v.trim() }))}
						/>
					</div>
				)}
			</div>
			<div className='row'>
				{!state.hasAccount && (
					<div className='col'>
						<AppInput
							label='Дата рождения'
							type='text'
							showAlert={!Validation.validDate(state.birthDate)}
							value={formatDateString(
								state.birthDate.split('T')[0].split('-').reverse().join('.')
							)}
							onChange={v =>
								setState(p => ({
									...p,
									birthDate: formatDateForServer(v),
								}))
							}
							disabled={false}
						/>
					</div>
				)}
				{state.googleToken === null && state.hhToken === null && state.gazToken === null && (
					<div className='col'>
						<AppInput
							label='Пароль'
							showAlert={!Validation.validPassword(state.password)}
							value={state.password}
							showEye={true}
							onChange={v =>
								setState(prev => ({ ...prev, password: v.trim() }))
							}
						/>
					</div>
				)}
			</div>

			<div className='d-flex flex-row flex-wrap-reverse align-items-center mt-3 gap-2'>
				{state.hasAccount ? (
					<div className='d-flex flex-column mb-2'>
						<p
							className='mb-1'
							style={{ color: COLORS.primary, cursor: 'pointer' }}
							onClick={() => setState(p => ({ ...p, hasAccount: false }))}
						>
							Зарегистрироваться
						</p>
						<p
							className='mb-1'
							style={{ color: COLORS.primary, cursor: 'pointer' }}
							onClick={() => navigate('enter_mail')}
						>
							Вход по почте
						</p>
						<p
							onClick={() =>
								navigate('forgot_password', { state: { email: state.email } })
							}
							style={{ color: COLORS.danger, cursor: 'pointer' }}
						>
							Забыли пароль?
						</p>
					</div>
				) : (
					<div className='d-flex flex-column mb-2'>
						<p
							className='mb-1'
							style={{ color: COLORS.primary, cursor: 'pointer' }}
							onClick={() => setState(p => ({ ...p, hasAccount: true }))}
						>
							Уже есть аккаунт
						</p>
						<p
							onClick={() => window.open('https://hunt.hurtle.ru/')}
							style={{ color: COLORS.danger, cursor: 'pointer' }}
						>
							Я ищу сотрудника
						</p>
					</div>
				)}
				<div className='flex-grow-1'></div>
				{!state.hhToken &&
					(state.googleToken ? (
						<div
							className='p-2 me-2 rounded-pill light_light_primary'
							style={{ border: '1px solid #dadce0' }}
						>
							<GoogleLogoSvg />
							<CheckLineSvg
								fill={COLORS.success}
								className='ms-2'
								style={{ width: '18px', height: '12px' }}
							/>
						</div>
					) : (
						<GoogleLogin
							onSuccess={r =>
								setState(p => ({ ...p, googleToken: r.credential }))
							}
							locale='ru_RU'
							type='icon'
							text={state.hasAccount ? 'signin_with' : 'signup_with'}
							shape='pill'
						/>
					))}
				{!state.googleToken &&
					(state.hhToken ? (
						<div
							className='p-2 me-2 rounded-pill light_light_primary'
							style={{ border: '1px solid #dadce0' }}
						>
							<img
								src={require('../../../images/auth/hh-red-logo.png')}
								alt='hh logo'
								style={{ width: '24px', height: '24px' }}
							/>
							<CheckLineSvg
								fill={COLORS.success}
								className='ms-2'
								style={{ width: '18px', height: '12px' }}
							/>
						</div>
					) : (
						<a
							className='rounded-circle p-1 d-flex justify-content-center align-items-center'
							href={state.hhAuthUrl || '#'}
							style={{
								border: '1px solid #dadce0',
								width: '40px',
								height: '40px',
							}}
						>
							<img
								src={require('../../../images/auth/hh-red-logo.png')}
								alt='hh logo'
								style={{ width: '20px', height: '20px' }}
							/>
						</a>
					))}
					{/* {!state.gazToken && <a
							className='rounded-circle p-1 d-flex bg-white justify-content-center align-items-center'
							href={state.gazAuthUrl || '#'}
							style={{
								border: '1px solid #dadce0',
								width: '40px',
								height: '40px',
							}}
						>
							<img
								src={require('../../../images/auth/gaz-logo.jpg')}
								alt='hh logo'
								style={{ width: '25px', height: '25px' }}
							/>
						</a>} */}
				<button
					className='btn py-2 px-4  rounded-pill primary'
					onClick={() => login(state, setState, navigate, dispatch)}
					style={{ maxWidth: '200px' }}
				>
					{state.hasAccount ? 'Войти' : 'Продолжить'}
				</button>
			</div>
		</form>
	)
}

const formatDateString = dateString => {
	const digits = dateString.replace(/\D/g, '')
	let formatted = ''
	if (digits.length > 0) {
		formatted += digits.substring(0, 2)
	}
	if (digits.length > 2) {
		formatted += '.' + digits.substring(2, 4)
	}
	if (digits.length > 4) {
		formatted += '.' + digits.substring(4, 8)
	}
	return formatted
}

const formatDateForServer = dateString => {
	const [day, month, year] = dateString.split('.')
	if (year && month && day) {
		return `${year}-${month}-${day}`
	}
	return dateString
}

async function googleLoginAttempt(state, setState, navigate, dispatch) {
	const data = await googleAuth(state.googleToken)
	if (!data) {
		setState(p => ({ ...p, googleToken: null }))
		dispatch(
			showErrorMsg('Ошибка авторизации через Google. Повторите попытку.')
		)
	} else if (Object.prototype.hasOwnProperty.call(data, 'token')) {
		dispatch(setAuth({ access_token: data.token }))
		navigate('/auth/next', { state: { step: 'login' } })
	} else {
		dispatch(showErrorMsg())
		setState(p => ({
			...p,
			hasAccount: false,
			fio:
				p.fio ||
				`${(data.googleAccount || {}).familyName} ${
					(data.googleAccount || {}).givenName
				}`,
			email: null,
			googleEmailRequired: !(data.googleAccount || {}).email,
		}))
		return 'register'
	}
}

async function hhLoginAttempt(hhCode, setState, navigate, dispatch) {
	const [data, status] = await hhAuth(hhCode)
	if (status === 403) {
		setState(p => ({ ...p, hhToken: null }))
		dispatch(showErrorMsg('Данный аккаунт hh.ru не является соискателем.'))
	} else if (!data || status === 401) {
		setState(p => ({ ...p, hhToken: null }))
		dispatch(showErrorMsg('Ошибка авторизации через hh.ru. Повторите попытку.'))
	} else if (Object.prototype.hasOwnProperty.call(data, 'token')) {
		dispatch(setAuth({ access_token: data.token }))
		navigate('/auth/next', { state: { step: 'login' } })
	} else {
		if (!data.hhToken) return
		dispatch(showErrorMsg())
		setState(p => ({
			...p,
			hasAccount: false,
			hhToken: data.hhToken,
			fio:
				p.fio ||
				`${(data.hhAccount || {}).lastName} ${
					(data.hhAccount || {}).firstName
				} ${(data.hhAccount || {}).middleName || ''}`,
			contact: p.contact || (data.hhAccount || {}).phone || '',
			email: p.email || (data.hhAccount || {}).email || '',
		}))
		return 'register'
	}
}

async function gazLoginAttempt(gazCode, setState, navigate, dispatch, state) {
	const [data, status] = await gazAuth(gazCode)
	console.log(data, status, gazCode)
	if (status === 403) {
		setState(p => ({ ...p, gazToken: null }))
		dispatch(showErrorMsg('Данный аккаунт gaz.ru не является соискателем.'))
	} else if (!data || status === 401) {
		setState(p => ({ ...p, gazToken: null }))
		dispatch(showErrorMsg('Ошибка авторизации через gaz.ru. Повторите попытку.'))
	} else if (Object.prototype.hasOwnProperty.call(data, 'token')) {
		dispatch(setAuth({ access_token: data.token }))
		navigate('/auth/next', { state: { step: 'login' } })
	} else {
		console.log(data)
		if (!data.gazpromToken) return
		dispatch(showErrorMsg())
		setState(p => ({
			...p,
			hasAccount: false,
			gazToken: data.gazpromToken,
			fio:
				p.fio ||
				`${(data.gazAccount || {}).lastName} ${
					(data.gazAccount || {}).firstName
				} ${(data.gazAccount || {}).middleName || ''}`,
			contact: p.contact || (data.gazAccount || {}).phone || '',
			email: p.email || (data.gazAccount || {}).email || '',
			phone: data.phone || '',
			gender: data.gender || '',
			nickname: data.nickname || ''
		}))
		const [lastName, firstName, middleName] = state.fio.trim().split(' ')

		// registerGaz(state, firstName, middleName, lastName, setState, dispatch, navigate)

		return 'register'
	}
}

async function login(state, setState, navigate, dispatch) {
	const [lastName, firstName, middleName] = state.fio.trim().split(' ')

	if (state.contact === '') {
		state.contact = state.email
	}
	if (
		!state.hasAccount &&
		!Validation.validFIO(lastName, firstName, middleName)
	) {
		dispatch(
			showErrorMsg(
				'Неверно указано ФИО\nФормат: минимум 2 символа на имя/фамилию\nОтчество не обязательно'
			)
		)
	} else if (!Validation.validMail(state.email) && state.googleToken === null) {
		dispatch(showErrorMsg('Неверный формат почты'))
	} else if (
		!Validation.validPassword(state.password) &&
		state.googleToken === null &&
		state.gazToken === null &&
		state.hhToken === null
	) {
		dispatch(
			showErrorMsg(
				'Пароль должен содержать латинские заглавные и прописные буквы и цифры и быть не короче 8 символов'
			)
		)
	} else if (!state.hasAccount && !Validation.validDate(state.birthDate)) {
		dispatch(
			showErrorMsg(
				`Неверный формат даты\n(От 1900 до ${
					new Date().getFullYear() - 13
				}) года`
			)
		)
	} else if (!state.hasAccount && !Validation.validPhone(state.contact)) {
		dispatch(showErrorMsg('Укажите способ связи'))
	} else if (state.googleToken !== null) {
		if (
			(await googleLoginAttempt(state, setState, navigate, dispatch)) !==
			'register'
		)
			return
		if (state.hasAccount) {
			dispatch(
				showErrorMsg(
					'Не найден профиль с привязанным Google-аккаунтом. Зарегистрируйтесь сейчас'
				)
			)
		} else {
			const data = await googleRegisterApplicant({
				contact: state.contact.trim(),
				birthDate: state.birthDate,
				lastName: lastName,
				firstName: firstName,
				middleName: middleName || null,
				email: state.email,
				googleToken: state.googleToken,
			})
			if (!data || !Object.prototype.hasOwnProperty.call(data, 'token')) {
				setState(p => ({
					...p,
					googleToken: null,
					googleEmailRequired: false,
				}))
				dispatch(
					showErrorMsg('Ошибка авторизации через Google. Повторите попытку.')
				)
			} else {
				dispatch(setAuth({ access_token: data.token }))
				navigate('/auth/next', { state: { step: 'login' } })
			}
		}
	} else if (state.hhToken !== null) {
		if (state.hasAccount) {
			dispatch(
				showErrorMsg(
					'Не найден профиль с привязанным аккаунтом hh.ru. Зарегистрируйтесь сейчас'
				)
			)
		} else {
			const [data, status] = await hhRegisterApplicant({
				contact: state.contact.trim(),
				birthDate: state.birthDate,
				email: state.email,
				lastName: lastName,
				firstName: firstName,
				middleName: middleName || null,
				hhToken: state.hhToken,
				_authBy: 'HH_TOKEN',
			})
			if (status === 403) {
				setState(p => ({ ...p, hhToken: null }))
				dispatch(showErrorMsg('Данный аккаунт hh.ru не является соискателем.'))
			} else if (status === 409) {
				setState(p => ({ ...p, hhToken: null }))
				dispatch(showErrorMsg('Данный аккаунт hh.ru уже зарегистрирован.'))
			} else if (status === 401 || !data || !Object.prototype.hasOwnProperty.call(data, 'token')) {
				setState(p => ({ ...p, hhToken: null }))
				dispatch(
					showErrorMsg('Ошибка авторизации через hh.ru. Повторите попытку.')
				)
			} else {
				dispatch(setAuth({ access_token: data.token }))
				navigate('/auth/next', { state: { step: 'login' } })
			}
		}
	} else if (state.gazToken !== null) {
		console.log(494, state)
		if (state.hasAccount) {
			dispatch(
				showErrorMsg(
					'Не найден профиль с привязанным аккаунтом gaz.ru. Зарегистрируйтесь сейчас'
				)
			)
		} else {
			registerGaz(state, firstName, middleName, lastName, setState, dispatch, navigate)
		}
	} else if (state.hasAccount) {
		const data = await createToken({
			login: state.email.trim(),
			password: state.password.trim(),
		})
		if (!data) return dispatch(showErrorMsg('Ошибка при создании токена'))
		if (!data.token)
			return dispatch(showErrorMsg('Неверный логин и/или пароль!'))
		dispatch(setAuth({ access_token: data.token }))
		navigate('/auth/next', { state: { step: 'login' } })
	} else {
		const status = await createApplicant({
			email: state.email,
			contact: state.contact.trim(),
			birthDate: state.birthDate,
			password: state.password.trim(),
			lastName: lastName,
			firstName: firstName,
			middleName: middleName || null,
		})
		if (status === 204) {
			return login(
				{
					...state,
					hasAccount: true,
				},
				setState,
				navigate,
				dispatch
			)
		}
		if (status === 409)
			return dispatch(
				showErrorMsg('Пользователь с такой почтой уже существует!')
			)
		dispatch(showErrorMsg('Произошла ошибка на сервере. Повторите попытку'))
	}
}


const registerGaz = async (state, firstName, middleName, lastName, setState, dispatch, navigate) => {
	console.log('register')
	const [data, status] = await gazRegisterApplicant({
		email: state.email,
		contact: state.contact.trim(),
		firstName: firstName,
		middleName: middleName || null,
		lastName: lastName,
		phone: null,
		birthDate: state.birthDate,
		gender: 'MALE',
		nickname: null,
		gazpromToken: state.gazToken,
		_authBy: 'GAZPROM_TOKEN',
	})
	if (status === 403) {
		setState(p => ({ ...p, gazToken: null }))
		dispatch(showErrorMsg('Данный аккаунт gaz.ru не является соискателем.'))
	} else if (status === 409) {
		setState(p => ({ ...p, gazToken: null }))
		dispatch(showErrorMsg('Данный аккаунт gaz.ru уже зарегистрирован.'))
	} else if (status === 401 || !data || !Object.prototype.hasOwnProperty.call(data, 'token')) {
		setState(p => ({ ...p, gazToken: null }))
		dispatch(
			showErrorMsg('Ошибка авторизации через gaz.ru. Повторите попытку.')
		)
	} else {
		dispatch(setAuth({ access_token: data.token }))
		navigate('/auth/next', { state: { step: 'login' } })
	}
}