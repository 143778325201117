import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { FastResponseModal } from '../../../components/Modals'
import { DefaultAvatar } from '../../../components/ui/DefaultAvatar'
import {
  COLORS,
  SOURCE,
  isResumeFilled,
  labelsByExperience,
  labelsBySalaryCurrency,
} from '../../../constants'
import { showErrorMsg, showSuccessMsg } from '../../../store/slices/alertSlice'
import { applyToVacancy } from '../../../api/vacancies'
import './VacancyCard.css'

export const VacancyCardPlaceholder = () => {
  return (
    <div
      className='bg-white rounded p-4 p-sm-5 mt-3 w-sm-max placeholder-glow	'
      style={{ cursor: 'pointer' }}
    >
      <div className='row gy-2'>
        <div className='col-auto d-flex flex-wrap'>
          <div
            className='rounded img-fluid placeholder'
            style={{
              objectFit: 'cover',
              width: '70px',
              height: '70px',
              flexShrink: '0',
            }}
          />
        </div>
        <div className='col d-grid'>
          <div className='d-grid col-auto'>
            <p className='fw-bolded col-6'>
              <span class='placeholder w-100'></span>
            </p>
            <p className='d-sm-none col-8'>
              <span class='placeholder w-100'></span>
            </p>
            <p className='mt-sm-1 col-3'>
              <span
                class='placeholder w-100'
                style={{ color: COLORS.header_text }}
              ></span>
            </p>
          </div>
        </div>
        <div className='col d-sm-grid d-none'>
          <p className='col'>
            <span class='placeholder offset-6 col-6'></span>
          </p>
        </div>
      </div>
      <p className='fs-3 mt-sm-4 mt-2'>
        <span class='placeholder col-3'></span>
      </p>
      <div className='g-2 mt-1 row gap-3 ms-3 fs-3'>
        <span class='placeholder col-1'></span>
        <span class='placeholder col-1'></span>
        <span class='placeholder col-1'></span>
      </div>
      <p className='mt-3 fs-6'>
        <span class='placeholder col-10' style={{ height: '5rem' }}></span>
      </p>
      <p className='mt-sm-4 mt-2 fs-6' style={{ color: COLORS.header_text }}>
        <span class='placeholder col-2'></span>
      </p>

      <div className='d-flex flex-wrap mt-3 gap-3 mt-sm-4 text-nowrap'>
        <button
          className='btn primary rounded px-4 py-2 me-sm-2 ms-sm-auto fs-6 disabled placeholder'
          style={{ width: '8rem' }}
        ></button>
        <button
          className='btn primary_outline d-sm-block d-none rounded px-4 py-2 fs-6 disabled placeholder'
          style={{ width: '10rem' }}
        />
      </div>
    </div>
  )
}

export const VacancyCard = ({
  vacancy,
  mySkills,
  guestResponseSent = false,
  responses,
  addResponse,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const access_token = useSelector(
    (state) => state.userSlice.user.access_token
  )
  const resume = useSelector((state) => state.resumeSlice.resume)
  const [state, setState] = useState({
    fastResponseModalShown: false,
  })
  let vacancySkills = [...vacancy.keySkills]
  vacancySkills = vacancySkills
    .sort((a, b) => mySkills.indexOf(b) >= 0)
    .slice(0, 3)
  const applyHandler = async () => {
    if (!resume.id) return navigate('/auth')
    if (!isResumeFilled(resume))
      return dispatch(showErrorMsg('Заполните резюме'))
    const apply = await applyToVacancy(access_token, vacancy.id)
    addResponse(vacancy.id)
    if (!apply) return dispatch(showErrorMsg('Отклик уже отправлен'))
    return dispatch(showSuccessMsg('Отклик отправлен'))
  }

  return (
    <div
      className='cards bg-white rounded p-4 p-sm-5 mt-3 w-sm-max'
      style={{ cursor: 'pointer' }}
      onClick={() => navigate('/vacancies/' + vacancy.id)}
    >
      {state.fastResponseModalShown && (
        <FastResponseModal
          vacancyId={vacancy.id}
          shown={state.fastResponseModalShown}
          set={setState}
        />
      )}
      <div className='row gy-2'>
        <div className='col-auto d-flex flex-wrap'>
          <DefaultAvatar
            type='b2b'
            alt='аватарка'
            src={SOURCE.url + `employers/${vacancy.employer.id}/avatar`}
            className='rounded img-fluid'
            style={{ objectFit: 'cover', width: '70px', height: '70px' }}
          />
        </div>
        <div className='col d-flex'>
          <div className='d-flex text-nowrap flex-column justify-content-center my-auto'>
            <p className='fs-4 fw-bolded'>
              {vacancy.employer?.name || 'Компания'}
            </p>
            <p className='fs-5 d-sm-none d-block'>
              {vacancy.salary
                ? vacancy.salary.toLocaleString() +
                  ' ' +
                  labelsBySalaryCurrency[vacancy.salaryCurrency]
                : 'З/п на собеседовании'}
            </p>
            <p className='fs-5 mt-sm-1' style={{ color: COLORS.header_text }}>
              {vacancy.city}
            </p>
          </div>
        </div>
        <div className='col-auto d-flex align-items-center d-sm-flex d-none'>
          <p className='fs-5'>
            {vacancy.salary
              ? vacancy.salary.toLocaleString() +
                ' ' +
                labelsBySalaryCurrency[vacancy.salaryCurrency]
              : 'З/п на собеседовании'}
          </p>
        </div>
      </div>
      <p className='fs-3 mt-sm-4 mt-2 fw-bolded'>
        {vacancy.name.slice(0, 35) + (vacancy.name.slice(35) && '...')}
      </p>
      <div className='row g-md-3 g-2 mt-1'>
        {vacancySkills.map(
          (s, i) =>
            s && (
              <div className='col-auto' key={i}>
                <p
                  className='fs-6 py-sm-2 py-1 px-3 rounded'
                  style={{
                    color: mySkills.indexOf(s) < 0 ? COLORS.text : 'white',
                    backgroundColor:
                      mySkills.indexOf(s) < 0
                        ? COLORS.light_neutral
                        : COLORS.warning,
                  }}
                >
                  {s}
                </p>
              </div>
            )
        )}
      </div>
      <p className='mt-3 fs-6'>{vacancy.shortDescription}</p>
      <p className='mt-sm-4 mt-2 fs-6' style={{ color: COLORS.header_text }}>
        {(vacancy.experience === 'NO_EXPERIENCE' ? '' : 'Опыт: ') +
          labelsByExperience[vacancy.experience]}
      </p>
      <div className='d-flex flex-wrap mt-3 gap-3 mt-sm-4 text-nowrap'>
        {access_token ? (
          <button
            className='btn primary rounded px-4 py-2 me-sm-2 ms-sm-auto fs-6'
            disabled={responses.indexOf(vacancy.id) !== -1}
            onClick={(e) => {
              e.stopPropagation()
              applyHandler()
            }}
          >
            {responses.indexOf(vacancy.id) !== -1
              ? 'Отклик отправлен'
              : 'Откликнуться'}
          </button>
        ) : guestResponseSent ? (
          <div className='me-sm-2 ms-sm-auto'></div>
        ) : (
          <button
            className='btn success rounded px-4 py-2  me-sm-2 ms-sm-auto fs-6'
            onClick={(e) => {
              e.stopPropagation()
              setState((p) => ({ ...p, fastResponseModalShown: true }))
            }}
          >
            Быстрый отклик
          </button>
        )}

        <button
          className='btn primary_outline d-sm-block d-none rounded px-4 py-2 fs-6'
          onClick={() => navigate('/vacancies/' + vacancy.id)}
        >
          Посмотреть вакансию
        </button>
      </div>
    </div>
  )
}
