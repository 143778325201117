import styles from './styles.module.css'

export const TitleSection = ({ title }) => {
  return (
    <div className='row mb-2'>
      <div className='col-12 d-flex justify-content-between align-items-center'>
        <h2 className={styles.section}>{title}</h2>
        <div className={styles.sectionLine}></div>
      </div>
    </div>
  )
}
