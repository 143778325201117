import { GoogleOAuthProvider } from '@react-oauth/google'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { Loader } from '../../components/Loader'
import { AppInput } from '../../components/ui/AppInput'
import { COLORS, GOOGLE_CLIENT_ID, SOURCE } from '../../constants'
import { NotFoundPage } from '../system/NotFoundPage'
import { AuthLayout } from './layouts/AuthLayout'
import { ConfirmMail, submitConfirmCode } from './layouts/ConfirmMail'
import { CreateResume } from './layouts/CreateResume'
import { ApplyEnterMail, EnterWithMail } from './layouts/EnterWithMail'
import { ForgotPassword } from './layouts/ForgotPassword'
import { ImportPDFResume } from './layouts/ImportPDFResume'
import { AuthNextStep } from './layouts/NextStep'
import { ResetPassword } from './layouts/ResetPassword'
import { ResumeSuggest } from './layouts/ResumeAuth'
import { SelectStartResume } from './layouts/SelectStartResume'
import { SetInterview } from './layouts/SetInterview'

export const AuthScreen = () => {
	const [isAdmin, setIsAdmin] = useState(true)
	const user = useSelector(state => state.userSlice.user)
	return (
		<div className='w-100 my-2 my-md-5 pb-5'>
			{isAdmin ? (
				<Routes>
					<Route
						path='/'
						element={
							<GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
								<AuthLayout />
							</GoogleOAuthProvider>
						}
					/>
					<Route path='enter_mail' element={<EnterWithMail />} />
					<Route path='byEmail' element={<ApplyEnterMail />} />

					<Route path='hh' element={<HHAuthCode />} />
					<Route path='gazprom' element={<GazAuthCode />} />

					<Route path='/next' element={<AuthNextStep />} />
					<Route path='confirm_mail' element={<ConfirmMail />} />
					<Route path='select_start_resume' element={<SelectStartResume />} />
					<Route path='create_resume' element={<CreateResume />} />
					<Route path='import_pdf_resume' element={<ImportPDFResume />} />
					<Route path='set_interview' element={<SetInterview />} />
					<Route path='fill_resume' element={<ResumeSuggest />} />
					<Route path='forgot_password' element={<ForgotPassword />} />
					<Route path='reset-password/:code' element={<ResetPassword />} />
					<Route path='verify-email' element={<ApplyConfirmMail />} />
					<Route path='*' element={<NotFoundPage />} />
				</Routes>
			) : (
				<div className='row gap-5 justify-content-between w-100 my-5 mx-auto px-4'>
					<div className='col-md-6 col-lg-4 col-12 d-flex align-items-center'>
						<img
							style={{ height: 'auto', width: '100%', maxWidth: '450px' }}
							className='m-auto'
							src={SOURCE.source_url + 'errorcat.png'}
							alt=''
						/>
					</div>
					<div className='col d-flex flex-column justify-content-center'>
						<p
							className='fs-1 mb-4'
							style={{ color: COLORS.primary, fontWeight: '400' }}
						>
							<span style={{ color: COLORS.text_primary }}>Доступ</span>{' '}
							ограничен!
						</p>
						<p className='fs-4 mb-4' style={{ color: COLORS.text_primary }}>
							Доступ к данной странице временно ограничен администрацией. Для
							продолжения работы потребуется ввести пароль.
						</p>
						<AppInput
							label='Пароль'
							type='password'
							onChange={v => setIsAdmin(v === 'qwerty')}
						/>
					</div>
				</div>
			)}
		</div>
	)
}

const ApplyConfirmMail = () => {
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const code = searchParams.get('code')
	const email = searchParams.get('email')
	const role = searchParams.get('role')
	const dispatch = useDispatch()
	const access_token = useSelector(state => state.userSlice.user.access_token)
	console.log({
		code,
		email,
		role,
	})
	useEffect(() => {
		submitConfirmCode(access_token, code, () => {}, navigate, dispatch)
	}, [])
	return <Loader />
}

const HHAuthCode = () => {
	const [searchParams] = useSearchParams()
	const code = searchParams.get('code')
	return code ? (
		<Navigate to='/auth' state={{ hhCode: code }} />
	) : (
		<Navigate to='/auth' />
	)
}

const GazAuthCode = () => {
	const [searchParams] = useSearchParams()
	const code = searchParams.get('code')
	return code ? (
		<Navigate to='/auth' state={{ gazCode: code }} />
	) : (
		<Navigate to='/auth' />
	)
}
