import { useDispatch, useSelector } from "react-redux"
import { COLORS } from "../../../constants"
import ResumePage from "../../account/layouts/ResumePage"
import { useEffect } from "react"
import {updateUserResume} from "../../../api/applicant"

export const ResumeSuggest = () => {
    const user = useSelector(state => state.userSlice.user)
    const dispatch = useDispatch()
    useEffect(() => {
        if (user.access_token !== undefined) {
            updateUserResume(user.access_token, dispatch)
        }
    }, [user.access_token])
    return <div className="w-100">
        <div className="position-fixed top-0 start-0 w-100 h-100 min-vw-100 min-vh-100" style={{backgroundColor: COLORS.mainBg, zIndex: -1}}></div>
        <div className="d-flex flex-column bg-white rounded mb-3 mb-sm-4 p-3 p-sm-4">
            <p className="text-center fw-bolded fs-3">
                Заполнение резюме
            </p>
            <p className="fs-5 text-center mx-auto" style={{ maxWidth: '800px' }}>
                Почти всё готово, осталось только заполнить резюме для выхода на финишную прямую к офферу в компанию мечты
            </p>

        </div>
        <ResumePage />
    </div>
}