import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit'
import {SOURCE, labelsByVacancyStatus} from "../../constants"
import { useDispatch } from 'react-redux'
import { showErrorMsg } from './alertSlice'
import { act } from 'react-dom/test-utils'
import {getAllVacancies} from "../../api/vacancies"

export const getAllVacanciesAsync = createAsyncThunk(
    "GET",

    async (queryObj = {}, {rejectWithValue, getState, dispatch}) => {
        const state = getState()
        const TOKEN = state.userSlice.user.access_token

        let query = '?'
        let filters = queryObj.filters || {}
        let include = queryObj.include || []
        let page = state.vacanciesSlice.vacanciesInfo.currentPage || 1
        let size = state.vacanciesSlice.vacanciesInfo.perPage || 12
        let city = filters.city || ''
        let name = filters.name || ''
        let employmentType = filters.employmentType || []
        let experience = filters.experience || []
        let employerId = filters.employerId || []
        let salaryCurrency = filters.salaryCurrency || []
        let reportingForm = filters.reportingForm || []
        let workingHours = filters.workingHours || []
        let workplaceModel = filters.workplaceModel || []
        let employer_isStartup = filters.isStartup || null
        let salary = filters.salary || {}
   

        include.forEach(el => {
            if (query !== '?') query += '&'
            query += 'include=' + el
        })

        employmentType.forEach(el => {
            if (query !== '?') query += '&'
            query += 'employmentType=' + el
        })

        experience.forEach(el => {
            if (query !== '?') query += '&'
            query += 'experience=' + el
        })
        
        employerId.forEach(el => {
            if (query !== '?') query += '&'
            query += 'employerId=' + el
        })
        salaryCurrency.forEach(el => {
            if (query !== '?') query += '&'
            query += 'salaryCurrency=' + el
        })
        reportingForm.forEach(el => {
            if (query !== '?') query += '&'
            query += 'reportingForm=' + el
        })
        workingHours.forEach(el => {
            if (query !== '?') query += '&'
            query += 'workingHours=' + el
        })
        workplaceModel.forEach(el => {
            if (query !== '?') query += '&'
            query += 'workplaceModel=' + el
        })

        employerId.forEach(el => {
            if (query !== '?') query += '&'
            query += 'employerId=' + el
        })
        let resSalary = {}
        Object.keys(salary).forEach(el => {
            if (salary[el] === '') return 0
            resSalary[el] = salary[el]*1000
        })
        if (Object.keys(resSalary).length > 0 ){
            if (query !== '?') query += '&'
            query += '&salary=' + encodeURIComponent(JSON.stringify(resSalary))
        }
        query += page ? '&page=' + page : ''
        query += size ? '&size=' + size : ''
        query += city ? '&city=' + city : ''
        // query += status ? '&status=' + status : ''
        query += name ? '&search=' + name : ''
        query += employer_isStartup ? '&employer_isStartup=' + employer_isStartup : ''

        const data = await getAllVacancies(TOKEN, query)

        if (!data) {
            return dispatch(showErrorMsg('Произошла ошибка на сервере'))
        }
        return data
    },
)


const defaultFilters = {
    status: 'PUBLISHED',
    city: '',
    name: '',
    employmentType: [],
    experience: [],
    workplaceModel: [],
    isStartup: false,
    employerId: '',
    teamRole: [],
    salary: {
        lte: '',
        gte: ''
    }
}


const initialState = {
    vacanciesInfo: {
        vacancies: [],
        cities: [],
        currentPage: 0,
        perPage: 0,
        totalPages: 0,
        total: 0,
        filters: defaultFilters
    },
    loading: false,
    loaded: false
}

export const vacanciesSlice = createSlice({
    name: 'vacancies',

    initialState,

    reducers: {
        setVacancies: (state, action) => {
            state.vacanciesInfo.vacancies = action.payload
            state.loaded = true
        },
        setCities: (state, action) => {
            state.vacanciesInfo.cities = [{ label: 'Не важно', value: '' }, ...(action.payload?.cities || []).map(c => ({ label: c, value: c }))]
        },
        updateRange: (state, action) => {
            state.vacanciesInfo = { 
                ...state.vacanciesInfo,
                ...action.payload,
            }
        },
        setFilters: (state, action) => {
            state.vacanciesInfo = {
                ...state.vacanciesInfo,
                currentPage: 0,
                perPage: 0,
                totalPages: 0,
                total: 0,
                filters: { ...state.vacanciesInfo.filters, ...action.payload }
            }
        },
        resetFilters: (state, action) => {
            state.vacanciesInfo.filters = defaultFilters
        },
        clearVacancies: (state, action) => {
            state = initialState
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(getAllVacanciesAsync.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAllVacanciesAsync.fulfilled, (state, action) => {
                if (action.payload.items){
                    state.vacanciesInfo = {
                        ...state.vacanciesInfo,
                        ...action.payload,
                        vacancies: action.payload.currentPage === 1 ? action.payload.items : [...state.vacanciesInfo.vacancies, ...action.payload.items],
                    }
                }
                state.loaded = true
                state.loading = false
            })
    }
})

export const { setVacancies, setCities, updateRange, setFilters, resetFilters, clearVacancies } = vacanciesSlice.actions
export default vacanciesSlice.reducer