import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { sendResetPassLink } from '../../../api/passwordResets'
import { AppInput } from '../../../components/ui/AppInput'
import { Validation } from '../../../constants'
import { showErrorMsg } from '../../../store/slices/alertSlice'

export const ForgotPassword = () => {
	const {
		state: { email },
	} = useLocation()
	const [state, setState] = useState({
		email: email || '',
		sent: false,
	})
	const navigate = useNavigate()
	const dispatch = useDispatch()
	return (
		<div
			className='container w-100 d-flex flex-column mx-auto my-5'
			style={{ maxWidth: '750px' }}
		>
			<p className='text-center fs-2 mb-4' style={{ fontWeight: '400' }}>
				Восстановление пароля
			</p>
			{state.sent ? (
				<p className='fs-3 text-center mb-4'>
					Письмо с инструкцией отправлено на{' '}
					<span style={{ fontWeight: '400' }}>{state.email}</span>
				</p>
			) : (
				<>
					<p className='fs-5 mb-4 text-center'>
						Вводи почту от своего акканта ниже, и вернись к поиску работы уже
						через несколько секунд
					</p>
					<AppInput
						label='Электронная почта'
						className='mx-auto w-100'
						style={{ maxWidth: '500px' }}
						value={state.email}
						showAlert={!Validation.validMail(state.email)}
						onChange={v => setState(prev => ({ ...prev, email: v.trim() }))}
					/>
					<button
						className='btn primary py-2 px-4 mt-4 mx-auto w-100'
						style={{ maxWidth: '500px' }}
						onClick={() => send(state, setState, dispatch)}
					>
						Восстановить
					</button>
				</>
			)}
			<button
				className='btn light_primary px-4 py-2 mt-2 mx-auto w-100'
				style={{ maxWidth: '500px' }}
				onClick={() => navigate('/auth')}
			>
				Авторизация
			</button>
		</div>
	)
}
async function send(state, setState, dispatch) {
	if (!Validation.validMail(state.email)) {
		return dispatch(showErrorMsg('Неверный формат почты'))
	}
	const result = await sendResetPassLink(state.email)
	if (result) return setState(p => ({ ...p, sent: true }))
	dispatch(showErrorMsg('Почта не найдена'))
}
