import { useLocation, useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../../../store/slices/userSlice'
import { Loader } from '../../../components/Loader'
import { useEffect } from 'react'
import {
  getMyAccount,
  getMyStatus,
  isProcessingPDF,
} from '../../../api/applicant'

export const AuthNextStep = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.userSlice.user)
  if (!user.access_token) navigate('/auth')
  if (!location.state) navigate('/auth')

  useEffect(() => {
    (async () => {
      const userData = await updateUserData(
        user.access_token,
        dispatch,
        navigate
      )
      const userStatus = await getStatus(
        user.access_token,
        userData?.id || null,
        navigate
      )
      if (!userStatus) return navigate('/logout')
      const isPDFProcessing = await isProcessingPDF(
        dispatch,
        user.access_token
      )
      console.log(
        'TOKEN',
        user.access_token,
        'STEP ',
        location.state.step,
        'isProcessing ',
        isPDFProcessing,
        'USer status',
        userStatus.isResumeFilled
      )
      switch (location.state.step) {
        case 'login':
          if (!userStatus.isEmailConfirmed) {
            navigate('/auth/confirm_mail')
          } else if (!isPDFProcessing && !userStatus.isResumeFilled) {
            navigate('/auth/select_start_resume')
          } else if (!isPDFProcessing && !userStatus.hasMeeting) {
            navigate('/auth/set_interview')
          } else {
            navigate('/account')
          }
          break
        case 'confirm_mail':
          if (!isPDFProcessing && !userStatus.isResumeFilled) {
            navigate('/auth/select_start_resume')
          } else if (!userStatus.hasMeeting) {
            navigate('/auth/set_interview')
          } else if (!isPDFProcessing && userStatus.isResumeFilled) {
            navigate('/auth/fill_resume')
          } else {
            navigate('/account')
          }
          break
        case 'create_resume':
          if (!userStatus.hasMeeting) {
            navigate('/auth/set_interview')
          } else if (!isPDFProcessing && !userStatus.isResumeFilled) {
            navigate('/auth/fill_resume')
          } else {
            navigate('/account')
          }
          break
        case 'set_interview':
          if (!isPDFProcessing && !userStatus.isResumeFilled) {
            navigate('/auth/fill_resume')
          } else {
            navigate('/account')
          }
          break
        case 'fill_resume':
          navigate('/account')
          break
        default:
          navigate('/account')
      }
    })()
  }, [])
  return <Loader />
}

async function updateUserData(access_token, dispatch, navigate) {
  const response = await getMyAccount(access_token)
  if (response === null || !response.ok) return navigate('/auth')

  const data = await response.json()
  await dispatch(
    setUser({
      ...data,
      loaded: true,
      access_token: access_token,
    })
  )
  return {
    ...data,
    loaded: true,
    access_token: access_token,
  }
}

export async function getStatus(access_token, userId, navigate) {
  if (!userId) return false
  const data = await getMyStatus(access_token, userId)
  if (!data) return navigate('/logout')
  return data
}
