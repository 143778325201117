import React, { useEffect, useRef, useState } from 'react'
import classes from './CompanyCard.module.scss'
import { SOURCE, teamSizeLabels, toDate } from '../../../../constants'
import { useLocation, useNavigate } from 'react-router'
import { DefaultAvatar } from '../../../../components/ui/DefaultAvatar'

const CompanyCard = ({ vacancy }) => {
  const navigate = useNavigate()
  const loc = useLocation()
  const [showFullText, setShowFullText] = useState(false)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const descriptionRef = useRef(null)

  useEffect(() => {
    if (descriptionRef.current) {
      setIsOverflowing(descriptionRef.current.scrollHeight > 84)
    }
  }, [vacancy.employer.description])

  const toggleShowMore = () => {
    setShowFullText(!showFullText)
  }

  // console.log(vacancy.employer.id);
  return (
    <div className={classes.Container + ' p-4 p-xl-5 w-sm-max '}>
      <div className={classes.Company}>
        <DefaultAvatar
          style={{
            height: '60px',
            width: '60px',
            objectFit: 'cover',
            flexShrink: '0',
          }}
          type='b2b'
          className={classes.CompanyAvatar}
          src={SOURCE.url + `employers/${vacancy.employer.id}/avatar`}
          alt='company'
          onClick={() => console.log(1)}
        />
        <div
          className={classes.CompanyTitle + ' my-auto'}
          style={{ overflow: 'hidden' }}
        >
          <div className={'' + classes.CompanyTitleUp}>
            {vacancy.employer.name}
          </div>
          {/* <div className={classes.CompanyTitleDown}>
            договор №{vacancy.employer.agreementNumber} от{' '}
            {toDate(vacancy.employer.createdAt)}
          </div> */}
        </div>
      </div>

      <div
        className={classes.CompanyInfo}
        style={{ maxHeight: showFullText ? 'none' : '84px' }}
        ref={descriptionRef}
      >
        {vacancy.employer.description}

        {isOverflowing && (
          <span className={classes.ShowMoreButton} onClick={toggleShowMore}>
            {showFullText ? 'Скрыть' : '... читать дальше'}
          </span>
        )}
      </div>

      <ul className={classes.CompanyStat}>
        <li>
          <b>Объем:</b>
          {` ${teamSizeLabels[vacancy.employer.size] || 'Не указано'}`}
        </li>
        <li>
          <b>Город:</b>
          {` ${vacancy.employer.city || 'Не указано'}`}
        </li>
        <li>
          <b>Стартап:</b>
          {` ${vacancy.employer.isStartup ? 'да' : 'нет'}`}
        </li>
      </ul>

      <a
        href={vacancy.employer.website}
        target={'_blank'}
        className={classes.Link}
      >
        Наш сайт
      </a>
    </div>
  )
}
export default CompanyCard
