import { COLORS } from '../../../../constants'
import { useNavigate } from 'react-router'
import { ReactComponent as FailmarkSvg } from '../../../../icons/consultations_landing/failmark.svg'

export const ExpiredPayment = ({ key_route }) => {
  const navigate = useNavigate()

  return (
    <div className='w-100 mx-auto pt-md-5 pb-5 my-sm-5' style={{ flex: '1' }}>
      <div
        className='d-flex flex-column align-items-center text-center mx-auto'
        style={{ maxWidth: '1000px' }}
      >
        <FailmarkSvg className='mb-2' />
        <p
          className='fs-1 fw-bold mb-1'
          style={{ color: COLORS.danger, maxWidth: '900px' }}
        >
          Срок платежа истек
        </p>
        <p className='fs-3 mb-5'>
          Повтори попытку или обратись в поддержку и мы постараемся решить твою
          проблему{' '}
        </p>
        <div className='row gap-3'>
          <button
            className='col-auto btn primary rounded-pill text-nowrap py-3 px-5 mt-sm-5 fs-6'
            style={{ flex: '1' }}
            onClick={() => navigate(key_route + '/book-slot')}
          >
            Повторить попытку
          </button>
          <button
            className='col-auto btn primary_outline text-nowrap rounded-pill py-3 px-5 mt-sm-5 fs-6'
            style={{ flex: '1' }}
            onClick={() => window.open('https://t.me/hurtle_support_bot')}
          >
            Обратиться в поддержку
          </button>
        </div>
        <p className='fs-5 mb-5' style={{ color: COLORS.hr }}>
					Также ссылка на успешно забронированную встречу будет выслана на твою почту
				</p>
      </div>
    </div>
  )
}
