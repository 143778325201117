import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { changeOfferStatus, getFullOffers } from '../../../api/offers'
import { OfferInfoModal } from '../../../components/Modals'
import { DefaultAvatar } from '../../../components/ui/DefaultAvatar'
import { COLORS, SOURCE, labelsBySalaryCurrency } from '../../../constants'
import { ReactComponent as BaselinePlace } from '../../../icons/baseline-place.svg'
import { ReactComponent as TwoCoins } from '../../../icons/two-coins.svg'

export const Offers = () => {
	const user = useSelector(state => state.userSlice.user)
	const [state, setState] = useState({
		offers: [],
		offerInfoModal: {
			shown: false,
			offer: {},
		},
	})

	const makeDeclineHandler = id => async () => {
		const res = await changeOfferStatus(user.access_token, id, 'DECLINED')
		if (res !== null)
			setState(p => ({
				...p,
				offers: p.offers.map(o =>
					o.id === id ? { ...o, status: 'DECLINED' } : o
				),
			}))
	}

	useEffect(() => {
		getFullOffers(user.access_token).then(
			data => data && setState(p => ({ ...p, offers: data }))
		)
	}, [user.access_token])

	return (
		<div className='w-100'>
			<p className='mb-3 fs-3' style={{ fontWeight: '300' }}>
				Все приглашения
			</p>
			<div className='row gx-3'>
				<OfferInfoModal
					offerInfoModal={state.offerInfoModal}
					setState={setState}
				/>
				{state.offers.length ? (
					state.offers.map((o, i) => (
						<Offer
							key={i}
							o={o}
							setState={setState}
							makeDeclineHandler={makeDeclineHandler}
						/>
					))
				) : (
					<p className='w-100 bg-white text-center rounded-4 py-4 px-4 fs-4 fw-light'>
						Пока их нет, но все впереди
					</p>
				)}
			</div>
		</div>
	)
}

const Offer = ({ o, makeDeclineHandler, setState }) => {
	let workplaceStr
	switch (o.vacancy.workplaceModel) {
		case 'OFFICE':
			workplaceStr = 'Офис в г. ' + o.vacancy.city
			break
		case 'REMOTE':
			workplaceStr = 'Удаленка'
			break
		case 'HYBRID':
			workplaceStr = o.vacancy.city + ' + удаленка'
	}
	return (
		<div key={o.id} className='col-12 col-md-6 col-lg-4 mt-3'>
			<div className='bg-white w-sm-max rounded-3 px-4 py-3 d-flex flex-column gap-2'>
				<div className='d-flex flex-row align-items-center'>
					<DefaultAvatar
						type='b2b'
						src={SOURCE.url + `employers/${o.vacancy.employer.id}/avatar`}
						className='rounded'
						style={{
							height: '60px',
							width: '60px',
							boxShadow: '0 0 12px 0 #6757f14d',
						}}
					/>
					<div className='d-flex flex-column flex-grow-1 ms-3'>
						<span className='fs-5 fw-semibold'>{o.vacancy.employer.name}</span>
						<span
							className='fs-6 fw-semibold'
							style={{ color: COLORS.header_text }}
						>
							{o.vacancy.name}
						</span>
					</div>
				</div>
				<div className='d-flex flex-row flex-wrap gap-1'>
					<p>
						<TwoCoins />
						<span
							className='fs-6 fw-semibold ms-2'
							style={{ color: COLORS.header_text }}
						>
							{o.salary.toLocaleString() || o.vacancy.salary.toLocaleString()}{' '}
							{
								labelsBySalaryCurrency[
									o.salaryCurrency || o.vacancy.salaryCurrency
								]
							}
						</span>
					</p>
					&nbsp;&nbsp;
					<p>
						<BaselinePlace />
						<span
							className='fs-6 fw-semibold ms-1'
							style={{ color: COLORS.header_text }}
						>
							{workplaceStr}
						</span>
					</p>
				</div>
				<div className='row m-0 mt-2 gap-2 gap-sm-4 gap-lg-2 gap-xl-4'>
					<button
						className='col btn success'
						disabled={o.status !== 'PENDING'}
						onClick={() =>
							setState(p => ({
								...p,
								offerInfoModal: { shown: true, offer: o },
							}))
						}
					>
						Рассмотреть
					</button>
					<button
						className='col btn danger'
						disabled={o.status !== 'PENDING'}
						onClick={makeDeclineHandler(o.id)}
					>
						Отказать
					</button>
				</div>
			</div>
		</div>
	)
}
