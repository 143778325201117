import {COLORS} from "../../constants"
import {useDispatch} from "react-redux"
import {changePublishStatus} from "./utils"

export const PublishResumeButton = ({ access_token, resume }) => {
    const dispatch = useDispatch()

    return <>
        <button className="col-12 col-sm px-3 py-2 rounded-1 fs-6 text-white"
                style={{backgroundColor: resume.isVisibleToEmployers ? COLORS.red_primary : COLORS.primary}}
                onClick={() => changePublishStatus(access_token, resume, !resume.isVisibleToEmployers, dispatch)}>
            {resume.isVisibleToEmployers ? "Снять с публикации" : "Опубликовать резюме"}
        </button>
    </>
}