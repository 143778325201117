import { Navigate, useNavigate } from 'react-router'
import { COLORS } from '../../../constants'
import { ReactComponent as FailmarkSvg } from '../../../icons/consultations_landing/failmark.svg'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { setPaymentStatus } from './SuccessPayment'
import { showErrorMsg } from '../../../store/slices/alertSlice'

export const FailurePayment = ({ key_route }) => {
  const urlParams = new URLSearchParams(window.location.search)
  const meetingPaymentId = urlParams.get('meetingPaymentId')
  const code = urlParams.get('code')

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const guest = useSelector((state) => state.guestSlice.guest)
  useEffect(() => {
    (async () => {
      const result = await setPaymentStatus(
        guest.access_token,
        meetingPaymentId,
        code,
        'FAIL'
      )
      if (!result) {
        dispatch(showErrorMsg('Произошла ошибка на сервере'))
      }
    })()
  }, [])

  return (
    <div className='w-100 mx-auto pt-md-5 pb-5 my-sm-5' style={{ flex: '1' }}>
      <div
        className='d-flex flex-column align-items-center text-center mx-auto'
        style={{ maxWidth: '1000px' }}
      >
        <FailmarkSvg className='mb-2' />
        <p
          className='fs-1 fw-bold mb-1'
          style={{ color: COLORS.danger, maxWidth: '900px' }}
        >
          Произошла ошибка. Попробуй снова
        </p>
        <p className='fs-3 mb-5'>
          Повтори попытку или обратись в поддержку и мы постараемся решить твою
          проблему{' '}
        </p>
        <div className='row gap-3'>
          <button
            className='col-auto btn primary rounded-pill text-nowrap py-3 px-5 mt-sm-5 fs-6'
            style={{ flex: '1' }}
            onClick={() => navigate(key_route + '/book-slot')}
          >
            Повторить попытку
          </button>
          <button
            className='col-auto btn primary_outline text-nowrap rounded-pill py-3 px-5 mt-sm-5 fs-6'
            style={{ flex: '1' }}
            onClick={() => window.open('https://t.me/hurtle_support_bot')}
          >
            Обратиться в поддержку
          </button>
        </div>
      </div>
    </div>
  )
}
