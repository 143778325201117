import { SOURCE } from '../constants'
import * as yup from 'yup'

export const BasicResumeSchema = yup.object({
  title: yup.string().trim().max(50, 'Максимальная длина - 50'),
  summary: yup
    .string()
    .trim()
    .max(3000, 'Краткое содержание до 3000')
    .nullable(),
  city: yup.string().trim().max(255, 'Максимальная длина - 255').nullable(),
  skills: yup
    .array()
    .of(yup.string().defined().trim().min(0).max(50, 'Скилл до 50 символов'))
    .max(30, 'Максимум 30 скилов'),
  isVisibleToEmployers: yup.boolean(),
  desiredSalary: yup
    .number()
    .max(2 ** 31 - 1, 'Слишком большая желаемая зарплата(')
    .nullable(),
})

export const BasicResumeExperienceSchema = yup.object({
  company: yup
    .string()
    .trim()
    .min(0)
    .max(256, 'Максимум 256 символов в компании')
    .nullable(),
  position: yup.string().trim().max(255, 'Максимум 255 символов в должности'),
  startMonth: yup.number().max(12, 'Неверный формат месяца'),
  endMonth: yup.number().max(12, 'Неверный формат месяца').nullable(),
  endYear: yup
    .number()
    .max(
      new Date().getFullYear(),
      `Год не может быть больше ${new Date().getFullYear()}`
    ),
  startYear: yup
    .number()
    .max(
      new Date().getFullYear(),
      `Год не может быть больше ${new Date().getFullYear()}`
    ),
  description: yup
    .string()
    .trim()
    .min(0)
    .max(3000, 'Длина описания не должна превышать 3000 символов')
    .nullable(),
})

export const BasicResumeEducationSchema = yup.object({
  name: yup.string().trim().min(0).max(255, 'Максимум 255 символов в имени'),
  description: yup
    .string()
    .trim()
    .min(0)
    .max(255, 'Описание до 255')
    .nullable(),
  degree: yup.string().trim().min(0).max(255, ''),
  startYear: yup.number().max(3000, 'Максимум 3000 год').nullable(),
  endYear: yup.number().max(3000, 'Максимум 3000 год'),
})

export const BasicResumeCertificateSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(0)
    .max(255, 'Наименование сертификата до 255 символов'),
  description: yup
    .string()
    .trim()
    .min(0)
    .max(255, 'Описание сертификата до 255 символов')
    .nullable(),
  year: yup.number().nullable(),
})

export const BasicResumeLanguageSchema = yup.object({
  name: yup.string().trim().max(100, 'Язык до 100 символов'),
  level: yup.string().trim().max(100, 'Уровень до 100 символов').nullable(),
})

export async function patchResume(access_token, id, updated) {
  try {
    const res = fetch(SOURCE.url + 'resumes/' + id, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify(updated),
    })
    return res.ok
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function createResume(title, access_token, id) {
  try {
    return await fetch(SOURCE.url + 'resumes/' + id, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify({ title }),
    })
  } catch {
    return false
  }
}

export async function createResumeExperience(access_token, resume) {
  try {
    const dateNow = new Date()
    const res = await fetch(SOURCE.url + 'resumeExperience', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify({
        description: '',
        resumeId: resume.id,
        company: '',
        position: '',
        startMonth: dateNow.getMonth() + 1,
        startYear: dateNow.getFullYear(),
        endMonth: dateNow.getMonth() + 1,
        endYear: dateNow.getFullYear(),
      }),
    })
    if (!res.ok) return false
    return await res.json()
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function createResumeExperienceForPDF(access_token, resume) {
  try {
    const dateNow = new Date()
    const res = await fetch(SOURCE.url + 'resumeExperience', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify(resume),
    })
    if (!res.ok) return false
    return await res.json()
  } catch (e) {
    console.error(e)
    return false
  }
}
export async function patchResumeExperience(access_token, id, updated) {
  try {
    const res = await fetch(SOURCE.url + 'resumeExperience/' + id, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify(updated),
    })
    return res.ok
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function deleteResumeExperience(access_token, id) {
  try {
    const res = await fetch(SOURCE.url + 'resumeExperience/' + id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
    })
    return res.ok
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function createResumeEducation(access_token, resume) {
  try {
    const dateNow = new Date()
    const res = await fetch(SOURCE.url + 'resumeEducation', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify({
        description: '',
        resumeId: resume.id,
        name: '',
        degree: '',
        startYear: dateNow.getFullYear(),
        endYear: dateNow.getFullYear(),
      }),
    })
    if (!res.ok) return false
    return await res.json()
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function createResumeEducationForPDF(access_token, resume) {
  try {
    const dateNow = new Date()
    const res = await fetch(SOURCE.url + 'resumeEducation', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify(resume),
    })
    if (!res.ok) return false
    return await res.json()
  } catch (e) {
    console.error(e)
    return false
  }
}
export async function patchResumeEducation(access_token, id, updated) {
  try {
    const res = await fetch(SOURCE.url + 'resumeEducation/' + id, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify(updated),
    })
    return res.ok
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function deleteResumeEducation(access_token, id) {
  try {
    const res = await fetch(SOURCE.url + 'resumeEducation/' + id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
    })
    return res.ok
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function createResumeCertificate(access_token, resume) {
  try {
    const dateNow = new Date()
    const res = await fetch(SOURCE.url + 'resumeCertificates', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify({
        description: '',
        resumeId: resume.id,
        name: '',
        year: dateNow.getFullYear(),
      }),
    })
    if (!res.ok) return false
    return await res.json()
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function createResumeCertificateForPDF(access_token, resume) {
  try {
    const dateNow = new Date()
    const res = await fetch(SOURCE.url + 'resumeCertificates', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify(resume),
    })
    if (!res.ok) return false
    return await res.json()
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function patchResumeCertificate(access_token, id, updated) {
  try {
    const res = await fetch(SOURCE.url + 'resumeCertificates/' + id, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify(updated),
    })
    return res.ok
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function deleteResumeCertificate(access_token, id) {
  try {
    const res = await fetch(SOURCE.url + 'resumeCertificates/' + id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
    })
    return res.ok
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function createResumeLanguage(access_token, resume) {
  try {
    const res = await fetch(SOURCE.url + 'resumeLanguages', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify({
        level: 'A1',
        resumeId: resume.id,
        name: '',
      }),
    })
    if (!res.ok) return false
    return await res.json()
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function createResumeLanguageForPDF(access_token, resume) {
  try {
    const res = await fetch(SOURCE.url + 'resumeLanguages', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify(resume),
    })
    if (!res.ok) return false
    return await res.json()
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function patchResumeLanguage(access_token, id, updated) {
  try {
    const res = await fetch(SOURCE.url + 'resumeLanguages/' + id, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify(updated),
    })
    return res.ok
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function deleteResumeLanguage(access_token, id) {
  try {
    const res = await fetch(SOURCE.url + 'resumeLanguages/' + id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
    })
    return res.ok
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function createResumeContact(access_token, resume, type) {
  try {
    const res = await fetch(SOURCE.url + 'resumeContacts', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify({
        type,
        resumeId: resume.id,
        name: null,
        value: '',
        preferred: true,
      }),
    })
    if (!res.ok) return false
    return await res.json()
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function createResumeContactForPDF(access_token, resume) {
  try {
    const res = await fetch(SOURCE.url + 'resumeContacts', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify(resume),
    })
    if (!res.ok) return false
    return await res.json()
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function patchResumeContact(access_token, id, updated) {
  try {
    const res = await fetch(SOURCE.url + 'resumeContacts/' + id, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify(updated),
    })
    return res.ok
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function deleteResumeContact(access_token, id) {
  try {
    const res = await fetch(SOURCE.url + 'resumeContacts/' + id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
    })
    return res.ok
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function deleteResume(access_token, id) {
  try {
    return await fetch(`${SOURCE.url}resumes/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: access_token,
      },
    })
  } catch {
    return false
  }
}
