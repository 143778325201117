// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NeuroResume_MainTitle__u6cwF {
  background: var(--neuro);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.NeuroResume_DotsLoader__R12Tr {
  width: 35px;
  margin-right: 30px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side, #272727 90%, #0000);
  background:
          var(--_g) 0 50%,
          var(--_g) 50%  50%,
          var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: NeuroResume_loader__UKjqK .75s infinite linear;
}

@keyframes NeuroResume_loader__UKjqK {
  33%{background-size: calc(100%/3) 0   , calc(100%/3) 100%, calc(100%/3) 100%}
  50%{background-size: calc(100%/3) 100%, calc(100%/3) 0   , calc(100%/3) 100%}
  66%{background-size: calc(100%/3) 100%, calc(100%/3) 100%, calc(100%/3) 0   }
}`, "",{"version":3,"sources":["webpack://./src/screens/account/layouts/neuro/NeuroResume.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,6BAA6B;EAC7B,oCAAoC;AACtC;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,wEAAwE;EACxE;;;4BAG0B;EAC1B,kCAAkC;EAClC,yDAAsC;AACxC;;AAEA;EACE,IAAI,wEAAwE;EAC5E,IAAI,wEAAwE;EAC5E,IAAI,wEAAwE;AAC9E","sourcesContent":[".MainTitle {\n  background: var(--neuro);\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.DotsLoader {\n  width: 35px;\n  margin-right: 30px;\n  aspect-ratio: 4;\n  --_g: no-repeat radial-gradient(circle closest-side, #272727 90%, #0000);\n  background:\n          var(--_g) 0 50%,\n          var(--_g) 50%  50%,\n          var(--_g) 100% 50%;\n  background-size: calc(100%/3) 100%;\n  animation: loader .75s infinite linear;\n}\n\n@keyframes loader {\n  33%{background-size: calc(100%/3) 0   , calc(100%/3) 100%, calc(100%/3) 100%}\n  50%{background-size: calc(100%/3) 100%, calc(100%/3) 0   , calc(100%/3) 100%}\n  66%{background-size: calc(100%/3) 100%, calc(100%/3) 100%, calc(100%/3) 0   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MainTitle": `NeuroResume_MainTitle__u6cwF`,
	"DotsLoader": `NeuroResume_DotsLoader__R12Tr`,
	"loader": `NeuroResume_loader__UKjqK`
};
export default ___CSS_LOADER_EXPORT___;
