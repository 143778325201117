import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useLocation, useNavigate } from 'react-router'
import { AppCheckbox } from '../../../components/ui/AppCheckbox'
import { AppInput } from '../../../components/ui/AppInput'
import { COLORS, Validation } from '../../../constants'
import { ReactComponent as CheckSvg } from '../../../icons/consultations_landing/check.svg'
import { ReactComponent as EditSvg } from '../../../icons/consultations_landing/edit.svg'
import { ReactComponent as MedalSvg } from '../../../icons/consultations_landing/medal.svg'
import { ReactComponent as SadFaceSvg } from '../../../icons/consultations_landing/sad_face.svg'
import { ReactComponent as StarSvg } from '../../../icons/consultations_landing/star.svg'
import { ReactComponent as WindSvg } from '../../../icons/consultations_landing/wind.svg'
import { showErrorMsg } from '../../../store/slices/alertSlice'
import { createGuestAsync, removeGuest } from '../../../store/slices/guestSlice'
import { NotFoundPage } from '../../system/NotFoundPage'
import { BookSlot } from './BookSlot'
import { FailurePayment } from './FailurePayment'
import { SuccessPayment } from './SuccessPayment'
import { ExpiredPayment } from './errors/ExpiredPayment'
import { ReloadPayment } from './errors/ReloadPayment'
import './styles.css'

export const ConsultationsScreen = () => {
	const loc = useLocation()
	const isCV = loc.pathname.includes('/cv')
	return (
		<Routes>
			<Route path='/' element={<Consultations />} />
			<Route path='*' element={<NotFoundPage />} />
			<Route path='book-slot' element={<BookSlot isCV={isCV} />} />
			<Route
				path='payment/success'
				element={<SuccessPayment key_route={isCV ? '/cv' : '/expert'} />}
			/>
			<Route
				path='payment/fail'
				element={<FailurePayment key_route={isCV ? '/cv' : '/expert'} />}
			/>
			<Route
				path='payment/reload'
				element={<ReloadPayment key_route={isCV ? '/cv' : '/expert'} />}
			/>
			<Route
				path='payment/expired'
				element={<ExpiredPayment key_route={isCV ? '/cv' : '/expert'} />}
			/>
		</Routes>
	)
}

const Consultations = () => {
	const [state, setState] = useState({
		email: '',
	})
	const navigate = useNavigate()
	const guest = useSelector(state => state.guestSlice.guest)
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(removeGuest())
	}, [])
	const handleSignUp = async () => {
		if (!Validation.validMail(state.email))
			return dispatch(showErrorMsg('Укажите корректную почту'))
		const res = await dispatch(createGuestAsync(state.email))
		if (res.payload.token) navigate('book-slot')
	}
	return (
		<div className='pt-3'>
			<div className='row'>
				<div className='col-md'>
					<p
						className=' fw-bold'
						style={{
							fontSize: 'max(4.5vw, 40px)',
							lineHeight: 'max(4vw, 30px)',
						}}
					>
						{/* lineHeight: 'min(3.5rem, 40px)' */}
						Поправим
						<span className='ms-sm-3'>
							<EditSvg height='min(90px,20vw)' />
						</span>
						<br />
						твое резюме
					</p>
				</div>
				<div className='col pt-4'>
					<div className='d-flex flex-column'>
						<p className='fs-5 mb-4' style={{ color: COLORS.header_text }}>
							Структурируем ваш опыт и составим резюме,
							<br /> которое выделит вас среди других и покажет рекрутеру,
							что вы — тот самый кандидат
						</p>

						<a
							href='#submit'
							className='btn primary rounded-3 py-2 px-4 fw-bolded d-md-block d-none me-auto'
							style={{ minHeight: '45px' }}
						>
							Заказать резюме
						</a>
					</div>
				</div>
			</div>
			<MainLayer />
			<MainLayerMobile />

			<p className='fs-1 mt-sm-5 mt-4 pt-md-5 text-center'>Подходит, если вы</p>
			<div className='d-flex flex-column mt-5 mb-sm-5' style={{ gap: '20px' }}>
				{[
					{
						text: 'Постоянно думаете, что «резюме сначала нужно доделать», и откладываете поиск работы',
						img: 'clock.png',
						icon: (
							<EditSvg
								className='position-absolute'
								style={{
									left: '5px',
									top: '-40px',
									width: 'min(20vw,130px)',
									height: 'min(20vw,130px)',
								}}
							/>
						),
					},
					{
						text: 'Устали упускать интересные вакансии, потому что нечего отправить HR-менеджеру',
						img: 'qa.png',
						icon: (
							<WindSvg
								className='position-absolute'
								style={{
									right: '0px',
									top: '-45px',
									width: 'min(20vw,130px)',
									height: 'min(20vw,130px)',
								}}
							/>
						),
					},
					{
						text: 'Не получаете приглашения на собеседования, хотя регулярно откликаетесь со своим резюме',
						img: 'overtime.png',
						icon: (
							<SadFaceSvg
								className='position-absolute'
								style={{
									right: '15%',
									bottom: '-45px',
									width: 'min(20vw,130px)',
									height: 'min(20vw,130px)',
								}}
							/>
						),
					},
				].map((el, i) => (
					<Card key={i} data={el} />
				))}
			</div>

			<p className='fs-1 d-sm-block d-none pt-5 text-center mt-5'>
				Поможем написать резюме, чтобы сразу после созвона вы начали откликаться
			</p>
			<p className='fs-2 d-sm-none d-block text-center mt-5'>
				Поможем написать резюме, чтобы сразу после созвона вы начали откликаться
			</p>

			<div
				id='background_image_layer_reverse'
				className='position-relative d-md-flex d-none flex-column'
				style={{ height: '530px' }}
			>
				{[
					{ text: 'Fullstack developer', top: '100px', left: '20px' },
					{ text: 'Frontend developer', top: '100px', right: '20%' },
					{ text: 'Frontend developer', top: '44%', left: '25%' },
					{
						text: '150 000 - 200 000 руб./мес.',
						bottom: '100px',
						left: '70px',
					},
					{ text: '250 000 - 300 000 руб./мес.', top: '46%', right: '-20px' },
					{ text: 'Backend developer', bottom: '20px', right: '21%' },
				].map((el, i) => {
					const { text, ...style } = el
					return (
						<p
							key={i}
							style={{ ...style, width: 'fit-content' }}
							className='bg-white rounded-pill position-absolute px-4 py-2 fs-5 fw-light'
						>
							{el.text}
						</p>
					)
				})}
				<div
					className='bg-white rounded-5 mx-auto mt-auto mb-5 d-flex flex-column align-items-center py-4 px-5'
					style={{
						zIndex: '3',
						filter: 'drop-shadow(0px 0px 22px #BF81E2037)',
					}}
				>
					<p className='fs-5'>Вам пришёл оффер!</p>
					<p className='fs-6 mb-3' style={{ color: COLORS.header_text }}>
						Зелёный банк
					</p>
					<img
						src={require('../../../images/expert/sberbank.png')}
						style={{ maxWidth: '80px' }}
					/>
					<p className='fs-4'>Fullstack developer</p>
					<div
						className='d-flex mt-4'
						style={{ gap: '10px', minWidth: '350px' }}
					>
						<button
							className='btn py-2 px-4 rounded-3 w-100'
							style={{ color: 'white', backgroundColor: '#40C700', flex: '1' }}
						>
							Рассмотреть
						</button>
						<button
							className='btn py-2 px-4 danger rounded-3 w-100'
							style={{ color: 'white', backgroundColor: '#40C700', flex: '1' }}
						>
							Отказать
						</button>
					</div>
				</div>
			</div>
			<img
				src={require('../../../images/expert/sber_sm.png')}
				className='w-100 d-md-none d-block'
			/>

			<div className='row my-sm-5 my-4 gy-3'>
				<div className='col-sm'>
					<div style={{ maxWidth: '550px' }}>
						<p className='fs-3 mb-3'>
							Подберём лучший формат резюме для ваших целей
						</p>
						<p className='fs-5 fw-light' style={{ color: COLORS.header_text }}>
							Исходя из ваших профессии и сферы, выберем формат отклика, который
							позволит максимально привлекательно отразить ваш опыт и обратить
							на него внимание рекрутеров
						</p>
					</div>
				</div>
				<div className='col-sm'>
					<p className='fs-3 mb-3'>
						Выгодно опишем текущий опыт и достижения под интересную вам позицию
					</p>
					<p className='fs-5 fw-light' style={{ color: COLORS.header_text }}>
						Резюме напишет эксперт с опытом найма в вашей сфере, который точно
						знает, что работодатели ищут в кандидатах. Эти детали он и
						подчеркнёт в вашем резюме
					</p>
				</div>
			</div>

			<div className='row my-sm-5 my-4'>
				<div className='col-sm'>
					<img
						src={require('../../../images/expert/chat.png')}
						style={{ objectFit: 'contain', width: '100%' }}
					/>
				</div>
				<div className='col-sm'>
					<p className='fs-3 mb-3 mt-4'>
						Поддержим и проконсультируем по поиску работы в чате
					</p>
					<p className='fs-5 fw-light' style={{ color: COLORS.header_text }}>
						Эксперт объяснит принципы создания хорошего резюме, ответит на
						вопросы о нюансах в откликах и будет поддерживать связь на
						протяжении всей совместной работы
					</p>

					<p className='fs-3 mb-3 mt-5'>
						Составим текстовое заключение встречи и пришлём его в телеграм
					</p>
					<p className='fs-5 fw-light' style={{ color: COLORS.header_text }}>
						После встречи эксперт напишет текстовые рекомендации по улучшению
						резюме, просуммирует результаты встречи и пришлёт файл в удобный
						мессенджер или на почту.
					</p>
				</div>
			</div>

			<p className='fs-1  text-center mt-5 mb-sm-5 mb-4 pt-sm-5'>
				Как это работает
			</p>

			<div className='row'>
				{[
					{
						text: 'Укажите свою электронную почту. Выберите удобные дату и время',
						img: 'step1.png',
						icon: (
							<div
								className='rounded-4 d-flex fs-1 position-absolute text-center'
								style={{
									top: '-20px',
									left: '-10px',
									transform: 'rotate(22deg)',
									color: 'white',
									backgroundColor: COLORS.primary,
									width: 'min(80px, 10vw)',
									height: 'min(80px, 10vw)',
								}}
							>
								<p className='m-auto'>1</p>
							</div>
						),
					},
					{
						text: 'Специалист разберет ваше резюме и дает рекомендации по его улучшению',
						img: 'step2.png',
						icon: (
							<div
								className='rounded-4 d-flex fs-1 position-absolute text-center'
								style={{
									top: '-20px',
									left: '40%',
									transform: 'rotate(8deg)',
									color: 'white',
									backgroundColor: COLORS.primary,
									width: 'min(80px, 10vw)',
									height: 'min(80px, 10vw)',
								}}
							>
								<p className='m-auto'>2</p>
							</div>
						),
					},
					{
						text: 'Ура! Можно начинать откликаться',
						img: 'step3.png',
						icon: (
							<div
								className='rounded-4 d-flex  fs-1 position-absolute text-center'
								style={{
									top: '-20px',
									right: '-5px',
									transform: 'rotate(-38deg)',
									color: 'white',
									backgroundColor: COLORS.primary,
									width: 'min(80px, 10vw)',
									height: 'min(80px, 10vw)',
								}}
							>
								<p className='m-auto'>3</p>
							</div>
						),
					},
				].map((el, i) => (
					<GrayCard key={i} data={el} />
				))}
			</div>

			<p className='fs-1  text-center mt-5 mb-sm-5 mb-4'>Сколько стоит?</p>

			<FooterForm
				state={state}
				setState={setState}
				handleSignUp={handleSignUp}
			/>
		</div>
	)
}

const GrayCard = ({ data }) => {
	return (
		<div className='col-xl-4 col-md-6 mb-3'>
			<div
				className='rounded-5 p-sm-4 p-3 h-100 position-relative'
				style={{ backgroundColor: '#F7F7F7' }}
			>
				{data.icon}
				<div className='d-flex flex-column gy-2 flex-nowrap'>
					<div className=' pe-sm-auto'>
						<div className='bg-white rounded-5 d-flex  h-100'>
							<img
								src={require('../../../images/expert/' + data.img)}
								style={{ maxWidth: '100%' }}
							/>
						</div>
					</div>
					<div className='d-flex flex-column my-auto'>
						<div className='d-flex mb-md-3 my-1 mt-sm-3'>
							<p
								className='me-auto my-auto fs-4 fw-bolded'
								style={{ color: 'white' }}
							>
								{data.title}
							</p>
						</div>
						<p className='fs-5'>{data.text}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

const Statement = ({ text }) => {
	return (
		<div className='d-flex mb-3'>
			<CheckSvg
				style={{ width: '24px', height: '24px', flexShrink: '0' }}
				className='me-4'
			/>
			<p>{text}</p>
		</div>
	)
}

const FooterForm = ({ state, setState, handleSignUp }) => {
	const loc = useLocation()
	return (
		<form
			onSubmit={e => e.preventDefault()}
			id='submit'
			className='bg-white rounded-5 mb-5'
			style={{
				filter: 'drop-shadow(0px 0px 15px #dee)',
				borderRadius: '20px',
				overflow: 'hiddden',
			}}
		>
			<div className='row'>
				<div className='col-sm-5'>
					<div
						className='p-4 d-md-flex d-none flex-column m-4'
						style={{
							backgroundColor: '#F9FAFA',
							borderTopLeftRadius: '20px',
							borderBottomLeftRadius: '20px',
							height: '500px',
						}}
					>
						<div style={{ maxWidth: '300px' }}>
							<p className='fs-4 mb-3 fw-light'>Разбор резюме</p>
							{[
								'длится 15-20 минут',
								'подробный разбор резюме',
								'текстовое заключение по результатам встречи',
							].map((el, i) => (
								<Statement text={el} />
							))}
						</div>
						<div className='mt-auto'>
							<p className='fs-6' style={{ color: COLORS.header_text }}>
								Стоимость
							</p>
							{loc.pathname.includes('/cv') ? (
								<p className='fs-3'>299 рублей</p>
							) : (
								<p className='fs-3'>
									1 рубль
									<span
										style={{ color: COLORS.header_text }}
										className='text-decoration-line-through fs-5 ms-3'
									>
										599 руб.
									</span>
								</p>
							)}
						</div>
					</div>

					<div
						className='d-md-none d-flex p-4 flex-column'
						style={{
							backgroundColor: '#F9FAFA',
							borderRadius: '20px',
							height: '300px',
						}}
					>
						<div style={{ maxWidth: '300px' }}>
							<p className='fs-4 mb-3 fw-light'>Разбор резюме</p>
							{[
								'длится 15-20 минут',
								'подробный разбор резюме',
								'текстовое заключение по результатам встречи',
							].map((el, i) => (
								<Statement text={el} />
							))}
						</div>
						<div className='mt-auto'>
							<p className='fs-6' style={{ color: COLORS.header_text }}>
								Стоимость
							</p>
							{loc.pathname.includes('/cv') ? (
								<p className='fs-3'>299 рублей</p>
							) : (
								<p className='fs-3'>
									1 рубль
									<span
										style={{ color: COLORS.header_text }}
										className='text-decoration-line-through fs-5 ms-2'
									>
										599 руб.
									</span>
								</p>
							)}
						</div>
					</div>
				</div>
				<div className='col d-flex flex-column p-4 me-md-4'>
					<p className='fs-4 mb-2'>Почта</p>
					<AppInput
						value={state.email}
						onChange={v => setState({ ...state, email: v })}
						className='mb-3'
						label='somebodysemail@gmail.com'
						placeholder='somebodysemail@gmail.com'
					/>
					<button
						onClick={() => handleSignUp()}
						className='btn primary rounded-3 px-5 py-2 ms-md-auto'
					>
						Записаться
					</button>
				</div>
			</div>
		</form>
	)
}

const Card = ({ data }) => {
	return (
		<div
			className='rounded-5 p-md-4 p-3  position-relative mx-auto w-100'
			style={{ maxWidth: '1000px', backgroundColor: '#F7F7F7' }}
		>
			{data.icon}

			<div className='row'>
				<div className='col-5'>
					<div
						className='bg-white rounded-5 d-flex py-md-3 py-0 h-100'
						style={{ overflow: 'hidden' }}
					>
						<img
							src={require('../../../images/expert/' + data.img)}
							className='mx-auto rounded-5'
							style={{
								maxWidth: 'min(100%,27vw)',
								height: 'auto',
								flexShrink: '0',
							}}
						/>
					</div>
				</div>
				<div className='col d-flex' style={{ fontWeight: '200' }}>
					<p
						className='fs-4 d-md-block d-none my-auto ps-5'
						style={{ maxWidth: '400px' }}
					>
						{data.text}
					</p>
					<p
						className='fs-6 d-md-none d-block my-auto'
						style={{ maxWidth: '400px' }}
					>
						{data.text}
					</p>
				</div>
			</div>
		</div>
	)
}

const MainLayer = () => {
	return (
		<div
			id='background_image_layer'
			className='position-relative d-md-block d-none'
			style={{ height: 'auto' }}
		>
			<div
				className='d-flex flex-column pt-md-5 px-5'
				style={{ zIndex: '2 !important' }}
			>
				<div className='row w-100'>
					<div className='col d-md-flex d-none'>
						<div className='bg-white p-4 rounded-4 mt-md-4'>
							<p className='mb-3 fs-4 fw-bold'>Мои навыки</p>
							<div className='d-flex' style={{ gap: '10px' }}>
								{['Python', 'FastAPI', 'Docker'].map((el, i) => (
									<p
										key={i}
										className='rounded-pill px-4 py-2 text-center'
										style={{
											flex: '1',
											backgroundColor: '#BBAEF5',
											color: 'white',
										}}
									>
										{el}
									</p>
								))}
							</div>
						</div>
					</div>
					<div className='col'>
						<div className='d-flex h-100 w-100 ps-5'>
							<MedalSvg className='me-auto mt-auto' />
							<StarSvg className='ms-auto' />
						</div>
					</div>
					<div className='col d-md-flex d-none'>
						<p className='bg-white rounded-pill fs-4 mt-3 text-center py-2 px-3 ms-auto mb-auto'>
							150 000 - 200 000 руб./мес.
						</p>
					</div>
				</div>

				<img
					className='mx-auto mt-md-4'
					style={{ maxWidth: 'min(340px, 30vw)' }}
					src={require('../../../images/expert/loading_btn.png')}
				/>

				<div className='row'>
					<div className='col-4 d-flex'>
						<img
							className='my-auto'
							src={require('../../../images/expert/search.png')}
						/>
					</div>
					<div className='col-4'>
						<div className='w-100 h-100 d-flex'>
							<AppCheckbox
								checked={true}
								size='30px'
								fs='fs-5'
								className='m-auto'
								label={'Работа мечты'}
							/>
						</div>
					</div>
					<div className='col-4 d-flex'>
						<img
							className='ms-auto'
							style={{ maxWidth: '300px', marginTop: '-100px' }}
							src={require('../../../images/expert/usercard.png')}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

const MainLayerMobile = () => {
	return (
		<>
			<img
				src={require('../../../images/expert/head_layer_sm.png')}
				className='w-100 d-md-none d-block mb-3'
				style={{}}
			/>
			<a
				href='#submit'
				className='btn primary rounded-3 py-2 px-4 fw-bolded d-md-none d-block'
				style={{ minHeight: '45px' }}
			>
				Заказать резюме
			</a>
		</>
	)
}
