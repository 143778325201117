import {setContacts} from "../../store/slices/resumeSlice"
import {createResumeContact, deleteResumeContact} from "../../api/resume"

export const createContact = async (access_token, type, resume, dispatch) => {
    const data = await createResumeContact(access_token, resume, type)
    if (!data) return
    await dispatch(setContacts([...resume.contacts, data]))
}

export const editContact = async (access_token, contactId, updated, resumeContacts, dispatch, updateServerContact) => {
    const contact = {...resumeContacts.find(e => e.id === contactId), ...updated}
    await dispatch(setContacts(resumeContacts.map(e => e.id === contactId ? contact : e)))
    updateServerContact(access_token, contactId, updated)
}

export const deleteContact = async (access_token, id, resumeContacts, dispatch) => {
    const ok = await deleteResumeContact(access_token, id)
    if (!ok) return
    await dispatch(setContacts(resumeContacts.filter(e => e.id !== id)))
}