import { createSlice } from '@reduxjs/toolkit'

export const bookingSlice = createSlice({
  name: 'bookingSlice',
  initialState: {
    showModal: false,
    timer: 900,
  },
  reducers: {
    setShowModal: (state, action) => {
      state.showModal = action.payload
    },
    setTimer: (state, action) => {
      state.timer = action.payload
    },
  },
})

export const { setShowModal, setTimer } = bookingSlice.actions
export default bookingSlice.reducer
