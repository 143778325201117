// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/expert/layer.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../images/expert/layer_sm.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../images/expert/layer_reverse.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#background_image_layer {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: 100%;
    background-repeat: no-repeat;
}

#background_image_layer_sm {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-size: 100%;
    background-repeat: no-repeat;
}

#background_image_layer_reverse {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
    background-size: 100%;
    background-repeat: no-repeat;
}`, "",{"version":3,"sources":["webpack://./src/screens/landings/Consultations/styles.css"],"names":[],"mappings":"AAAA;IACI,yDAAyD;IACzD,qBAAqB;IACrB,4BAA4B;AAChC;;AAEA;IACI,yDAA4D;IAC5D,qBAAqB;IACrB,4BAA4B;AAChC;;AAEA;IACI,yDAAiE;IACjE,qBAAqB;IACrB,4BAA4B;AAChC","sourcesContent":["#background_image_layer {\n    background-image: url('../../../images/expert/layer.png');\n    background-size: 100%;\n    background-repeat: no-repeat;\n}\n\n#background_image_layer_sm {\n    background-image: url('../../../images/expert/layer_sm.png');\n    background-size: 100%;\n    background-repeat: no-repeat;\n}\n\n#background_image_layer_reverse {\n    background-image: url('../../../images/expert/layer_reverse.png');\n    background-size: 100%;\n    background-repeat: no-repeat;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
