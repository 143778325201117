// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards:hover {
    transform: scale(1.02);
    box-shadow: 0px 0px 30px #eee;
}`, "",{"version":3,"sources":["webpack://./src/screens/vacancies/components/VacancyCard.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,6BAA6B;AACjC","sourcesContent":[".cards:hover {\n    transform: scale(1.02);\n    box-shadow: 0px 0px 30px #eee;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
