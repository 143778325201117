import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router'
import {
  getMyAccount,
  isProcessingPDF,
  pollingPDFJob,
  updateUserResume,
} from '../../api/applicant'
import { updateUserMeetings } from '../../api/meetings'
import { Loader } from '../../components/Loader'
import { MeetingInfoModal } from '../../components/Modals'
import ProfileNavbar from '../../components/ProfileNavbar'
import { COLORS } from '../../constants'
import { ReactComponent as ExclamationSvg } from '../../icons/account_stats/exclamation.svg'
import { SetInterview } from '../auth/layouts/SetInterview'
import { NotFoundPage } from '../system/NotFoundPage'
import { Meetings } from './layouts/Meetings'
import { Offers } from './layouts/Offers'
import ProfilePage from './layouts/ProfilePage'
import { Responses } from './layouts/Responses'
import ResumePage from './layouts/ResumePage'
import { SettingsLayout } from './layouts/Settings'
import { NeuroResume } from './layouts/neuro/NeuroResume'
import Cookie from 'js-cookie'

import { createAiChat } from '../../api/applicantAiChats'
export const AccountScreen = () => {
  const user = useSelector((state) => state.userSlice.user)
  const resume = useSelector((state) => state.resumeSlice.resume)
  const loc = useLocation()
  const dispatch = useDispatch()
  const meetingsData = useSelector((state) => state.userSlice.meetingsData)
  const [state, setState] = useState({ pdfProcessing: false })
  const [chatId, setChatId] = useState('')
  Cookie.set('resumeID', resume.id)

  useEffect(() => {
    (async () => {
      if (user.access_token !== undefined) {
        await updateUserMeetings(user.access_token, dispatch, [
          'slot',
          'manager',
          'feedback',
        ])
        await updateUserResume(user.access_token, dispatch)
        if (await isProcessingPDF(dispatch, user.access_token)) {
          console.log('USER ACCOUNT SCREEN', user)
          console.log('RESUME id', resume.id)
          setState((p) => ({ ...p, pdfProcessing: true }))
          await pollingPDFJob(user.access_token, dispatch, state, setState)
        }
      }
    })()
  }, [user.access_token])

  useEffect(() => {
    if (chatId) return
    async function f() {
      const response = await getMyAccount(user.access_token, ['aiChats'])
      const trueUser = await response.json()

      let id = trueUser?.aiChats ? trueUser?.aiChats[0]?.id : ''

      if (!id) {
        const result = await createAiChat(user.access_token, trueUser.id)
        id = result.id
      }
      setChatId(id)
    }
    f()
  }, [user])

  if (user.access_token === undefined) {
    return <Navigate to='/auth' />
  }
  return !user.loaded || !meetingsData.loaded ? (
    <Loader />
  ) : (
    <div className='pb-5'>
      {!loc.pathname.includes('settings') &&
        !loc.pathname.includes('offers') && <ProfileNavbar chatId={chatId} />}
      {(loc.pathname.endsWith('account') ||
        loc.pathname.endsWith('resume')) && (
        <Info
          meetings={meetingsData.meetings}
          access_token={user.access_token}
        />
      )}
      <Routes>
        <Route path='/' element={<ProfilePage />} />
        <Route
          path='resume'
          element={<ResumePage pdfProcessing={state.pdfProcessing} />}
        />
        <Route path='meetings' element={<Meetings />} />
        <Route
          path='neuro'
          element={
            chatId ? (
              <NeuroResume chatId={chatId} />
            ) : (
              <Navigate to='/account' />
            )
          }
        />
        <Route path='set_interview' element={<SetInterview />} />
        <Route path='responses' element={<Responses />} />
        <Route path='offers' element={<Offers />} />
        <Route path='settings' element={<SettingsLayout />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </div>
  )
}

const Info = ({ meetings, access_token }) => {
  const [state, setState] = useState({
    meetingInfoModal: {
      shown: false,
      meeting: {},
    },
  })
  const navigate = useNavigate()
  const activeMeeting = (meetings.filter((m) => m.status === 'PLANNED') ||
    [])[0]
  const hasMeetingFeedback = !!(meetings || [{}]).filter(
    (m) => (m.feedback || []).length > 0
  )
  return (
    <>
      <div className='w-sm-100 w-sm-max flex-row flex-wrap mb-md-4 mb-2 bg-white px-4 py-3 rounded-3'>
        <div className='row justify-content-center gap-2'>
          {meetings.length !== 0 && (
            <MeetingInfoModal
              meetingInfoModal={state.meetingInfoModal}
              setState={setState}
              past={false}
            />
          )}
          <div className='col-auto my-auto'>
            <ExclamationSvg style={{ height: '60px' }} />
          </div>
          <div className='col my-auto'>
            {meetings.length === 0 ? (
              <p
                className='my-auto fs-5'
                style={{ fontWeight: '300', cursor: 'pointer' }}
              >
                Необходимо{' '}
                <span
                  style={{ color: COLORS.primary }}
                  className='underline'
                  onClick={() =>
                    navigate('/account/set_interview', {
                      state: { access_token: access_token },
                    })
                  }
                >
                  назначить
                </span>{' '}
                дату собеседования
              </p>
            ) : activeMeeting ? (
              <p className='my-auto fs-5'>
                У вас назначено собеседование на&nbsp;
                <span
                  className='underline'
                  onClick={() =>
                    setState({
                      meetingInfoModal: {
                        shown: true,
                        meeting: activeMeeting,
                      },
                    })
                  }
                  style={{
                    fontWeight: '400',
                    cursor: 'pointer',
                    color: COLORS.primary,
                  }}
                >
                  {activeMeeting.slot &&
                    new Date(activeMeeting.slot.dateTime)
                      .toLocaleString()
                      .slice(0, -3)}
                </span>
              </p>
            ) : hasMeetingFeedback ? (
              <p className='my-auto fs-5'>
                Результаты{' '}
                <span
                  className='underline'
                  onClick={() => navigate('/account/meetings')}
                  style={{
                    fontWeight: '400',
                    cursor: 'pointer',
                    color: COLORS.primary,
                  }}
                >
                  собеседования
                </span>{' '}
                готовы
              </p>
            ) : (
              <p className='my-auto fs-5'>
                Ожидайте результатов
                <span
                  className='underline'
                  onClick={() => navigate('/account/meetings')}
                  style={{
                    fontWeight: '400',
                    cursor: 'pointer',
                    color: COLORS.primary,
                  }}
                >
                  {' '}
                  собеседования
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
