import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { patchMyAccount } from '../../../api/applicant'
import { Loader } from '../../../components/Loader'
import {
    ConfirmMailModal,
    PublishPortfolioModal,
} from '../../../components/Modals'
import { AppCheckbox } from '../../../components/ui/AppCheckbox'
import { AppInput } from '../../../components/ui/AppInput'
import { AppSelect } from '../../../components/ui/AppSelect'
import {
    COLORS,
    SOURCE,
    Validation,
    applicantGenders,
} from '../../../constants'
import { showErrorMsg, showSuccessMsg } from '../../../store/slices/alertSlice'
import { setUser } from '../../../store/slices/userSlice'

const url = SOURCE.url

const formatDateString = dateString => {
  const digits = dateString.replace(/\D/g, '')
  let formatted = ''
  if (digits.length > 0) {
    formatted += digits.substring(0, 2)
  }
  if (digits.length > 2) {
    formatted += '.' + digits.substring(2, 4)
  }
  if (digits.length > 4) {
    formatted += '.' + digits.substring(4, 8)
  }
  return formatted
}

const formatDateForServer = dateString => {
  const [day, month, year] = dateString.split('.')
  if (year && month && day) {
    return `${year}-${month}-${day}T12:00:00Z`
  }
  return dateString
}

export const SettingsLayout = () => {
  const user = useSelector(state => state.userSlice.user)
  const [state, setState] = useState({
    patchUser: {
      firstName: user.firstName,
      lastName: user.lastName,
      contact: user.contact,
    },
    publishModalShown: false,
    confirmMailModalShown: false,
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const mappedGenders = Object.entries(applicantGenders).map(
    ([key, value]) => ({ value: key === 'null' ? null : key, label: value })
  )
  return !user.loaded ? (
    <Loader />
  ) : (
    <>
      <p className='fs-3 mb-3' style={{ fontWeight: '400' }}>
        Общие настройки
      </p>
      <div className='bg-white w-sm-max rounded-3 px-4 px-sm-5 py-4'>
        <PublishPortfolioModal state={state} setState={setState} />
        {state.confirmMailModalShown && (
          <ConfirmMailModal
            state={state}
            setState={setState}
            access_token={user.access_token}
          />
        )}
        <div className='row'>
          <div className='col-md'>
            <AppInput
              label='Имя'
              showAlert={!Validation.validName(state.patchUser.firstName)}
              value={state.patchUser.firstName}
              onChange={v =>
                setState(p => ({
                  ...p,
                  patchUser: { ...p.patchUser, firstName: v.trim() },
                }))
              }
            />
          </div>
          <div className='col'>
            <AppInput
              label='Фамилия'
              showAlert={!Validation.validName(state.patchUser.lastName)}
              value={state.patchUser.lastName}
              onChange={v =>
                setState(p => ({
                  ...p,
                  patchUser: { ...p.patchUser, lastName: v.trim() },
                }))
              }
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md'>
            <AppInput
              label='Отчество'
              showAlert={
                state.patchUser.middleName !== undefined &&
                !Validation.validName(state.patchUser.middleName)
              }
              onChange={v =>
                setState(p => ({
                  ...p,
                  patchUser: { ...p.patchUser, middleName: v.trim() },
                }))
              }
              value={
                state.patchUser.middleName === undefined
                  ? user.middleName
                  : state.patchUser.middleName
              }
            />
          </div>
          <div className='col'>
            <AppInput
              label='Дата рождения'
              type='text'
              showAlert={
                state.patchUser.birthDate !== undefined &&
                !Validation.validDate(state.patchUser.birthDate)
              }
              value={
                state.patchUser.birthDate === undefined
                  ? formatDateString(
                      user.birthDate
                        .split('T')[0]
                        .split('-')
                        .reverse()
                        .join('.')
                    )
                  : formatDateString(
                      state.patchUser.birthDate
                        .split('T')[0]
                        .split('-')
                        .reverse()
                        .join('.')
                    )
              }
              onChange={v => {
                const formattedDate = formatDateString(v.trim())
                setState(p => ({
                  ...p,
                  patchUser: {
                    ...p.patchUser,
                    birthDate: formatDateForServer(formattedDate),
                  },
                }))
              }}
              disabled={false}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md'>
            <AppInput
              label='Контакт'
              value={state.patchUser.contact}
              showAlert={state.patchUser.contact === ''}
              onChange={v =>
                setState(p => ({
                  ...p,
                  patchUser: { ...p.patchUser, contact: v.trim() },
                }))
              }
            />
          </div>

          <div className='col'>
            <AppInput
              label={
                'Электронная почта' + !user.isEmailConfirmed
                  ? 'Не подтверждена'
                  : 'Подтверждена'
              }
              aria-describedby='emailHelp'
              type='email'
              value={user.email}
              disabled={true}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <AppSelect
              options={mappedGenders}
              label='Пол'
              value={
                state.patchUser.gender === undefined
                  ? user.gender
                  : state.patchUser.gender
              }
              onChange={e =>
                setState(p => ({
                  ...p,
                  patchUser: { ...p.patchUser, gender: e.target.value },
                }))
              }
            />
          </div>
          <div className='col-md'>
            <AppInput
              label='Страна'
              showAlert={
                state.patchUser.country !== undefined &&
                !Validation.validName(state.patchUser.country)
              }
              value={
                state.patchUser.country === undefined
                  ? user.country
                  : state.patchUser.country
              }
              onChange={v =>
                setState(p => ({
                  ...p,
                  patchUser: { ...p.patchUser, country: v.trim() },
                }))
              }
            />
          </div>
        </div>
        <AppCheckbox
          label='Готов к переезду'
          className='mt-sm-4 mt-2'
          checked={
            state.patchUser.isReadyToRelocate === undefined
              ? user.isReadyToRelocate
              : state.patchUser.isReadyToRelocate
          }
          onChange={() =>
            setState(p => ({
              ...p,
              patchUser: {
                ...p.patchUser,
                isReadyToRelocate: !p.patchUser.isReadyToRelocate,
              },
            }))
          }
        />
        <p className='fs-4 mt-sm-4 mt-3 mb-3' style={{ fontWeight: '400' }}>
          Настройки приватности
        </p>
        <AppCheckbox
          label='Показывать профиль работодателям'
          className='mb-sm-4 mb-2'
          fs='fs-6'
          checked={
            state.patchUser.isVisibleToEmployers === undefined
              ? user.isVisibleToEmployers
              : state.patchUser.isVisibleToEmployers
          }
          onChange={() =>
            setState(p => ({
              ...p,
              patchUser: {
                ...p.patchUser,
                isVisibleToEmployers: !p.patchUser.isVisibleToEmployers,
              },
            }))
          }
        />
        <AppCheckbox
          label='Сделать профиль общедоступным по ссылке'
          className='mb-sm-4'
          fs='fs-6'
          checked={user.nickname !== null}
          onChange={async () => {
            if (user.nickname === null) {
              setState(p => ({ ...p, publishModalShown: true }))
            } else {
              const data = await patchMyAccount(
                { nickname: null },
                user.access_token,
                dispatch
              ).catch(e => {
                console.error(e)
                dispatch(showErrorMsg(e.errors))
              })
              if (data.error) dispatch(showErrorMsg('Произошла ошибка'))
              else dispatch(setUser({ ...user, nickname: null }))
            }
          }}
        />

        <button
          className='btn primary px-5 py-2 rounded-3 mb-2 fs-6 w-auto'
          onClick={() =>
            patchMyAccount(state.patchUser, user.access_token, dispatch)
              .then(data => {
                if (data.error)
                  dispatch(showErrorMsg('Произошла ошибка на сервере'))
                else {
                  dispatch(showSuccessMsg('Данные сохранены'))
                  dispatch(setUser({ ...user, ...data }))
                }
              })
              .catch(e => {
                console.error(e)
                dispatch(showErrorMsg(e.errors))
              })
          }
        >
          Сохранить изменения
        </button>
        {/* <div className='d-flex flex-row flex-wrap mt-sm-5 mt-3 bg-info'>
          <button className='btn px-3 py-2 rounded-3 light_red_primary mb-2 fs-6'
                        onClick={() => window.confirm('Вы действительно хотите удалить ваш аккаунт?') && deleteMyAccount(user.access_token, navigate)
                            .catch(e => {console.error(e); dispatch(showErrorMsg('Произошла ошибка на сервере'))})}
                        style={{
                            backgroundColor: COLORS.red_light_primary,
                            color: COLORS.red_primary,
                            minWidth: '240px'
                        }}>Удалить аккаунт
                </button>
        </div> */}
      </div>
    </>
  )
}
