import { configureStore } from '@reduxjs/toolkit'
import resumeSlice from './slices/resumeSlice'
import userSlice from './slices/userSlice'
import alertSlice from './slices/alertSlice'
import vacanciesSlice from './slices/vacanciesSlice'
import guestSlice from './slices/guestSlice'
import bookingSlice from './slices/bookingSlice'

export default configureStore({
  reducer: {
    userSlice: userSlice,
    guestSlice: guestSlice,
    resumeSlice: resumeSlice,
    vacanciesSlice: vacanciesSlice,
    alertSlice: alertSlice,
    bookingSlice: bookingSlice,
  },
})
