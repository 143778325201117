import { useEffect, useState } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { getMyAccount, updateUserResume } from '../api/applicant'
import { COLORS, PASTEL_COLORS, SOURCE, switchTitle } from '../constants'
import { getStatus } from '../screens/auth/layouts/NextStep'
import { showErrorMsg } from '../store/slices/alertSlice'
import { setUser } from '../store/slices/userSlice'
import { LogoutModal } from './Modals'

let i = Math.floor(Math.random() * PASTEL_COLORS.length)

export const MainHeader = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const loc = useLocation()
	const isOzon = loc.pathname === '/please' || loc.pathname === '/tamara'
	const user = useSelector(state => state.userSlice.user)
	const [state, setState] = useState({
		logoutModalShown: false,
	})

	useEffect(() => {
		const navbarCollapse = document.getElementById('responsive-navbar-nav')
		if (navbarCollapse && navbarCollapse.classList.contains('show')) {
			navbarCollapse.classList.remove('show')
		}
	}, [loc.pathname])

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await getMyAccount(user.access_token)
				updateUserResume(user.access_token, dispatch)
				if (response === null) {
					dispatch(showErrorMsg('Произошла ошибка!'))
					navigate('/logout')
					return
				}
				if (response.status === 429) {
					dispatch(showErrorMsg('Слишком частые запросы'))
					navigate('/spam')
					return
				}
				if (response.status === 401) return navigate('/logout')

				if (!response.ok) {
					dispatch(showErrorMsg('Пользователь не найден!'))
					navigate('/logout')
					return
				}
				const data = await response.json()
				const status = await getStatus(user.access_token, data.id, navigate)

				dispatch(
					setUser({
						...data,
						...status,
						loaded: true,
						access_token: user.access_token,
					})
				)
			} catch (e) {
				dispatch(showErrorMsg('Ошибка при загрузке данных!'))
				navigate('/logout')
			}
		}

		if (user.access_token !== undefined) {
			fetchData()
		} else {
			dispatch(
				setUser({
					...user,
					loaded: true,
				})
			)
		}
	}, [user.access_token])

	useEffect(() => switchTitle(loc.pathname), [loc.pathname])

	return (
		<div className='py-2' style={{ transition: 'none', outline: 'none' }}>
			<LogoutModal shown={state.logoutModalShown} setState={setState} />
			<Navbar collapseOnSelect expand='md'>
				<Navbar.Brand className='me-5'>
					<Nav.Link eventKey='1'>
						<img
							src={
								SOURCE.source_url +
								(isOzon ? 'brand_footer.svg' : 'hurtle-logo.svg')
							}
							style={{ cursor: 'pointer', height: '35px' }}
							onClick={() => navigate('/')}
							alt=''
						/>
					</Nav.Link>
				</Navbar.Brand>
				<Navbar.Toggle
					aria-controls='responsive-navbar-nav'
					bsPrefix='no_outline'
				>
					<img
						className='d-block d-md-none'
						src={SOURCE.source_url + (isOzon ? 'menu-white.svg' : 'menu.svg')}
						style={{ height: '30px' }}
						alt=''
					/>
				</Navbar.Toggle>
				<Navbar.Collapse id='responsive-navbar-nav'>
					<Nav className='me-auto mt-md-0 mt-3'>
						{!isOzon &&
							(loc.pathname.includes('/account') ||
							(loc.pathname.includes('/vacancies') && user.access_token) ? (
								<AccountBar pathname={loc.pathname} />
							) : (
								<DefaultBar pathname={loc.pathname} />
							))}
					</Nav>
					<Nav>
						{user.access_token !== undefined ? (
							<AccountRightBar
								user={user}
								setState={setState}
								pathname={loc.pathname}
							/>
						) : (
							!loc.pathname.includes('/auth') && (
								<RightBarDefault pathname={loc.pathname} />
							)
						)}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	)
}

const DefaultBar = ({ pathname }) => {
	const navigate = useNavigate()
	return pathname.includes('/expert') ? (
		<Nav.Link
			className='text-nowrap pe-4'
			onClick={() => navigate('/expert')}
			eventKey='1'
			style={{
				color: COLORS.text,
				fontSize: '18px',
				outline: 'none',
			}}
		>
			Консультации
		</Nav.Link>
	) : (
		<>
			<Nav.Link
				className='text-nowrap pe-4'
				onClick={() => navigate('/vacancies')}
				eventKey='1'
				style={{
					color: pathname.includes('/vacancies')
						? COLORS.text
						: COLORS.header_text,
						fontWeight: '400',
					fontSize: '18px',
					outline: 'none',
				}}
			>
				Вакансии
			</Nav.Link>
			<Nav.Link
				className='text-nowrap pe-4'
				onClick={() => window.location.replace('https://hunt.hurtle.ru/')}
				eventKey='2'
				style={{
					color: COLORS.header_text,
					fontWeight: '400',
					fontSize: '18px',
					outline: 'none',
				}}
			>
				Найти сотрудников
			</Nav.Link>
			<Nav.Link
				className='text-nowrap'
				onClick={() => navigate('/contacts')}
				eventKey='3'
				style={{
					color: pathname === '/contacts' ? COLORS.text : COLORS.header_text,
					fontWeight: '400',
					fontSize: '18px',
					outline: 'none',
				}}
			>
				Контакты
			</Nav.Link>
		</>
	)
}

const AccountBar = ({ pathname }) => {
	const navigate = useNavigate()
	return (
		<>
			<Nav.Link
				className='pe-4'
				onClick={() => navigate('/account')}
				eventKey='4'
				style={{
					color:
						pathname === '/account' || pathname.includes('offers')
							? COLORS.text
							: COLORS.header_text,
					fontWeight: '400',
					fontSize: '18px',
					outline: 'none',
				}}
			>
				Главное
			</Nav.Link>
			<Nav.Link
				className='pe-4'
				onClick={() => navigate('/vacancies')}
				eventKey='6'
				style={{
					color: pathname.includes('/vacancies')
						? COLORS.text
						: COLORS.header_text,
					fontWeight: '400',
					fontSize: '18px',
					outline: 'none',
				}}
			>
				Вакансии
			</Nav.Link>
			<Nav.Link
				onClick={() => navigate('/account/settings')}
				eventKey='5'
				style={{
					color: pathname.includes('/settings')
						? COLORS.text
						: COLORS.header_text,
					fontWeight: '400',
					fontSize: '18px',
					outline: 'none',
				}}
			>
				Настройки
			</Nav.Link>
		</>
	)
}

const RightBarDefault = ({ pathname }) => {
	const navigate = useNavigate()
	return (
		<div className='d-flex flex-row flex-wrap-reverse align-items-center justify-content-start justify-content-md-end text-nowrap no_outline'>
			{['/expert', '/cv'].some(el => pathname === el) ? (
				<a
					className='btn primary_outline rounded-pill px-5 py-2'
					href='#submit'
				>
					Войти
				</a>
			) : pathname === '/please' || pathname === '/tamara' ? (
				<button
					className='btn black_outline rounded-pill px-5 py-2'
					onClick={() => navigate('/auth')}
				>
					Регистрация
				</button>
			) : (
				<>
					<Nav.Link
						onClick={() => navigate('/auth')}
						style={{ fontSize: '14px' }}
						eventKey='1'
						className='btn me-lg-4 me-2 px-4 py-2 rounded-3 primary my-auto'
					>
						Регистрация
					</Nav.Link>
					<Nav.Link
						onClick={() => navigate('/auth', { state: {} })}
						className='btn rounded-3 py-2 px-4'
						eventKey='2'
						style={{
							fontSize: '14px',
							backgroundColor: COLORS.header_text,
							color: 'white',
						}}
					>
						Вход в аккаунт
					</Nav.Link>
				</>
			)}
		</div>
	)
}

const AccountRightBar = ({ user, setState, pathname }) => {
	const navigate = useNavigate()
	return !user.loaded || !user.id ? (
		<AccountRightBarPlaceholder />
	) : (
		<div className='d-flex flex-row-reverse flex-md-row no_outline justify-content-end justify-content-md-start'>
			<div className='d-flex flex-column me-3 mt-1'>
				<p
					className='text-wrap text-end align-items-end d-none d-md-block'
					style={{
						color:
							pathname === '/tamara' || pathname === '/please'
								? COLORS.secondary
								: COLORS.text,
					}}
				>
					{user.lastName + ' ' + user.firstName}
				</p>
				<Nav.Link
					style={{ color: COLORS.primary }}
					className='p-0 text-decoration-none align-self-end text-nowrap my-auto'
					eventKey='10'
					onClick={() => setState(p => ({ ...p, logoutModalShown: true }))}
				>
					Выйти
				</Nav.Link>
			</div>
			<div className='my-auto me-md-0 me-3'>
				<Nav.Link
					onClick={() => navigate('/account')}
					className='d-flex lh-lg align-items-center justify-content-center'
					eventKey='11'
					style={{
						fontSize: '18px',
						backgroundColor: PASTEL_COLORS[i],
						width: '50px',
						height: '50px',
						borderRadius: '15px',
						cursor: 'pointer',
						color: '#000',
					}}
				>
					{user.lastName[0] + user.firstName[0]}
				</Nav.Link>
			</div>
		</div>
	)
}

const AccountRightBarPlaceholder = () => {
	const navigate = useNavigate()
	return (
		<div className='d-flex flex-row-reverse flex-md-row no_outline justify-content-end justify-content-md-start placeholder-wave'>
			<div className='d-flex flex-column me-3 mt-1' style={{ width: '5rem' }}>
				<p className='text-end align-items-end d-none d-md-block w-100'>
					<span className='placeholder col-12'></span>
				</p>
				<div
					style={{ color: COLORS.primary }}
					className='p-0 text-decoration-none align-self-end text-nowrap my-auto w-75'
				>
					<span className='placeholder col-12'></span>
				</div>
			</div>
			<div className='my-auto me-md-0 me-3'>
				<div
					onClick={() => navigate('/account')}
					className='d-flex lh-lg align-items-center justify-content-center placeholder'
					style={{
						fontSize: '18px',
						width: '50px',
						height: '50px',
						borderRadius: '15px',
						cursor: 'pointer',
						color: '#000',
					}}
				></div>
			</div>
		</div>
	)
}
