import { useEffect } from 'react'
import { getObjContact } from '../utils'
import { DescriptionExperience } from './DescriptionExperience'
import { HeaderPage } from './HeaderPage'
import { TitleSection } from './TitleSection'
import styles from './styles.module.css'

export const PageThree = ({ props, id, user }) => {
  const {
    city,
    title,
    contacts,
    education,
    skills,
    experience,
    languages,
    certificates,
  } = props

  const { firstName, lastName } = user

  const contactOjb = getObjContact(contacts)
  return (
    <div className={`container p-3`} id={id} style={{ maxWidth: '700px' }}>
      <HeaderPage
        summary={props.summary}
        firstName={firstName}
        contacts={contacts}
        lastName={lastName}
        profession={title}
        city={city}
      />
      {experience.length ? <TitleSection title='Опыт работы' /> : null}
      {experience?.map((elem) => {
        return (
          <DescriptionExperience
            key={elem.id}
            company_name={elem.company}
            title_job={elem.position}
            experience_start_year={elem.startYear}
            experience_end_year={elem.endYear}
            experience_start_month={elem.startMonth}
            experience_end_month={elem.endMonth}
            description_experience={elem.description}
          />
        )
      })}
      {education.length ? <TitleSection title='Образование' /> : null}
      {education.length ? (
        <div className='row mb-3'>
          {education?.map((elem) => {
            return (
              <div
                className='d-flex justify-content-between align-items-start'
                key={elem.id}
              >
                <p className={`col-3 fw-bolder ${styles.education}`}>
                  {elem.name}
                </p>
                <p className={`col-6 ${styles.text}`}>{elem.description}</p>
                <p className={`col-1 ${styles.experience}`}>{elem.endYear}</p>
              </div>
            )
          })}
        </div>
      ) : null}
      {certificates.length ? <TitleSection title='Сертификаты' /> : null}
      {certificates.length ? (
        <div className='row mb-3'>
          {certificates?.map((elem) => {
            return (
              <div
                className='d-flex justify-content-between align-items-start'
                key={elem.id}
              >
                <p className={`col-3 fw-bolder ${styles.education}`}>
                  {elem.name}
                </p>
                <p className={`col-6 ${styles.text}`}>{elem.description}</p>
                <p className={`col-1 ${styles.experience}`}>{elem.year}</p>
              </div>
            )
          })}
        </div>
      ) : null}
      {languages.length ? <TitleSection title='Языки' /> : null}
      {languages.length ? (
        <div className={`row`}>
          <ul className={`col-12 mb-3 ${styles.list}`}>
            {console.log(languages)}
            {languages
              ?.map((elem) => (
                <li key={elem.id} className={` fw-bolder ${styles.item}`}>
                  {elem.name} – {elem.level}
                </li>
              ))
              .reduce(
                (p, c, i, a) =>
                  i < a.length - 1 ? p.concat(c, '|') : p.concat(c),
                []
              )}
          </ul>
        </div>
      ) : null}
      {skills.length ? <TitleSection title='Навыки' /> : null}
      {skills.length ? (
        <div className='row gy-2 mb-3'>
          <ul className={styles.list}>
            {skills
              ?.map((elem) => (
                <li
                  key={elem}
                  className={`fw-bolder ${styles.item} ${styles.technology}`}
                >
                  {elem}
                </li>
              ))
              .reduce(
                (p, c, i, a) =>
                  i < a.length - 1 ? p.concat(c, '|') : p.concat(c),
                []
              )}
          </ul>
        </div>
      ) : null}
    </div>
  )
}
