import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { Stack } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Route, Routes, useLocation, useNavigate } from 'react-router'
import { AcceptCookie } from './components/AcceptCookie'
import ErrorBoundary from './components/ErrorBoundary'
import { Footer } from './components/Footer'
import { Loader } from './components/Loader'
import { MainHeader } from './components/MainHeader'
import {
  AREA_LANDINGS,
  COLORS,
  ErrorMsg,
  MAX_WIDTH,
  PADDINGS,
  SuccessMsg,
} from './constants'
import { AccountScreen } from './screens/account/AccountScreen'
import { AuthScreen } from './screens/auth/AuthScreen'
import { AboutLanding } from './screens/landings/AboutLanding'
import { ConsultationsScreen } from './screens/landings/Consultations/Consultations'
import { Startups } from './screens/landings/Startups'
import { Contacts } from './screens/main/Contacts'
import { NotFoundPage } from './screens/system/NotFoundPage'
import { PrivacyPolicy } from './screens/system/PrivacyPolicy'
import { VacanciesScreen } from './screens/vacancies/Vacancies'
import { clearResume } from './store/slices/resumeSlice'
import { setUser } from './store/slices/userSlice'

import { MainLanding } from './screens/landings/MainLanding'
import { AreaLanding } from './screens/landings/areas/AreaLanding'
import { PublicProfile } from './screens/main/PublicProfile'
import { SpamPage } from './screens/system/SpamPage'

const EXPERT_DATA = [
  {
    promo: 'promo1',
    price: '1 рубль',
    route: 'expert',
  },
  {
    promo: 'promo299',
    price: '299 рублей',
    route: 'cv',
  },
  {
    promo: 'promo799',
    price: '799 рублей',
    route: 'help',
  },
]

function App() {
  const loc = useLocation()
  const bgColor =
    EXPERT_DATA.map(el => '/' + el.route).filter(el => loc.pathname === el)
      .length > 0
      ? 'white'
      : loc.pathname === '/please' || loc.pathname === '/tamara'
        ? '#181B1F'
        : [...AREA_LANDINGS.map(el => '/' + el.route), '/'].includes(
              loc.pathname
            )
          ? '#FAF5ED'
          : COLORS.mainBg
  const [state, setState] = useState({
    headerScrollClasses: '',
  })

  useEffect(() => {
    let lastScroll = 0
    let headerScrollUp = true
    let headerScrollDown = false
    const listener = () => {
      if (window.scrollY <= 0) headerScrollUp = false
      else {
        if (window.scrollY > lastScroll && !headerScrollDown) {
          headerScrollUp = false
          headerScrollDown = true
        }
        if (window.scrollY < lastScroll && headerScrollDown) {
          headerScrollUp = true
          headerScrollDown = false
        }
      }
      const headerScrollClasses = `${headerScrollUp ? 'header-scroll-up' : ''} ${headerScrollDown ? 'header-scroll-down' : ''}`
      lastScroll = window.scrollY
      setState(p => ({ ...p, headerScrollClasses }))
    }
    window.addEventListener('scroll', listener)
    return () => window.removeEventListener('scroll', listener)
  }, [loc])

  return (
    <Stack style={{ minHeight: '100vh', backgroundColor: bgColor }}>
      <ErrorBoundary>
        <ErrorMsg />
        <SuccessMsg />
        <div
          className={
            'w-100 position-fixed top-0 start-0 ' + state.headerScrollClasses
          }
          style={{
            backgroundColor: bgColor,
            flexShrink: '1',
            zIndex: 1000,
          }}
        >
          <div className={PADDINGS + ' mx-auto'}>
            <div
              className='w-100 mx-auto'
              style={{ maxWidth: MAX_WIDTH, flex: '1' }}
            >
              <MainHeader />
            </div>
          </div>
        </div>

        <div className={PADDINGS} style={{ flex: '1', marginTop: '5.25rem' }}>
          <div
            className='w-100 mx-auto'
            style={{ maxWidth: MAX_WIDTH, flex: '1' }}
          >
            <Routes>
              <Route path='/' element={<MainLanding />} />
              {/* <Route path='old' element={<OldMainLanding />}/> */}

              {EXPERT_DATA.map((el, i) => (
                <Route
                  key={i}
                  path={el.route + '/*'}
                  element={<ConsultationsScreen data={el} />}
                />
              ))}

              <Route path='startup' element={<Startups />} />

              {AREA_LANDINGS.map((el, i) => (
                <Route
                  key={i}
                  path={el.route}
                  element={
                    <AreaLanding
                      title={el.title}
                      img={el.img}
                      text={el.text || ''}
                    />
                  }
                />
              ))}

              <Route path='about' element={<AboutLanding />} />
              <Route path='auth/*' element={<AuthScreen />} />
              <Route path='account/*' element={<AccountScreen />} />
              <Route path='vacancies/*' element={<VacanciesScreen />} />
              <Route path='logout' element={<Logout />} />
              <Route path='policy' element={<PrivacyPolicy />} />
              <Route path='spam' element={<SpamPage />} />
              <Route path='contacts' element={<Contacts />} />
              <Route path='u/:nickname/*' element={<PublicProfile />} />
              <Route path='*' element={<NotFoundPage />} />
            </Routes>
          </div>
        </div>
      </ErrorBoundary>
      <AcceptCookie />
      <Footer />
    </Stack>
  )
}

const Logout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    Cookies.remove('access_token')
    setTimeout(() => {
      dispatch(
        setUser({
          loaded: false,
          access_token: undefined,
          id: '',
          createdAt: '',
          updatedAt: '',
          email: '',
          login: '',
          contact: '',
          firstName: '',
          middleName: '',
          lastName: '',
          phone: '',
          birthDate: '',
          gender: '',
          city: '',
          country: '',
          aboutMe: '',
          specialty: '',
          nickname: '',
          isReadyToRelocate: false,
          isVisibleToEmployers: false,
          isConfirmedByManager: false,
          isEmailConfirmed: false,
          resumeId: '',
        })
      )
      dispatch(clearResume())

      navigate('/auth')
    }, 200)
  })
  return <Loader />
}

export default App
