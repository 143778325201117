import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Loader } from '../../../../components/Loader'
import { DefaultAvatar } from '../../../../components/ui/DefaultAvatar'
import TextareaAutosize from '../../../../components/ui/TextareaAutosize'
import { COLORS, SOURCE, toTime } from '../../../../constants'
import { showErrorMsg } from '../../../../store/slices/alertSlice'
import { ReactComponent as MagicStick } from '../../../../icons/magic_stick.svg'
import { ReactComponent as SendSvg } from '../../../../icons/send.svg'
import { ReactComponent as StarsSvg } from '../../../../icons/stars.svg'
import { ReactComponent as TailLeftSvg } from '../../../../icons/tail_left.svg'
import { ReactComponent as TailRightSvg } from '../../../../icons/tail_right.svg'
import classes from './NeuroResume.module.css'
import {createAiMessage, getAiChat} from "../../../../api/applicantAiChats"

const FAQ = [
	'Какой у тебя опыт работы?',
	'Где ты учился?',
	'Какие у тебя навыки?',
]

export const NeuroResume = ({ chatId }) => {
	const user = useSelector(state => state.userSlice.user)
	const resume = useSelector(state => state.resumeSlice.resume)
	const candidate = user
	const divRef = useRef(null)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	if (!chatId) {
		dispatch(showErrorMsg('Ошибка получения нейро чата, попробуйте еще раз')) 
		navigate('/account')
	}

	const [state, setState] = useState({
		chatId: chatId,
		history: [],
		loaded: false,
		question: '',
		waitingForResponse: false,
		short: false,
		canCreateMessage: false
	})

	useEffect(() => {
		if (state.chatId) {
			(async () => {
				const result = await getAiChat(user.access_token, state.chatId, {
					include: ['history'],
				})
				if (!result) return dispatch(showErrorMsg('Ошибка при загрузке чата'))

				setState({ ...state, history: result.history, loaded: true, canCreateMessage: result.canCreateMessage  })
			})()
		}
	}, [state.chatId])

	useEffect(() => {
		if (divRef.current) {
			divRef.current.scrollTop = divRef.current.scrollHeight
		}
	}, [state.history])

	const sendQuestion = async question => {
		if (!question) return
		if (!state.canCreateMessage) {
			dispatch(showErrorMsg('Невозможно отправить сообщение так как у вас еще не обработано собеседование'))
			return
		}

		setState({
			...state,
			question: '',
			history: [
				...state.history,
				{ id: '', createdAt: new Date(), prompt: question, response: '...' },
			],
		})
		const result = await createAiMessage(
			user.access_token,
			state.chatId,
			question,
			dispatch
		)
		if (!result) return

		setState({ ...state, history: [...state.history, result], question: '' })
	}

	if (!chatId) return navigate(-1)

	return <div className='d-flex flex-column align-items-center'>
	<div
		className='d-flex flex-column bg-white rounded p-sm-5 p-4 w-sm-100 w-sm-max'
		style={{ maxWidth: '1000px', minHeight: '90vh' }}
	>
		<div
			className='d-flex flex-column px-md-5 mx-auto mb-md-5 w-100'
			style={{ maxWidth: '800px' }}
		>
			<DefaultAvatar
					style={{ objectFit: 'cover', width: 'min(110px,20vw)', height: 'min(110px,20vw)' }}
				className='mx-auto rounded img-fluid mb-4'
				src={SOURCE.url + `applicants/${candidate.id}/avatar`}
			/>
			<p className='fs-3 fw-bolded text-center'>
				{candidate.firstName + ' ' + candidate.lastName}
			</p>
			<p className='fs-4 text-center' style={{ color: COLORS.gray_text }}>
				{resume.title}
			</p>
			<hr
				className={state.history.length === 0 ? 'my-5' : 'mt-4 mb-5'}
				style={{ color: '#D9D9D9', opacity: '1', width: '100%' }}
			/>
			<div
				ref={divRef}
				className='w-100 d-flex flex-column'
				style={{
					maxHeight: '600px',
					overflowY: 'auto',
					paddingRight: '5px',
				}}
			>
				{!state.loaded ? (
					<Loader />
				) : state.history.length ? (
					state.history.map((aim, i) => <AiPair key={i} pair={aim} />)
				) : (
					<div className='w-100 d-flex flex-column pt-sm-5'>
						<div className='d-flex mx-auto mb-md-5 mb-4'>
							<MagicStick
								fill={COLORS.primary}
								style={{ width: '34px', height: '32px' }}
								className='me-3'
							/>
							<p className={classes.MainTitle + ' fs-2'}>Нейрорезюме</p>
						</div>
						<p className='fs-6 fw-light' style={{ color: COLORS.gray_text }}>
							Нейросеть проанализирует за вас резюме и запись собеседования.
							Таким образом, вы можете просто спросить у виртуального
							кандидата интересующий вас вопрос и получить краткий ответ без
							траты лишнего времени на созвон с кандидатом
						</p>
					</div>
				)}
			</div>
		</div>
		<div className='mt-auto px-md-4'>
			{state.loaded && state.history.length === 0 && (
				<div className='row g-2'>
					{FAQ.slice(0, window.innerWidth > 576 ? 3 : 2).map((v, i) => (
						<PopularQuestion
							key={i}
							value={v}
							onClick={() => sendQuestion(v)}
						/>
					))}
				</div>
			)}
			<form
				onSubmit={e => e.preventDefault()}
				className='d-flex align-items-center mt-2'
			>
				<TextareaAutosize
					value={state.question}
					onChange={e => setState({ ...state, question: e.target.value })}
					maxHeight='150px'
					className='me-sm-4 me-2 rounded p-2'
					style={{ flex: '1', border: '1px solid ' + COLORS.hr, resize: 'none'  }}
					minHeight='70px'
					defaultHeight='70px'
				/>
				<button
					disabled={!state.chatId || state.waitingForResponse}
					className='btn rounded-circle d-flex'
					onClick={() => sendQuestion(state.question || '')}
					style={{
						width: '53px',
						height: '53px',
						flexShrink: '0',
						backgroundColor: COLORS.primary,
					}}
				>
					<SendSvg className='m-auto' />
				</button>
			</form>
		</div>
	</div>
</div>
}

const PopularQuestion = ({ value, onClick }) => {
	return (
		<div className='col-auto'>
			<button
				onClick={onClick}
				className='btn primary_outline rounded p-2'
				style={{
					border: '1px solid ' + COLORS.primary,
					color: COLORS.primary,
					cursor: 'pointer',
				}}
			>
				{value}
			</button>
		</div>
	)
}

const AiPair = ({ pair }) => {
	return (
		<div className='d-flex flex-column'>
			<div className='d-flex mb-1 '>
				<div
					className='ms-auto d-flex flex-column ms-auto'
					style={{ maxWidth: '70%', position: 'relative', overflowX: 'hidden' }}
				>
					<p
						className='px-sm-4 px-3 py-sm-3 py-2 rounded-4'
						style={{ backgroundColor: COLORS.primary, color: 'white' }}
					>
						{pair.prompt}
					</p>
					<p
						className='ms-auto mt-1 fs-6 fw-light'
						style={{ color: COLORS.gray_text }}
					>
						{toTime(pair.createdAt)}
					</p>
					<div
						className=''
						style={{
							position: 'absolute',
							bottom: '23px',
							right: 0,
						}}
					>
						<TailRightSvg fill={COLORS.primary} />
					</div>
				</div>
			</div>
			<div className='d-flex mb-1'>
				<div
					className='rounded-circle d-sm-flex d-none mt-auto mb-1 me-2'
					style={{
						width: '34px',
						height: '34px',
						flexShrink: '0',
						backgroundColor: COLORS.primary,
					}}
				>
					<StarsSvg className='m-auto' />
				</div>
				<div className='d-flex'>
					<div
						className='d-flex flex-column'
						style={{
							maxWidth: 'min(80vw, 500px)',
							position: 'relative',
							overflowX: 'hidden',
						}}
					>
						<p
							className='px-sm-4 px-3 py-sm-3 py-2 rounded-4'
							style={{ backgroundColor: '#F3F3F3' }}
						>
							{pair.response === '...' ? (
								<div className={classes.DotsLoader}></div>
							) : (
								pair.response
							)}
						</p>
						<p
							className='mt-1 fs-6 fw-light'
							style={{ color: COLORS.gray_text }}
						>
							{toTime(pair.createdAt)}
						</p>
						<div
							className=''
							style={{
								position: 'absolute',
								bottom: '23px',
								left: 0,
							}}
						>
							<TailLeftSvg />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
