import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router'
import { updateUserResume } from '../../api/applicant'
import { getCities } from '../../api/vacancies'
import { Loader } from '../../components/Loader'
import { COLORS, useDebounceCallback } from '../../constants'
import { ReactComponent as FilterSvg } from '../../icons/filter.svg'
import { ReactComponent as SearchSvg } from '../../icons/search.svg'
import {
    getAllVacanciesAsync,
    resetFilters,
    setCities,
    setFilters,
    updateRange,
} from '../../store/slices/vacanciesSlice'
import { NotFoundPage } from '../system/NotFoundPage'
import { getMyVacancyResponses } from './../../api/vacancyResponses'
import { Vacancy } from './Vacancy'
import { FiltersBlock } from './components/FiltersBlock'
import { VacancyCard, VacancyCardPlaceholder } from './components/VacancyCard'

export const VacanciesScreen = () => {
	const dispatch = useDispatch()
	const user = useSelector(state => state.userSlice.user)
	useEffect(() => {
		(async () => {
			await updateUserResume(user.access_token, dispatch)
		})()
	}, [])

	return (
		<>
			<div
				className='position-fixed top-0 start-0 w-100 h-100 min-vw-100 min-vh-100'
				style={{ backgroundColor: COLORS.mainBg, zIndex: -1 }}
			></div>
			<Routes>
				<Route path='/' element={<Vacancies />} />
				<Route path='*' element={<NotFoundPage />} />
				<Route path='/:id' element={<Vacancy />} />
			</Routes>
		</>
	)
}

const Vacancies = () => {
	const dispatch = useDispatch()
	const vacanciesState = useSelector(state => state.vacanciesSlice)
	const user = useSelector(state => state.userSlice.user)

	const filters = useSelector(
		state => state.vacanciesSlice.vacanciesInfo.filters
	)
	const resume = useSelector(state => state.resumeSlice.resume)
	const [state, setState] = useState({
		filtersModalShown: false,
		guestResponseSent: [],
	})

	const applyFilters = useDebounceCallback(
		filters =>
			dispatch(getAllVacanciesAsync({ include: ['employer'], filters })),
		500
	)

	useEffect(() => {
		applyFilters(filters)
	}, [filters, vacanciesState.vacanciesInfo.currentPage])

	const [responses, setResponses] = useState([])

	useEffect(() => {
		(async () => {
			const cities = await getCities()
			dispatch(setCities(cities))
		})()
		;(async () => {
			const a = await getMyVacancyResponses(user.access_token)
			if (!a) return
			setResponses(a.items.map(el => el.vacancyId))
		})()
	}, [])

	const addResponse = id => {
		setResponses(f => [...f, id])
	}
	useEffect(() => {
		const guestResponseSent = JSON.parse(
			localStorage.getItem('guestResponseSent') || '[]'
		)
		setState(p => ({ ...p, guestResponseSent }))
	}, [localStorage])

	const changeFilter = payload => {
		dispatch(setFilters(payload))
	}
	const fetchMore = async () => {
		dispatch(
			updateRange({ currentPage: vacanciesState.vacanciesInfo.currentPage + 1 })
		)
	}

	return (
		<div className='w-100 pb-5'>
			<FiltersModal
				filters={filters}
				changeFilter={changeFilter}
				dispatch={dispatch}
				shown={state.filtersModalShown}
				set={setState}
			/>
			<div className='d-flex flex-row justify-content-between'>
				<p className='fs-3 mb-3 fw-bolded'>Вакансии</p>
				<button
					className='no_outline fs-5 mb-3 fw-bolded d-sm-none'
					onClick={() => setState(p => ({ ...p, filtersModalShown: true }))}
				>
					Фильтр&nbsp;
					<FilterSvg className='ms-2' />
				</button>
			</div>
			<div className='row gy-3'>
				<div className='col-lg-5 col-xl-4 d-none d-sm-block'>
					<FiltersBlock
						filters={filters}
						changeFilter={changeFilter}
						dispatch={dispatch}
					/>
				</div>
				<div className='col d-flex flex-column'>
					<SearchInput
						placeholder='Введите название вакансии'
						className='p-3 mb-sm-2'
						style={{ border: 'none' }}
						value={filters.name}
						onChange={e => changeFilter({ name: e.target.value })}
					/>
					{!(vacanciesState.loaded && resume?.loaded) ? (
						<>
							<VacancyCardPlaceholder />
							<VacancyCardPlaceholder />
							<VacancyCardPlaceholder />
							<VacancyCardPlaceholder />
							<VacancyCardPlaceholder />
						</>
					) : (
						vacanciesState.vacanciesInfo.vacancies.map((v, i) => (
							<VacancyCard
								key={i}
								vacancy={v}
								mySkills={resume?.skills}
								addResponse={addResponse}
								guestResponseSent={state.guestResponseSent.includes(v.id)}
								responses={responses}
							/>
						)) || <p className='text-center fs-5'>Вакансий не найдено</p>
					)}

					<p className='fs-6 mt-4' style={{ color: COLORS.header_text }}>
						Показано {vacanciesState.vacanciesInfo.vacancies.length} из{' '}
						{Math.min([vacanciesState.vacanciesInfo.total])} вакансий
					</p>
					{vacanciesState.vacanciesInfo.vacancies.length > 0 &&
						vacanciesState.vacanciesInfo.vacancies.length <
							vacanciesState.vacanciesInfo.total && (
							<button
								onClick={fetchMore}
								className='btn py-2 px-4 bg-white mx-auto mt-3'
							>
								{vacanciesState.loading ? (
									<Loader animation={false} size='small' color='black' />
								) : (
									'Показать ещё'
								)}
							</button>
						)}
				</div>
			</div>
		</div>
	)
}

const FiltersModal = ({ filters, changeFilter, dispatch, shown, set }) => {
	return (
		<Modal
			dialogClassName='rounded-4'
			size='xl'
			centered
			show={shown}
			onHide={() => set(p => ({ ...p, filtersModalShown: false }))}
		>
			<Modal.Header closeButton>
				<Modal.Title style={{ fontWeight: '400' }}>Фильтр</Modal.Title>
				<p
					className='text-decoration-underline ms-3 fs-6'
					style={{ cursor: 'pointer' }}
					onClick={() => dispatch(resetFilters())}
				>
					Сбросить фильтры
				</p>
			</Modal.Header>
			<Modal.Body>
				<FiltersBlock
					filters={filters}
					changeFilter={changeFilter}
					dispatch={dispatch}
					header={false}
				/>
			</Modal.Body>
		</Modal>
	)
}

const SearchInput = ({
	value,
	onChange,
	placeholder,
	className = 'p-2',
	...props
}) => {
	return (
		<div className='position-relative'>
			<input
				placeholder={placeholder}
				className={'form-control rounded ' + className}
				value={value}
				onChange={e => onChange(e)}
				{...props}
			/>
			<SearchSvg
				className='position-absolute my-auto'
				style={{
					right: '8px',
					top: className.includes('p-2') ? '10px' : '18px',
				}}
			/>
		</div>
	)
}
