import { useNavigate } from 'react-router'
import {
  COLORS,
  SOURCE,
  colorsByResponseStatus,
  labelsByResponseStatus,
  labelsBySalaryCurrency,
} from '../../../../constants'
import { ReactComponent as DeleteOutlineSvg } from '../../../../icons/delete-outline.svg'
import { DefaultAvatar } from '../../../../components/ui/DefaultAvatar'

export const ResponseDesktop = ({ response, handleDelete }) => {
  const navigate = useNavigate()
  const employer = response.vacancy.employer
  return (
    <div className='d-none d-lg-flex row gy-3'>
      <div className='col-auto'>
        <DefaultAvatar
          type='b2c'
          src={SOURCE.url + `employers/${employer.id}/avatar`}
          className='rounded img-fluid'
          style={{
            objectFit: 'cover',
            width: 'min(70px,20vw)',
            height: 'min(70px,20vw)',
          }}
        />
      </div>
      <div className='col-lg-2 col-auto d-flex'>
        <p className='fs-5 fw-bolded my-auto'>{employer.name}</p>
      </div>
      <div className='col-lg-3 col-auto d-flex'>
        <p className='fs-5 fw-bolded my-auto'>{response.vacancy.name}</p>
      </div>
      <div className='col-md-2 d-flex'>
        <p
          className='fs-6 fw-bolded my-auto'
          style={{ color: COLORS.header_text }}
        >
          {response.vacancy.salary
            ? response.vacancy.salary.toLocaleString() +
              ' ' +
              labelsBySalaryCurrency[response.vacancy.salaryCurrency]
            : 'З/п на собеседовании'}
        </p>
      </div>
      <div className='col d-flex'>
        <p
          className='fs-6 my-auto rounded px-3 py-2 text-nowrap'
          style={{
            color: 'white',
            backgroundColor: colorsByResponseStatus[response.status],
          }}
        >
          {labelsByResponseStatus[response.status]}
        </p>
      </div>
      <div className='col-auto d-flex'>
        <p
          onClick={() => navigate('/vacancies/' + response.vacancy.id)}
          className='fs-6 my-auto text-decoration-underline'
          style={{ cursor: 'pointer' }}
        >
          Перейти на вакансию
        </p>
      </div>
      <div className='col-auto d-flex'>
        <button className='no_outline my-auto' onClick={handleDelete}>
          <DeleteOutlineSvg
            style={{ width: '24px', height: '24px', fill: COLORS.danger }}
          />
        </button>
      </div>
    </div>
  )
}

export const ResponseDesktopPlaceholder = () => {
  return (
    <div className='d-none d-lg-flex row gy-3 placeholder-glow'>
      <div className='col-auto'>
        <div
          className='rounded img-fluid placeholder'
          style={{
            objectFit: 'cover',
            width: '70px',
            height: '70px',
            boxShadow: '0 0 6px 0 #00000010',
          }}
        />
      </div>
      <div className='col-lg-2 col-auto d-flex'>
        <p className='fs-4 fw-bolded my-auto col'>
          <span class='placeholder col-10'></span>
        </p>
      </div>
      <div className='col-lg-3 col-auto d-flex'>
        <p className='fs-4 fw-bolded my-auto col'>
          <span class='placeholder col-10'></span>
        </p>
      </div>
      <div className='col-md-2 d-flex'>
        <p
          className='fs-5 fw-bolded my-auto col'
          style={{ color: COLORS.header_text }}
        >
          <span class='placeholder col-12'></span>
        </p>
      </div>
      <div className='col d-flex'>
        <p className='fs-4 my-auto rounded px-3 py-2 text-nowrap placeholder col-10'>
          <span class='placeholder col-3'></span>
        </p>
      </div>

      <div className='col d-flex'>
        <p
          className='fs-6 my-auto text-decoration-underline col'
          style={{ cursor: 'pointer' }}
        >
          <span class='placeholder col-12'></span>
        </p>
      </div>
      <div className='col-auto d-flex' style={{ alignItems: 'center' }}>
        <span
          class='placeholder col-12'
          style={{ width: '2rem', height: '2rem' }}
        ></span>
      </div>
    </div>
  )
}
