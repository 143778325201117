export const AppSelect = ({className = '', label = '', selectClassName = ' p-sm-3 py-2 rounded-3 ',  value, onChange, options, disabled = false, backgroundColor = "#fff"}) => {
    if (typeof options[0] === 'string') {
        options = options.map(el => ({value: el, label: el}))
    }
    return <div className={'mb-md-3 mb-2 ' + className}>
      
        <select className={`form-select ${selectClassName}`} style={{ minWidth: '120px', backgroundColor}}
                disabled={disabled} defaultValue={value}
                onChange={e => onChange(e)}>
            {options.map((el, i) => <option key={i} value={el.value}>{el.label}</option>)}
        </select>
    </div>
}