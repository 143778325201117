import { useState } from 'react'
import ReactInputMask from 'react-input-mask'
import { COLORS, SOURCE } from '../../constants'
import { sendContact } from '../main/Contacts'

export const AboutLanding = () => {
	const [state, setState] = useState({
		phone: '',
	})

	return (
		<div className='pb-5 pt-4'>
			<p
				className='fs-1 my-2'
				style={{ fontWeight: '700', color: COLORS.primary }}
			>
				Хартл – это IT-компания
			</p>
			<p className='fs-5 mb-5' style={{ fontWeight: '200' }}>
				Разрабатываем и поддерживаем программное обеспечение, ведем крупные
				HR-проекты и соединяем IT-специалистов с компаниями.
			</p>
			<div className='row flex-wrap-reverse mb-4'>
				<div className='col d-flex flex-column text-wrap mb-4'>
					<p
						className='mb-2 fs-2'
						style={{ fontWeight: '400', color: COLORS.red_primary }}
					>
						Итак, Хартл
					</p>
					<p className='fs-4' style={{ color: COLORS.text }}>
						Добро пожаловать сайт Хартл – компании, специализирующейся на
						предоставлении высококачественных IT-услуг. Мы гордимся своими
						инновационными решениями и стремимся к постоянному совершенствованию
						в сфере информационных технологий.
					</p>
				</div>
				<div className='col-md-4 mb-4'>
					<img
						className='m-auto ps-md-3'
						style={{ height: 'auto', width: '100%', maxWidth: '100%' }}
						src={SOURCE.source_url + 'men1.png'}
						alt=''
					/>
				</div>
			</div>

			<div className='row mb-4'>
				<div className='col-md-4 mb-4'>
					<img
						className='m-auto pe-md-3'
						style={{ height: 'auto', width: '100%', maxWidth: '100%' }}
						src={SOURCE.source_url + 'men2.png'}
						alt=''
					/>
				</div>
				<div className='col d-flex flex-column text-wrap pe-sm-5'>
					<p
						className='mb-2 fs-2'
						style={{ fontWeight: '400', color: COLORS.red_primary }}
					>
						Наши цели
					</p>
					<p className='fs-4' style={{ color: COLORS.text }}>
						Наша компания стремится к построению долгосрочных партнерских
						отношений с нашими клиентами. Мы понимаем, что каждый проект
						уникален, и поэтому мы тщательно анализируем потребности и цели
						наших клиентов, чтобы предложить наиболее эффективные и
						индивидуальные решения.
					</p>
				</div>
			</div>
			<div className='row flex-wrap-reverse mb-4'>
				<div className='col d-flex flex-column text-wrap pe-sm-5'>
					<p
						className='mb-2 fs-2'
						style={{ fontWeight: '400', color: COLORS.red_primary }}
					>
						Чем мы занимаемся
					</p>
					<p className='fs-4' style={{ color: COLORS.text }}>
						Хартл – это команда профессионалов, которые обладают глубокими
						знаниями и опытом в различных областях IT-деятельности. Мы
						предлагаем широкий спектр услуг, включая разработку программного
						обеспечения, веб-разработку, а также консалтинг и поддержку
						IT-инфраструктуры собственных цифровых продуктов.
					</p>
				</div>
				<div className='col-md-4 mb-4'>
					<img
						className='m-auto ps-md-3'
						style={{ height: 'auto', width: '100%', maxWidth: '100%' }}
						src={SOURCE.source_url + 'men3.png'}
						alt=''
					/>
				</div>
			</div>
			<div className='row mb-4'>
				<div className='col-md-4 mb-4'>
					<img
						className='m-auto pe-md-3'
						style={{ height: 'auto', width: '100%', maxWidth: '100%' }}
						src={SOURCE.source_url + 'men4.png'}
						alt=''
					/>
				</div>
				<div className='col d-flex flex-column text-wrap'>
					<p
						className='mb-2 fs-2'
						style={{ fontWeight: '400', color: COLORS.red_primary }}
					>
						Мы гибкие
					</p>
					<p className='fs-4' style={{ color: COLORS.text }}>
						Мы готовы взять на себя любые проекты, независимо от их сложности и
						масштаба. Наша команда готова предложить вам высококачественные
						услуги, оперативную поддержку и индивидуальный подход к каждому
						проекту.
					</p>
				</div>
			</div>
			<hr className='my-5 mx-auto w-100' />
			<div className='row gap-5 flex-wrap-reverse justify-content-between mb-4'>
				<div className='col d-flex flex-column' style={{ maxWidth: '700px' }}>
					<p
						className='fs-1 mb-3'
						style={{ color: COLORS.text_primary, fontWeight: '400' }}
					>
						Начать сотрудничество
					</p>
					<p className='fs-4 mb-4' style={{ color: COLORS.text_primary }}>
						Если вы ищете надежного партнера в сфере IT-деятельности, Хартл –
						ваш выбор. Свяжитесь с нами сегодня, чтобы обсудить ваши потребности
						и начать сотрудничество с нами. Мы готовы помочь вам достичь ваших
						целей и преуспеть в сфере информационных технологий.
					</p>
					<div className='mb-4'>
						<label className='ms-3 position-absolute'>
							<span className='h6 small bg-white text-muted px-1'>Телефон</span>
						</label>
						<ReactInputMask
							mask='+7 (999) 999-99-99'
							className='form-control py-sm-3 py-2 px-3'
							style={{ marginTop: '12px' }}
							value={state.phone}
							onChange={e =>
								setState(prev => ({ ...prev, contact: e.target.value }))
							}
						/>
					</div>
					<div className='d-flex flex-row flex-wrap-reverse align-items-center text-nowrap'>
						<button
							onClick={() => sendContact(state.phone, setState)}
							className='btn rounded-2 py-2 px-3 mb-3 primary'
							style={{
								backgroundColor: COLORS.primary,
								color: '#fff',
								fontWeight: '300',
								minWidth: '180px',
							}}
						>
							Отправить
						</button>
					</div>
				</div>
				<div className='col-md-6 col-lg-4 col-12 d-flex align-items-center'>
					<img
						style={{ height: 'auto', width: '100%', maxWidth: '500px' }}
						className='m-auto'
						src={SOURCE.source_url + 'men5.png'}
						alt=''
					/>
				</div>
			</div>
		</div>
	)
}
