import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { showErrorMsg } from '../../../store/slices/alertSlice'
import {
	ResponseDesktop,
	ResponseDesktopPlaceholder,
} from './components/ResponseDesktop'
import {
	ResponseMobile,
	ResponseMobilePlaceholder,
} from './components/ResponseMobile'
import {deleteVacancyResponse, getMyVacancyResponses} from "../../../api/vacancyResponses"
export const Responses = () => {
	const user = useSelector(state => state.userSlice.user)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [state, setState] = useState({
		responses: [],
		loaded: false,
	})

	useEffect(() => {
		(async () => {
			const data = await getMyVacancyResponses(
				user.access_token,
				'?include=' + ['vacancy.employer'].join('&include=')
			)
			if (!data) return dispatch(showErrorMsg('Ошибка при загрузке откликов'))
			setState({
				responses: data.items,
				loaded: true,
			})
		})()
	}, [])

	return !state.loaded ? (
		<ResponsePlaceholder />
	) : (
		<>
			<div className='d-flex flex-column gap-3'>
				{state.responses.length ? (
					state.responses.map((r, i) => (
						<Response key={i} response={r} setState={setState} />
					))
				) : (
					<div className='mt-sm-4  d-flex flex-column'>
						<p className='w-100 bg-white text-center rounded-4 py-4 px-4 fs-4'>
							Пока откликов нет, но все впереди
						</p>
						<button
							onClick={() => navigate('/vacancies')}
							className='btn primary py-2 px-sm-5 px-3 mx-auto mt-4'
						>
							Начать искать работу
						</button>
					</div>
				)}
			</div>
		</>
	)
}

const ResponsePlaceholder = () => {
	return (
		<div className='d-flex flex-column gap-3'>
			<div className='w-sm-max bg-white p-4 rounded'>
				<ResponseDesktopPlaceholder />
				<ResponseMobilePlaceholder />
			</div>
		</div>
	)
}

const Response = ({ response = {}, setState }) => {
	const dispatch = useDispatch()
	const access_token = useSelector(state => state.userSlice.user.access_token)

	const handleDelete = async () => {
		if (!window.confirm('Отозвать отклик?')) return
		const isOk = await deleteVacancyResponse(access_token, response.id)
		if (isOk)
			setState(p => ({
				...p,
				responses: p.responses.filter(r => r.id !== response.id),
			}))
		else dispatch(showErrorMsg('Ошибка удаления отклика'))
	}

	return (
		<div className='w-sm-max bg-white p-4 rounded'>
			<ResponseDesktop response={response} handleDelete={handleDelete} />
			<ResponseMobile response={response} handleDelete={handleDelete} />
		</div>
	)
}
