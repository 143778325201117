// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-btn {
    border-radius: 4px;
    padding: .5rem 1rem;
    color: black;
    background-color: var(--neutral);
}

.section-btn:disabled {
    background-color: var(--light-neutral);
    color: gray
}`, "",{"version":3,"sources":["webpack://./src/components/ConstructorSectionsBlock/styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,gCAAgC;AACpC;;AAEA;IACI,sCAAsC;IACtC;AACJ","sourcesContent":[".section-btn {\n    border-radius: 4px;\n    padding: .5rem 1rem;\n    color: black;\n    background-color: var(--neutral);\n}\n\n.section-btn:disabled {\n    background-color: var(--light-neutral);\n    color: gray\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
