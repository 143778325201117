import {useEffect, useState} from "react"
import {
    COLORS,
    CONTACT_LABELS,
    CONTACT_TYPE,
    PLACEHOLDER_CONTACT,
    typeAttributeByContactType,
    uuidv4
} from "../../constants"
import InputMask from "react-input-mask"

export default function GuestResponseContacts({ contacts, setState }) {
    const [availableTypes, setAvailableTypes] = useState([])

    const createContact = (type) => {
        const data = {
            id: uuidv4(),
            type,
            name: null,
            value: "",
            preferred: true
        }
        setState(p => ({...p, contacts: [...contacts, data]}))
    }

    const makeInputHandler = (id) => (e) => {
        const contact = {...contacts.find(e => e.id === id), value: e.target.value}
        setState(p => ({...p, contacts: contacts.map(e => e.id === id ? contact : e)}))
    }

    const makeBlurHandler = (id) => (e) => {
        if (!e.target.value) setState(p => ({...p, contacts: contacts.filter(e => e.id !== id)}))
    }

    useEffect(() => {
        setAvailableTypes(Object.keys(CONTACT_TYPE).filter(t => contacts.findIndex(c => c.type === t) === -1))
    }, [contacts])

    return <div className="w-100 d-flex flex-column gap-2">
        {contacts.map(contact => <div className="d-flex flex-row w-100">
            <p className="fs-5 fw-semibold">{CONTACT_LABELS[contact.type]}:&nbsp;</p>
            {contact.type === CONTACT_TYPE.PHONE ?
                <InputMask
                    type="tel"
                    className="flex-grow-1 no_outline fs-6"
                    mask="+7 (999) 999-99-99"
                    placeholder={PLACEHOLDER_CONTACT[contact.type]}
                    value={contact.value}
                    onChange={makeInputHandler(contact.id)}
                    onBlur={makeBlurHandler(contact.id)}
                /> :
                <input
                    type={typeAttributeByContactType(contact.type)}
                    className="flex-grow-1 no_outline fs-6"
                    placeholder={PLACEHOLDER_CONTACT[contact.type]}
                    value={contact.value}
                    onChange={makeInputHandler(contact.id)}
                    onBlur={makeBlurHandler(contact.id)}
                />}
        </div>) }
        <div className="w-100 d-flex flex-row flex-wrap gap-3 mt-1">
            { (availableTypes || []).map((t, i) => <span
                key={i}
                className="fs-6 fw-medium px-3 py-2 rounded-2"
                style={{backgroundColor: COLORS.light_neutral, cursor: "pointer"}}
                onClick={() => createContact(t)}>
                            {CONTACT_LABELS[t]}
            </span>) }
        </div>
    </div>
}