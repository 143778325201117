import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  COLORS,
  SOURCE,
  labelsByEmploymentType,
  labelsByExperience,
  labelsBySalaryCurrency,
  labelsByWorkplaceModel,
} from '../constants'
import { SeparateCodeInput, getCode } from '../screens/auth/layouts/ConfirmMail'

import { useDropzone } from 'react-dropzone'
import { patchMyAccount } from '../api/applicant'
import { verifyEmail } from '../api/emailVerifications'
import {
  createGuestVacancyResponse,
  createGuestVacancyResponseResumeContact,
} from '../api/guestVacancyResponses'
import { moveMeeting, updateUserMeetings } from '../api/meetings'
import { changeOfferStatus } from '../api/offers'
import { ReactComponent as BagSvg } from '../icons/bag.svg'
import { ReactComponent as CalendarSvg } from '../icons/calendar.svg'
import { ReactComponent as Close } from '../icons/close.svg'
import { ReactComponent as DownloadSvg } from '../icons/download.svg'
import { ReactComponent as InfoSvg } from '../icons/info.svg'
import { ReactComponent as OfficeSvg } from '../icons/office.svg'
import { ReactComponent as SberJazzSvg } from '../icons/sberjazz.svg'
import { SelectDate } from '../screens/auth/layouts/SetInterview'
import { showErrorMsg, showSuccessMsg } from '../store/slices/alertSlice'
import { setUser } from '../store/slices/userSlice'
import GuestResponseContacts from './GuestResponseContacts/GuestResponseContacts'
import { Loader } from './Loader'
import VideoPlayer from './ui/VideoPlayer'

const url = SOURCE.url

export const LogoutModal = ({ shown, setState }) => {
  const navigate = useNavigate()
  return (
    <Modal
      dialogClassName='rounded-4'
      centered
      show={shown}
      onHide={() => setState(p => ({ ...p, logoutModalShown: false }))}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: '400' }}>Подтверждение</Modal.Title>
      </Modal.Header>
      <Modal.Body>Вы точно хотите выйти?</Modal.Body>
      <Modal.Footer>
        <button
          className='btn btn-primary primary'
          style={{ backgroundColor: COLORS.primary }}
          onClick={() => setState(p => ({ ...p, logoutModalShown: false }))}
        >
          Нет, остаться!
        </button>
        <button
          className='btn text-nowrap light_primary px-3'
          style={{
            backgroundColor: COLORS.footer,
            color: COLORS.primary,
            maxWidth: '100px',
          }}
          onClick={() => {
            setState(p => ({ ...p, logoutModalShown: false }))
            navigate('/logout')
          }}
        >
          Выйти
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export const BookingModal = ({
  handleCloseModal,
  showModal,
  timer,
  formatTime,
  handlePay,
}) => {
  return (
    <Modal
      dialogClassName='m-auto w-50'
      contentClassName='border-0 rounded-4 rounded-md-5 px-sm-3 px-2 py-4'
      centered
      size='xl'
      show={showModal}
      onHide={handleCloseModal}
    >
      <Modal.Header closeButton style={{ border: 'none' }}></Modal.Header>
      <Modal.Body className='d-flex w-100 flex-column m-50 clearfix'>
        <Modal.Title className='text-center fs-3' style={{ fontWeight: '400' }}>
          Вы забронировали слот на консультацию
        </Modal.Title>
        <p
          className='text-center fs-6'
          style={{ fontWeight: '200', color: 'gray' }}
        >
          Слот забронирован на 15 минут
        </p>
        <div className='text-center mt-5 fs-5'>Таймер</div>
        <div className='text-center'>{formatTime(timer)}</div>
        {timer > 0 ? (
          <Button
            onClick={handlePay}
            style={{
              marginTop: '30px',
              backgroundColor: COLORS.primary,
              color: COLORS.secondary,
              borderWidth: 0,
              maxWidth: '250px',
            }}
            className='btn btn-md py-2 mb-3 w-100 text-nowrap mx-auto'
          >
            Оплатить
          </Button>
        ) : (
          <div className='text-center text-danger mt-3'>
            Время оплаты истекло
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}
export const ConfirmMailModal = ({ state, setState }) => {
  const [localState, setLocalState] = useState({
    successMsg: '',
    errorMsg: '',
    disabled: false,
    sent: false,
  })
  const dispatch = useDispatch()
  const user = useSelector(state => state.userSlice.user)
  useEffect(() => getCode(user.access_token, setLocalState, dispatch), [])

  function submitConfirmCode(code) {
    verifyEmail(user.access_token, code)
      .then(response => {
        if (response !== null && response.ok) {
          setState(p => ({ ...p, confirmMailModalShown: false }))
          dispatch(showSuccessMsg('Электронный адрес подтверждён'))
        } else {
          dispatch(showErrorMsg('Неверный код!'))
        }
      })
      .catch(error => {
        console.log(error)
        dispatch(showErrorMsg('Неверный код!'))
      })
  }

  return (
    <Modal
      dialogClassName='rounded-4'
      size
      centered
      show={state.confirmMailModalShown}
      onHide={() => setState(p => ({ ...p, confirmMailModalShown: false }))}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: '400' }}>
          Подтверждение почты
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='text-center mb-5 fs-5' style={{ fontWeight: '200' }}>
          Мы отправили тебе на почту код подтверждения, скорее вводи его здесь
        </p>
        <SeparateCodeInput callback={code => submitConfirmCode(code)} />
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => getCode(user.access_token, setLocalState, dispatch)}
          style={{
            backgroundColor: COLORS.secondary,
            color: COLORS.primary,
            borderWidth: 0,
            maxWidth: '250px',
          }}
          className='btn btn-md py-2 mb-3 w-100 text-nowrap mx-auto'
          disabled={localState.disabled}
          title={
            localState.disabled
              ? 'Следующий код можно запросить через 30 секунд'
              : ''
          }
        >
          Отправить код
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export const PublishPortfolioModal = ({ state, setState }) => {
  const [localState, setLocalState] = useState({
    published: false,
    nickname: '',
  })
  const dispatch = useDispatch()
  const user = useSelector(state => state.userSlice.user)
  return (
    <Modal
      centered
      contentClassName='border-0 rounded-4 rounded-md-5 px-sm-3 px-2 py-4'
      size='lg'
      show={state.publishModalShown}
      onHide={() => setState(p => ({ ...p, publishModalShown: false }))}
    >
      <Modal.Header closeButton className='border-0'>
        <p className='fs-4' style={{ fontWeight: '400' }}>
          {localState.published
            ? 'Резюме опубликовано!'
            : 'Опубликовать резюме'}
        </p>
      </Modal.Header>
      <Modal.Body>
        {localState.published ? (
          <p className='fs-6'>
            Вы можете открыть его по ссылке:{' '}
            <span
              className='underline'
              onClick={() =>
                window.open('https://hurtle.ru/u/' + localState.nickname)
              }
              style={{
                color: COLORS.primary,
                cursor: 'pointer',
                fontWeight: '400',
              }}
            >
              hurtle.ru/u/{localState.nickname}
            </span>
          </p>
        ) : (
          <>
            <div className='form-floating mb-4'>
              <input
                className='form-control'
                placeholder='supercoder'
                value={localState.nickname}
                onChange={e =>
                  setLocalState(prev => ({
                    ...prev,
                    nickname: e.target.value.trim(),
                  }))
                }
              />
              <label>Придумай себе никнейм (например, supercoder)</label>
            </div>
            <button
              className='btn btn-md text-nowrap w-100 float-end'
              style={{
                backgroundColor: COLORS.primary,
                color: '#fff',
                maxWidth: '200px',
              }}
              onClick={() => {
                patchMyAccount(
                  { nickname: localState.nickname },
                  user.access_token,
                  dispatch
                )
                  .then(data => {
                    if (data.error) {
                      setLocalState(p => ({ ...p, published: false }))
                      dispatch(showErrorMsg('Никнейм занят'))
                    } else {
                      setLocalState(p => ({ ...p, published: true }))
                      dispatch(
                        setUser({ ...user, nickname: localState.nickname })
                      )
                    }
                  })
                  .catch(e => {
                    console.error(e)
                    dispatch(showErrorMsg(e.errors))
                  })
              }}
            >
              Опубликовать
            </button>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

export const MeetingInfoModal = ({ meetingInfoModal, setState, past }) => {
  return meetingInfoModal.shown ? (
    <Modal
      centered
      contentClassName='border-0 rounded-4 px-2 m-3'
      size='lg'
      show={meetingInfoModal.shown}
      onHide={() =>
        setState(p => ({
          ...p,
          meetingInfoModal: { ...p.meetingInfoModal, shown: false },
        }))
      }
    >
      <Modal.Header closeButton style={{ borderBottom: '0 none' }}>
        <Modal.Title className='fs-4' style={{ fontWeight: '400' }}>
          {meetingInfoModal.meeting.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-row justify-content-between mb-2'>
          <p className='fs-5'>Дата и время</p>
          <div
            className='rounded-3 py-1 px-3'
            style={{
              color: COLORS.primary,
              border: '1px solid ' + COLORS.primary,
            }}
          >
            {meetingInfoModal.shown &&
              new Date(meetingInfoModal.meeting.slot.dateTime)
                .toLocaleString()
                .slice(0, -3)}
          </div>
        </div>
        <div className='d-flex flex-row justify-content-between'>
          <p className='fs-5'>Менеджер</p>
          <div
            className='rounded-3 py-1 px-3 fs-6'
            style={{
              color: COLORS.primary,
              border: '1px solid ' + COLORS.primary,
            }}
          >
            {meetingInfoModal.shown &&
              meetingInfoModal.meeting.slot &&
              meetingInfoModal.meeting.slot.manager &&
              meetingInfoModal.meeting.slot.manager.name}
          </div>
        </div>
        <hr className='my-4' />
        <p className='fs-4 mb-3' style={{ fontWeight: '400' }}>
          {past ? 'Отзыв о встрече' : 'Что будет на встрече'}
        </p>
        <p
          className='fs-6 text-wrap text-break'
          style={{ whiteSpace: 'pre-line' }}
        >
          {past
            ? (meetingInfoModal.meeting.feedback || [{}])[0].text
            : meetingInfoModal.meeting.description}
        </p>
      </Modal.Body>
      {!past && (
        <Modal.Footer style={{ borderTop: '0 none' }}>
          <button
            onClick={() => window.open(meetingInfoModal.meeting.roomUrl)}
            disabled={past}
            className='py-2 px-5 rounded-3 justify-content-center btn success'
            style={{ cursor: 'pointer' }}
          >
            <SberJazzSvg alt='' className='me-3' />
            Подключиться
          </button>
        </Modal.Footer>
      )}
    </Modal>
  ) : (
    ''
  )
}

export const OfferInfoModal = ({ offerInfoModal, setState }) => {
  const access_token = useSelector(state => state.userSlice.user.access_token)
  const dispatch = useDispatch()
  const hideModal = () =>
    setState(p => ({
      ...p,
      offerInfoModal: { ...p.offerInfoModal, shown: false },
    }))
  return offerInfoModal.shown ? (
    <Modal
      dialogClassName='rounded-4 m-auto p-4'
      centered
      size='xl'
      show={offerInfoModal.shown}
      onHide={() => setState(p => ({ ...p, offerInfoModal: { shown: false } }))}
    >
      <Modal.Body>
        <div className='px-2 px-lg-4 py-3'>
          <div className='d-flex flex-row'>
            <div className='d-flex flex-row flex-wrap mb-3 me-auto'>
              {/*<img src={offerInfoModal.offer.vacancy.employer.photo} alt='' className="me-4 rounded-3"*/}
              {/*     style={{width: '70px', height: '70px'}}/>*/}
              <div className='d-flex flex-column justify-content-center'>
                <p className='fs-4' style={{ fontWeight: '400' }}>
                  {offerInfoModal.offer.vacancy.name}
                </p>
                <p className='fs-5' style={{ color: COLORS.hr }}>
                  в {offerInfoModal.offer.vacancy.employer?.name} (
                  {offerInfoModal.offer.vacancy.city})
                </p>
              </div>
            </div>
            <Close
              cursor='pointer'
              onClick={() =>
                setState(p => ({ ...p, offerInfoModal: { shown: false } }))
              }
              fill={COLORS.text}
              height={'15px'}
              width={'15px'}
            />
          </div>
          <p className='fs-5'>
            {offerInfoModal.offer.salary === 0
              ? 'Зарплата — '
              : 'Лично тебе предложили зарплату — '}
            <span style={{ color: COLORS.primary }}>
              {offerInfoModal.offer.vacancy.salary ===
              offerInfoModal.offer.salary
                ? offerInfoModal.offer.vacancy.salary.toLocaleString() +
                  ' ' +
                  labelsBySalaryCurrency[
                    offerInfoModal.offer.vacancy.salaryCurrency
                  ]
                : offerInfoModal.offer.salary.toLocaleString() +
                  ' ' +
                  labelsBySalaryCurrency[offerInfoModal.offer.salaryCurrency]}
            </span>
          </p>
          <hr className='mt-3 mb-4' />
          <div className='row mb-3'>
            <div className='col-lg-4 d-flex flex-row align-items-center mb-3'>
              <div
                className='me-2 d-flex align-items-center'
                style={{ width: '50px' }}
              >
                <BagSvg className='mx-auto' height='40px' alt='' />
              </div>
              <div className='d-flex flex-column'>
                <p className='fs-6' style={{ color: COLORS.header_text }}>
                  Вид занятости
                </p>
                <p className='fs-5'>
                  {
                    labelsByEmploymentType[
                      offerInfoModal.offer.vacancy.employmentType
                    ]
                  }
                </p>
              </div>
            </div>
            <div className='col-lg-4 d-flex flex-row align-items-center mb-3'>
              <div
                className='me-2 d-flex align-items-center'
                style={{ width: '50px' }}
              >
                <CalendarSvg className='mx-auto' height='40px' alt='' />
              </div>
              <div className='d-flex flex-column'>
                <p className='fs-6' style={{ color: COLORS.header_text }}>
                  Опыт работы
                </p>
                <p className='fs-5'>
                  {labelsByExperience[offerInfoModal.offer.vacancy.experience]}
                </p>
              </div>
            </div>
            <div className='col-lg-4 d-flex flex-row align-items-center mb-3'>
              <div
                className='me-2 d-flex align-items-center'
                style={{ width: '50px' }}
              >
                <OfficeSvg className='mx-auto' height='40px' alt='' />
              </div>
              <div className='d-flex flex-column'>
                <p className='fs-6' style={{ color: COLORS.header_text }}>
                  Место работы
                </p>
                <p className='fs-5'>
                  {offerInfoModal.offer.vacancy.city +
                    ' + ' +
                    labelsByWorkplaceModel[
                      offerInfoModal.offer.vacancy.workplaceModel
                    ]}
                </p>
              </div>
            </div>
            {/* <div className='col-lg-4 d-flex flex-row align-items-center mb-3'>
                        <div className='me-2 d-flex align-items-center' style={{width: '50px'}}>
                            <ClockOutlineSvg className='mx-auto' height='40px' alt=''/>
                        </div>
                        <div className='d-flex flex-column'>
                            <p className='fs-6' style={{color: COLORS.header_text}}>Рабочее время</p>
                            <p className='fs-5'>{labelsByWorkingHours[offerInfoModal.offer.vacancy.workingHours]}</p>
                        </div>
                    </div> */}
          </div>
          <div className='px-2 d-flex flex-row mb-3'>
            <div className='me-3 d-flex align-items-center'>
              <InfoSvg className='mx-0' height='40px' alt='' />
            </div>
            <div className='d-flex flex-column'>
              <p className='fs-6' style={{ color: COLORS.header_text }}>
                Чем предстоит заниматься
              </p>
              <p className='fs-6'>
                {offerInfoModal.offer.vacancy.shortDescription}
              </p>
            </div>
          </div>
          {offerInfoModal.offer.message && (
            <div className='px-2 d-flex flex-row mb-3'>
              <div className='me-3 d-flex align-items-center'>
                <InfoSvg className='mx-0' height='40px' alt='' />
              </div>
              <div className='d-flex flex-column'>
                <p className='fs-6' style={{ color: COLORS.header_text }}>
                  Сообщение от рекрутера
                </p>
                <p className='fs-6'>{offerInfoModal.offer.message}</p>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className='btn btn-link btn-md p-1 text-decoration-none text-nowrap underline'
          style={{ color: '#E74840' }}
          onClick={() =>
            offerInfoModal.offer.status === 'PENDING' &&
            changeOfferStatus(
              access_token,
              offerInfoModal.offer.id,
              'DECLINED'
            ).then(data => {
              if (data === null) {
                hideModal()
                dispatch(showErrorMsg('Произошла ошибка'))
              } else dispatch(showSuccessMsg('Успешно!'))
            })
          }
        >
          Отклонить
        </button>
        <button
          className='btn btn-primary primary'
          style={{ backgroundColor: COLORS.primary }}
          onClick={() =>
            offerInfoModal.offer.status === 'PENDING' &&
            changeOfferStatus(
              access_token,
              offerInfoModal.offer.id,
              'ACCEPTED'
            ).then(data => {
              if (data === null) {
                hideModal()
                dispatch(showErrorMsg('Произошла ошибка'))
              } else dispatch(showSuccessMsg('Успешно!'))
            })
          }
        >
          Принять
        </button>
      </Modal.Footer>
    </Modal>
  ) : (
    ''
  )
}

export const MoveMeetingModal = ({ meeting, shown, set }) => {
  const [state, setState] = useState({
    loading: false,
  })
  const dispatch = useDispatch()
  const access_token = useSelector(state => state.userSlice.user.access_token)

  return (
    <Modal
      dialogClassName='rounded-4'
      size='xl'
      centered
      show={shown}
      onHide={() => set(p => ({ ...p, moveMeetingModalShown: false }))}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: '400' }}>Выбор времени</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SelectDate
          type={meeting.type}
          access_token={access_token}
          onCreate={async slotId => {
            setState(p => ({ ...p, loading: true }))
            const movedSuccessfully = await moveMeeting(
              access_token,
              meeting,
              slotId
            )
            setState(p => ({ ...p, loading: false }))
            if (!movedSuccessfully) dispatch(showErrorMsg('Произошла ошибка'))
            else {
              set(p => ({ ...p, moveMeetingModalShown: false }))
              dispatch(showSuccessMsg('Встреча перенесена'))
              updateUserMeetings(access_token, dispatch, [
                'slot',
                'manager',
                'feedback',
              ])
            }
          }}
          loading={state.loading}
          move={true}
        />
      </Modal.Body>
    </Modal>
  )
}

export const FastResponseModal = ({ shown, set, vacancyId }) => {
  const [state, setState] = useState({
    resumeUploaded: false,
    loading: false,
    resumeId: null,
    contacts: [],
  })
  const dispatch = useDispatch()

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      multiple: false,
      accept: { 'application/pdf': ['.pdf'] },
    })

  const handleSendContacts = async () => {
    for (const contact of state.contacts) {
      const data = {
        type: contact.type,
        name: contact.name,
        value: contact.value,
        preferred: contact.preferred,
        resumeId: state.resumeId,
      }

      const res = await createGuestVacancyResponseResumeContact(data)

      if (res === null || !res.ok)
        await dispatch(showErrorMsg('Ошибка отправки контакта'))
    }

    localStorage.setItem(
      'guestResponseContacts',
      JSON.stringify(state.contacts)
    )
    await dispatch(showSuccessMsg('Отклик отправлен'))
    closeModal()
  }

  const handleUploadResume = async () => {
    if (acceptedFiles.length === 0)
      return await dispatch(showErrorMsg('Выберите файл'))

    try {
      const res = await createGuestVacancyResponse(
        vacancyId,
        acceptedFiles[0],
        acceptedFiles[0].name
      )
      if (res === null) return dispatch(showErrorMsg('Ошибка на сервере'))
      else if (res.status === 404 || res.status === 409)
        return dispatch(showErrorMsg('Вакансия не найдена'))
      else if (res.status === 413)
        return dispatch(showErrorMsg('Файл слишком большой'))
      else if (res.status === 415)
        return dispatch(showErrorMsg('Неверный тип файла'))
      else if (res.status === 429)
        return dispatch(
          showErrorMsg('Вы отправляете файлы слишком часто. Попробуйте позже')
        )
      else if (!res.ok) return dispatch(showErrorMsg('Ошибка на сервере'))
      const data = await res.json()

      const guestResponseSent = JSON.parse(
        localStorage.getItem('guestResponseSent') || '[]'
      )
      guestResponseSent.push(vacancyId)
      localStorage.setItem(
        'guestResponseSent',
        JSON.stringify(guestResponseSent)
      )

      const contacts = JSON.parse(
        localStorage.getItem('guestResponseContacts') || '[]'
      )
      setState(p => ({
        ...p,
        resumeUploaded: true,
        contacts,
        resumeId: data.resume.id,
      }))
    } catch (e) {
      dispatch(showErrorMsg('Ошибка на сервере'))
      console.error(e)
    }
  }

  const closeModal = () => set(p => ({ ...p, fastResponseModalShown: false }))

  useEffect(() => {
    const cachedResume = localStorage.getItem('pdfResume')
    if (cachedResume)
      setState(p => ({ ...p, cachedResume: JSON.parse(cachedResume) }))
  }, [])

  return (
    <Modal
      dialogClassName='rounded-4'
      contentClassName='p-3'
      size='xl'
      centered
      show={shown}
      onHide={closeModal}
      onClick={e => e.stopPropagation()}
    >
      <Modal.Header closeButton className='border-0'>
        <Modal.Title style={{ fontWeight: '400' }}>Быстрый отклик</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!state.resumeUploaded && !state.loading && (
          <div className='w-100 d-flex flex-column align-items-center gap-2 fs-5'>
            <span className='fw-semibold'>Загрузить резюме из PDF</span>
            <div
              {...getRootProps({
                className:
                  'w-100 d-flex flex-column text-center justify-content-center align-items-center p-5 rounded-5 gap-3',
                style: {
                  border:
                    '2px dashed ' +
                    (isDragActive ? COLORS.primary : 'lightgray'),
                  cursor: 'pointer',
                },
              })}
            >
              <input {...getInputProps()} />
              <DownloadSvg />
              <span
                style={{
                  color: isDragActive ? COLORS.primary : COLORS.dark_neutral,
                }}
              >
                Перенеси или загрузи из проводника
                <br />
                PDF файл своего резюме
              </span>
              {acceptedFiles.length > 0 && <span>{acceptedFiles[0].name}</span>}
            </div>
          </div>
        )}
        {state.loading && (
          <>
            <div className='w-100 fs-5 text-center mb-3'>
              Резюме загружается. <br />
              Это займет некоторое время
            </div>
            <Loader size='small' />
          </>
        )}
        {state.resumeUploaded && !state.loading && (
          <>
            <span className='fs-4 fw-semibold'>Укажите контакты</span>
            <GuestResponseContacts
              contacts={state.contacts}
              setState={setState}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer className='border-0'>
        {!state.resumeUploaded && !state.loading && (
          <button
            className='btn primary py-2 px-5 fs-5'
            disabled={acceptedFiles.length === 0}
            onClick={handleUploadResume}
          >
            Далее
          </button>
        )}
        {state.resumeUploaded && !state.loading && (
          <button
            className='btn primary py-2 px-5 fs-5'
            onClick={handleSendContacts}
          >
            Отправить
          </button>
        )}
        <button className='btn outlined py-2 px-5 fs-5' onClick={closeModal}>
          Отменить
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export const VideoModal = ({ state, setState }) => {
  return (
    <Modal
      onHide={() => setState(p => ({ ...p, videoModalShown: false }))}
      dialogClassName='rounded-4'
      size='lg'
      show={state.videoModalShown}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: '400' }}>
          Запись собеседования
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <VideoPlayer url={state.videoUrl || ''} />
      </Modal.Body>

      <Modal.Footer>
        <button
          onClick={() => setState(p => ({ ...p, videoModalShown: false }))}
          className='btn primary'
        >
          Закрыть
        </button>
      </Modal.Footer>
    </Modal>
  )
}
