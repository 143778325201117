import { useNavigate } from 'react-router'
import {
  COLORS,
  SOURCE,
  colorsByResponseStatus,
  labelsByResponseStatus,
  labelsBySalaryCurrency,
} from '../../../../constants'
import { ReactComponent as DeleteOutlineSvg } from '../../../../icons/delete-outline.svg'
import { DefaultAvatar } from '../../../../components/ui/DefaultAvatar'

export const ResponseMobile = ({ response, handleDelete }) => {
  const navigate = useNavigate()
  const employer = response.vacancy.employer
  return (
    <div className='w-100 d-flex d-lg-none flex-column gap-3'>
      <div className='d-flex flex-row align-items-center'>
        <DefaultAvatar
          type='b2c'
          src={SOURCE.url + `employers/${employer.id}/avatar`}
          className='rounded'
          style={{
            height: '60px',
            width: '60px',
            boxShadow: '0 0 12px 0 #6757f14d',
          }}
        />
        <div className='d-flex flex-column flex-grow-1 gap-1 ms-3'>
          <span className='fs-6 fw-semibold'>{employer.name}</span>
          <span
            className='fw-medium text-white rounded px-3 py-1 text-nowrap'
            style={{
              backgroundColor: colorsByResponseStatus[response.status],
              width: 'fit-content',
              fontSize: '12px',
            }}
          >
            {labelsByResponseStatus[response.status]}
          </span>
        </div>
        <span
          className='align-self-start mt-1 text-nowrap'
          style={{ color: COLORS.header_text, fontSize: '12px' }}
        >
          {response.vacancy.salary
            ? response.vacancy.salary.toLocaleString() +
              ' ' +
              labelsBySalaryCurrency[response.vacancy.salaryCurrency]
            : 'З/п на собеседовании'}
        </span>
      </div>
      <p className='fs-6 fw-semibold'>{response.vacancy.name}</p>
      <div className='d-flex justify-content-between align-items-center'>
        <button className='no_outline' onClick={handleDelete}>
          <DeleteOutlineSvg
            style={{ width: '24px', height: '24px', fill: COLORS.danger }}
          />
        </button>
        <p
          className='fs-6 text-decoration-underline'
          style={{ cursor: 'pointer' }}
          onClick={() => navigate('/vacancies/' + response.vacancy.id)}
        >
          Перейти на вакансию
        </p>
      </div>
    </div>
  )
}

export const ResponseMobilePlaceholder = () => {
  return (
    <div className='w-100 d-flex d-lg-none flex-column gap-3 placeholder-glow'>
      <div className='d-flex flex-row align-items-center'>
        <div
          className='rounded img-fluid placeholder'
          style={{
            objectFit: 'cover',
            width: '70px',
            height: '70px',
            boxShadow: '0 0 6px 0 #00000010',
          }}
        />
        <div className='d-flex flex-column flex-grow-1 gap-1 ms-3'>
          <span className='fs-6 fw-semibold'>
            <span class='placeholder col-4'></span>
          </span>
          <span
            className='fw-medium rounded py-1 text-nowrap'
            style={{
              fontSize: '12px',
              color: COLORS.header_text,
            }}
          >
            <span class='placeholder col-3 fs-3'></span>
          </span>
        </div>
        <span
          className='align-self-start mt-1 text-nowrap'
          style={{ color: COLORS.header_text }}
        >
          <span class='placeholder col-10 px-5'></span>
        </span>
      </div>
      <p className='fs-6 fw-semibold'>
        <span class='placeholder col-3'></span>
      </p>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='col-auto d-flex' style={{ alignItems: 'center' }}>
          <span
            class='placeholder col-12'
            style={{ width: '24px', height: '24px' }}
          ></span>
        </div>
        <div className='col d-flex'>
          <p
            className='fs-6 my-auto text-decoration-underline col'
            style={{ cursor: 'pointer' }}
          >
            <span class='placeholder offset-8 col-4'></span>
          </p>
        </div>
      </div>
    </div>
  )
}
