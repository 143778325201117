import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { isProcessingPDF } from '../../../api/applicant'
import { createResume } from '../../../api/resume'
import { AppInput } from '../../../components/ui/AppInput'
import { showErrorMsg } from '../../../store/slices/alertSlice'

export const CreateResume = () => {
  const [state, setState] = useState({
    specialty: '',
  })
  const access_token = useSelector(
    (state) => state.userSlice.user.access_token
  )
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const resume = useSelector((state) => state.resumeSlice.resume)
  useEffect(() => {
    if (!access_token) return navigate('/auth')
    isProcessingPDF(dispatch, access_token).then((flag) => {
      console.log('Createresume TOKEN', access_token)
      flag && navigate('/auth/next', { state: { step: 'create_resume' } })
    })
  }, [])

  return (
    <>
      <div
        className='container d-flex flex-column justify-content-center w-100 my-auto'
        style={{ maxWidth: '900px' }}
      >
        <p className='text-center mb-3 fs-1' style={{ fontWeight: '400' }}>
          Теперь расскажи о своей специальности
        </p>
        <p
          className='text-center mb-3 mb-lg-5 fs-5'
          style={{ fontWeight: '200' }}
        >
          Введи название своей специальности в поле ниже, а мы создадим для тебя
          резюме, которые ты сможешь заполнить в профиле
        </p>
        <AppInput
          className='mb-3'
          label='Специальность'
          value={state.specialty}
          showAlert={state.specialty === ''}
          onChange={(v) => setState((p) => ({ ...p, specialty: v }))}
        />
        <button
          className='align-self-end btn rounded-pill py-2 px-4 primary'
          onClick={() =>
            nextBtnHandler(
              access_token,
              state.specialty,
              resume.id,
              navigate,
              dispatch
            )
          }
        >
          Продолжить
        </button>
      </div>
    </>
  )
}

async function nextBtnHandler(access_token, specialty, id, navigate, dispatch) {
  try {
    if (specialty.trim().length < 3) {
      dispatch(
        showErrorMsg('Название специальности должно быть не короче 3 символов')
      )
      return
    }
    const res = await createResume(specialty.trim(), access_token, id)
    if (!res.ok && res.status !== 409) {
      console.log(res)
      dispatch(showErrorMsg('Ошибка при создании резюме'))
    } else {
      navigate('/auth/next', { state: { step: 'create_resume' } })
    }
  } catch (error) {
    console.log(1, error)
    dispatch(showErrorMsg('Ошибка при создании резюме'))
  }
}
