import { Nav, Navbar } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router'
import { COLORS } from '../constants'
import { ReactComponent as InfoCircleBroken } from '../icons/info-circle-broken.svg'
import { ReactComponent as MeetNow } from '../icons/meet_now.svg'
import { ReactComponent as PencilCV } from '../icons/pencil_cv.svg'
import { ReactComponent as ResponsesSvg } from '../icons/responses.svg'
import { ReactComponent as MagicStick } from '../icons/magic_stick.svg'

export default function ProfileNavbar({ chatId }) {
	const navigate = useNavigate()
	const location = useLocation()

	return (
		<Navbar className='w-sm-100 w-sm-max bg-white d-flex flex-row flex-wrap px-4 py-3 rounded-3 mb-md-4 mb-2'>
			<Nav className='w-100 gap-3 justify-content-center justify-content-md-start'>
				<Nav.Link
					className='d-flex align-items-center text-nowrap rounded-3 text-black px-3 px-md-4 py-2'
					onClick={() => navigate('/account')}
					eventKey='1'
					style={{
						backgroundColor:
							location.pathname === '/account' ? COLORS.neutral : '',
						fontSize: '1rem',
						outline: 'none',
						gap: '.75rem',
					}}
				>
					<InfoCircleBroken />
					<span className='d-none d-md-inline'>Обзор</span>
				</Nav.Link>
				<Nav.Link
					className='d-flex align-items-center text-nowrap rounded-3 text-black px-3 px-md-4 py-2'
					onClick={() => navigate('/account/resume')}
					eventKey='2'
					style={{
						backgroundColor:
							location.pathname === '/account/resume' ? COLORS.neutral : '',
						fontSize: '1rem',
						outline: 'none',
						gap: '.75rem',
					}}
				>
					<PencilCV />
					<span className='d-none d-md-inline'>Резюме</span>
				</Nav.Link>
				<Nav.Link
					className='d-flex align-items-center text-nowrap rounded-3 text-black px-3 px-md-4 py-2'
					onClick={() => navigate('/account/meetings')}
					eventKey='3'
					style={{
						backgroundColor:
							location.pathname === '/account/meetings' ? COLORS.neutral : '',
						fontSize: '1rem',
						outline: 'none',
						gap: '.75rem',
					}}
				>
					<MeetNow />
					<span className='d-none d-md-inline'>Встречи</span>
				</Nav.Link>
				<Nav.Link
					className='d-flex align-items-center text-nowrap rounded-3 text-black px-3 px-md-4 py-2'
					onClick={() => navigate('/account/responses')}
					eventKey='4'
					style={{
						backgroundColor:
							location.pathname === '/account/responses' ? COLORS.neutral : '',
						fontSize: '1rem',
						fontWeight: 600,
						outline: 'none',
						gap: '.75rem',
					}}
				>
					<ResponsesSvg />
					<span className='d-none d-md-inline'>Отклики</span>
				</Nav.Link>
				{chatId && (
					<Nav.Link
						className='d-flex align-items-center text-nowrap rounded-3 text-black px-3 px-md-4 py-2'
						onClick={() => navigate('/account/neuro')}
						eventKey='4'
						style={{
							backgroundColor:
								location.pathname === '/account/neuro'
									? COLORS.neutral
									: '',
							fontSize: '1rem',
							fontWeight: 600,
							outline: 'none',
							gap: '.75rem',
						}}
					>
						<MagicStick />
						<span className='d-none d-md-inline'>Нейрорезюме</span>
					</Nav.Link>
				)}
			</Nav>
		</Navbar>
	)
}
