import { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as SuccessSvg } from './icons/check_line.svg'
import { ReactComponent as FailSvg } from './icons/fail.svg'
import { hideErrorMsg, hideSuccessMsg } from './store/slices/alertSlice'

export const MONTHS = [
	'января',
	'февраля',
	'марта',
	'апреля',
	'мая',
	'июня',
	'июля',
	'августа',
	'сентября',
	'октября',
	'ноября',
	'декабря',
]
export const FULL_MONTHS = [
	'Январь',
	'Февраль',
	'Март',
	'Апрель',
	'Май',
	'Июнь',
	'Июль',
	'Август',
	'Сентябрь',
	'Октябрь',
	'Ноябрь',
	'Декабрь',
]
export const SHORT_MONTHS = [
	'янв.',
	'фев.',
	'мар.',
	'апр.',
	'мая',
	'июн.',
	'июл.',
	'авг.',
	'сент.',
	'окт.',
	'ноя.',
	'дек.',
]
export const PASTEL_COLORS = ['#FFFCB0', '#D4FFC0', '#D1F4FF', '#FDD0B7']
export const AVATARS_BACKGROUNDS = [
	'radial-gradient(50% 50% at 50% 50%, rgba(248, 255, 170, 0.50) 0%, rgba(176, 232, 142, 0.50) 100%)',
	'radial-gradient(50% 50% at 50% 50%, #AAFFC7 0%, #8EE8D2 100%',
	'radial-gradient(50% 50% at 50% 50%, #ACAAFF 0%, #B58EE8 100%',
	'radial-gradient(50% 50% at 50% 50%, #F8FFAA 0%, #B0E88E 100%',
]

export const AREA_LANDINGS = [
	{
		img: 'tamara_main.png',
		route: 'tamara',
		title: 'Дарим шоппер с Тамарой Кожемятько\n за прохождение интервью',
		text: 'Каждому пятому в апреле',
	},
	{
		img: 'ozon_main.png',
		route: 'please',
		title: 'Дарим 1000₽ на Ozon\n за прохождение интервью',
		text: 'Каждому десятому в апреле',
	},
	{
		img: 'engineer_main.png',
		route: 'search',
		title: 'Оффер в IT всего за одно собеседование',
	},
	{
		img: '1c_main.png',
		route: '1c',
		title: 'Оффер в 1C-разработку всего за одно собеседование',
	},
	{
		img: 'engineer_main.png',
		route: 'engineer',
		title: 'Оффер на должность инженера всего за одно собеседование',
	},
	{
		img: 'engineer_main.png',
		route: 'joke',
		title:
			'Ну ладно, может быть не в Google, но зато всего за одно собеседование',
		text: 'Зато на нашей платформе собрано множество других не менее крутых работодателей России – крупнейшие банки, строительные и IT-компании. С помощью активного применения нейросетей на нашей платформе мы помогаем кандидатам максимально быстро найти работу – создаем нейрорезюме и сами рекомендуем тебя в компании',
	},
	{
		img: 'go_main.png',
		route: 'go',
		title: 'Оффер в Go-разработку всего за одно собеседование',
	},
	{
		img: 'java_main.png',
		route: 'java',
		title: 'Оффер в Java-разработку всего за одно собеседование',
	},
	{
		img: 'js_main.png',
		route: 'js',
		title: 'Оффер в JS-разработку всего за одно собеседование',
	},
	{
		img: 'manager_main.png',
		route: 'manager',
		title:
			'Оффер на должность менеджера по продажам всего за одно собеседование',
	},
	{
		img: 'marketolog_main.png',
		route: 'marketolog',
		title: 'Оффер на должность маркетолога всего за одно собеседование',
	},
	{
		img: 'php_main.png',
		route: 'php',
		title: 'Оффер в PHP-разработку всего за одно собеседование',
	},
	{
		img: 'react_main.png',
		route: 'react',
		title: 'Оффер в React-разработку всего за одно собеседование',
	},
	{
		img: 'python/python_main.png',
		route: 'python',
		title: 'Оффер в Python-разработку всего за одно собеседование',
	},
]

export const COLORS = {
	primary: '#6757F1', //'#7268CB', #8F4EE3
	light_primary: '#F1F0FA',
	red_primary: '#F8485D',
	red_light_primary: '#FFF9FA',
	text_primary: '#5246B8',
	hover_primary: '#4C3EC9',
	danger: '#F8485D',
	hover_danger: '#d93d4c',
	footer: '#272727', // '#F8F8FD'
	secondary: '#FAFAFD',
	text: '#272727',
	second_text: '#6E6D75',
	header_text: '#849095',
	pastel_secondary: '#FFF9F9',
	pastel: '#FBFBFF',
	warning: '#FF9900',
	success: '#01A707',
	light_success: '#40C700',
	hover_success: '#008307',
	hr: '#C7C8C9',
	light_neutral: '#F1F1F1',
	neutral: '#E8E8EC',
	dark_neutral: '#9B9B9B',
	mainBg: '#F8F7FB',
	gray: '#E9F0F6',
	orange: '#F96D49',
	yellow: '#FECF77',
	black: '#18191B',
	purple: '#715AFF',
	sand: '#F2D291',
}

export const MAX_WIDTH = '1400px'
export const PADDINGS = ' px-3 px-lg-5'

export const SOURCE = {
	url:
		process.env.REACT_APP_NODE_ENV === 'dev'
			? 'https://dev.hurtle.ru/api/v1/'
			: 'https://hurtle.ru/api/v1/',
	source_url: 'https://resource.hurtle.ru/',
}

export const GOOGLE_CLIENT_ID =
	'220007612496-406ntg3j25rlksus9hbvdtcb69rkffpu.apps.googleusercontent.com'

export const toTime = datetime => {
	return new Date(datetime).toLocaleTimeString().slice(0, 5)
}

export const toDate = datetime => {
	return new Date(datetime).toLocaleDateString()
}

export const teamSizeLabels = {
	LESS_THAN_15: 'до 15 человек',
	BETWEEN_15_AND_50: 'до 50 человек',
	BETWEEN_50_AND_100: 'до 100 человек',
	BETWEEN_100_AND_500: 'до 500 человек',
	MORE_THAN_500: 'свыше 500 человек',
}

export const datePlusTime = datetime => {
	const a = new Date(datetime)
	let [date, time] = a.toLocaleString().split(', ')
	date = date.split('.')
	date[1] = MONTHS[parseInt(date[1]) - 1]
	return date.join(' ') + ', ' + time.substring(0, 5)
}

export const calendarDateToDateTime = calendarDate => {
	return [
		calendarDate.getMonth() + 1,
		calendarDate.getDate(),
		calendarDate.getFullYear(),
	].join('-')
}

export const ErrorMsg = () => {
	const dispatch = useDispatch()
	const message = useSelector(state => state.alertSlice.alert.errorMsg)
	const hideHandler = () => dispatch(hideErrorMsg())
	useEffect(() => {
		const timeout = setTimeout(hideHandler, 3000)
		return () => clearTimeout(timeout)
	}, [message])
	return (
		<div
			className='position-fixed'
			style={{
				maxWidth: '500px',
				top: '10px',
				right: '10px',
				zIndex: '999999',
			}}
		>
			<Alert
				show={Boolean(message)}
				variant='danger'
				className='bg-white border-0'
				style={{ filter: 'drop-shadow(10px 10px 10px #F1F1F1)' }}
				onClose={() => dispatch(hideErrorMsg())}
				dismissible
			>
				<div className='d-flex me-sm-5'>
					<FailSvg className='me-2' height='24px' width='24px' />
					<p className='fs-5' style={{ color: COLORS.red_primary }}>
						Ошибка
					</p>
				</div>
				<p
					className='fs-6'
					style={{ color: COLORS.header_text, whiteSpace: 'pre-wrap' }}
				>
					{message}
				</p>
			</Alert>
		</div>
	)
}

export const SuccessMsg = () => {
	const dispatch = useDispatch()
	const message = useSelector(state => state.alertSlice.alert.successMsg)
	const hideHandler = () => dispatch(hideSuccessMsg())
	useEffect(() => {
		const timeout = setTimeout(hideHandler, 3000)
		return () => clearTimeout(timeout)
	}, [message])
	return (
		<div
			className='position-fixed'
			style={{
				maxWidth: '500px',
				top: '10px',
				right: '10px',
				zIndex: '999999',
			}}
		>
			<Alert
				show={Boolean(message)}
				variant='success'
				className='bg-white border-0'
				style={{ filter: 'drop-shadow(10px 10px 10px #F1F1F1)' }}
				onClose={() => dispatch(hideSuccessMsg())}
				dismissible
			>
				<div className='d-flex me-sm-5'>
					<SuccessSvg
						className='me-2'
						style={{ flexShrink: '0' }}
						fill={COLORS.success}
						height='24px'
						width='24px'
					/>
					<p className='fs-5' style={{ color: COLORS.success }}>
						Успешно!
					</p>
				</div>
				<p className='fs-6' style={{ color: COLORS.header_text }}>
					{message}
				</p>
			</Alert>
		</div>
	)
}

export const switchTitle = path => {
	document.title = path.includes('contacts')
		? 'Хартл | Контакты'
		: path.includes('policy')
			? 'Хартл | Документы'
			: path.includes('python')
				? 'Работа Python-разработчиком'
				: 'Сервис поиска работы Хартл'
}

const lengthValidate = (s, min, max) => s?.length >= min && s?.length < max
const rangeValidate = (s, min, max) => s >= min && s < max

export const Validation = {
	validName: name => /^[A-zА-яЁё]+$/i.test(name),
	validFIO: (firstName, lastName, middleName) =>
		lengthValidate(firstName, 2, 50) &&
		lengthValidate(lastName, 2, 50) &&
		(!middleName || lengthValidate(middleName, 1, 50)),
	validFio: fio =>
		fio.trim().split(' ').length >= 2 &&
		fio.trim().split(' ').length < 4 &&
		fio
			.trim()
			.split(' ')
			.filter(el => !Validation.validName(el)).length === 0 &&
		fio.trim().split(' ')[0].length >= 2 &&
		fio.trim().split(' ')[1].length >= 2,
	validMail: mail =>
		/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
			mail
		), // /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i
	validPhone: phone => lengthValidate(phone, 1, 36),
	validPassword: pswd => pswd.length >= 8, // /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{6,}$/.test(pswd)
	validDateTime: date => date.length === 20,
	validDate: date =>
		date.length === 10 &&
		rangeValidate(+date.split('-')[0], 1900, new Date().getFullYear() - 13),
	validTelegram: tg => tg !== '',
	validMask: mask => !mask.includes('_') && mask !== '',
}

export const getMonthPeriod = (st_m, st_y, end_m, end_y, till_now) => {
	if (till_now) {
		return st_m + ' ' + st_y + ' - по н.в.'
	}
	if (st_y === end_y) {
		if (st_m === end_m) {
			return st_m + ' ' + st_y
		}
		return st_m + ' - ' + end_m + ' ' + end_y
	}
	return st_m + ' ' + st_y + ' - ' + end_m + ' ' + end_y
}

export const useDebounceCallback = (func, delay) => {
	const [args, setArgs] = useState(null)
	useEffect(() => {
		if (args === null) return
		const timeout = setTimeout(() => func(...args), delay)
		return () => clearTimeout(timeout)
	}, [args]) // DO NOT add func and delay to deps
	return (...args) => setArgs(args)
}

export function uuidv4() {
	return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c =>
		(
			+c ^
			(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
		).toString(16)
	)
}

export const isResumeFilled = resume => {
	return (
		resume.skills?.length > 0 ||
		resume.certificates?.length > 0 ||
		resume.education?.length > 0 ||
		resume.experience?.length > 0 ||
		resume.languages?.length > 0
	)
}

export const isInt = value =>
	!isNaN(value) &&
	parseInt(Number(value)) === +value &&
	!isNaN(parseInt(value, 10))

export const labelsByEmploymentType = {
	FULL_TIME: 'Полная занятость',
	PART_TIME: 'Частичная занятость',
	PROJECT: 'Проектная',
	VOLUNTEER: 'Волонтерство',
	PROBATION: 'Стажировка',
}

export const labelsByWorkplaceModel = {
	OFFICE: 'Офис',
	HYBRID: 'Гибрид',
	REMOTE: 'Удаленно',
}

export const labelsByExperience = {
	NO_EXPERIENCE: 'Без опыта',
	BETWEEN_1_AND_3: '1-3 года',
	BETWEEN_3_AND_6: '3-6 лет',
	MORE_THAN_6: '6 и более лет',
}

export const labelsByTeamRole = {
	COLLABORATIVE_WORK: 'Координирование команды',
	TEAM_COORDINATION: 'Совместная работа',
	TEAM_MANAGEMENT: 'Управление командой',
	INDEPENDENT_WORK: 'Самостоятельная работа',
}

export const labelsByReportingForm = {
	GROUP_WORK: 'Групповая работа',
	PROJECT_MANAGER: 'Project-менеджер',
	DIRECTOR: 'Директор',
	INDEPENDENT_WORK: 'Независимая работа',
}

export const labelsByWorkingHours = {
	GROUP_WORK: 'Групповая работа',
	STRICT_SCHEDULE: 'Строгий график',
	FREELANCE: 'Фриланс',
	FIXED_HOURS: 'Фиксированные часы',
}

export const labelsBySalaryCurrency = {
	RUB: 'руб/мес.',
	USD: 'дол/мес.',
	EUR: 'евро/мес.',
	GBP: 'фунт/мес.',
	GEL: 'GEL (₾)',
	AZN: 'AZN (₼)',
	BYR: 'BYR (Br)',
	KGS: 'KGS (с)',
	KZT: 'KZT (₸)',
	UZS: 'UZS (S)',
}

export const meetingTypes = {
	CONSULTATION_B2C_EXPERT: 'Карьерная консультация',
	CONSULTATION_B2B: 'Консультация бизнес',
	CONSULTATION_B2C: 'Консультация',
	INTERVIEW: 'Собеседование',
}

export const meetingDescriptions = {
	INTERVIEW: 'Интервью',
	CONSULTATION_B2C: 'Описание консультации',
	CONSULTATION_B2C_EXPERT: 'Описание кaрьерной консультации',
	CONSULTATION_B2B: 'Описание консультации бизнес',
}

export const applicantGenders = {
	[null]: 'Пол (Не указан)',
	MALE: 'Мужской',
	FEMALE: 'Женский',
}

export const CONTACT_TYPE = {
	EMAIL: 'EMAIL',
	PHONE: 'PHONE',
	TELEGRAM: 'TELEGRAM',
	GITHUB: 'GITHUB',
	VK: 'VK',
	FACEBOOK: 'FACEBOOK',
	LINKEDIN: 'LINKEDIN',
	OTHER: 'OTHER',
}

export const PLACEHOLDER_CONTACT = {
	EMAIL: 'somebody@gmail.com',
	PHONE: '+7 (000) 000-00-00',
	TELEGRAM: '@nickname',
	GITHUB: 'https://github.com/user',
	VK: 'https://vk.com/id',
	FACEBOOK: 'https://www.facebook.com/',
	LINKEDIN: 'https://www.linkedin.com/',
	OTHER: 'контакт',
}

export const CONTACT_LABELS = {
	EMAIL: 'Почта',
	PHONE: 'Телефон',
	TELEGRAM: 'Telegram',
	GITHUB: 'GitHub',
	VK: 'VK',
	FACEBOOK: 'Facebook',
	LINKEDIN: 'LinkedIn',
	OTHER: 'Другое',
}

export const labelsByVacancyStatus = {
	UNDER_REVIEW: 'На рассмотрении',
	FAILED_TO_PASS_REVIEW: 'Не прошла проверку',
	PUBLISHED: 'Опубликована',
	HIDDEN: 'Скрыта',
}

export const typeAttributeByContactType = type => {
	switch (type) {
		case CONTACT_TYPE.EMAIL:
			return 'email'
		case CONTACT_TYPE.PHONE:
			return 'tel'
		default:
			return 'text'
	}
}
export const labelsByResponseStatus = {
	NEW_APPLICATION: 'Ждем ответа',
	SCREENING: 'Ждем ответа',
	INTERVIEWING: 'Интервью',
	OFFER_MADE: 'Оффер',
	HIRED: 'Оффер',
	REJECTED: 'Отказ',
}

export const colorsByResponseStatus = {
	NEW_APPLICATION: COLORS.warning,
	SCREENING: COLORS.warning,
	INTERVIEWING: COLORS.purple,
	OFFER_MADE: COLORS.success,
	HIRED: COLORS.success,
	REJECTED: COLORS.danger,
}
