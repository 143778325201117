import { SOURCE } from '../constants'

export const getVacancy = async (access_token, id, queryObj = {}) => {
  try {
    const res = await fetch(
      SOURCE.url +
        `vacancies/${
          id +
          (queryObj.include?.length
            ? '?' +
              new URLSearchParams(
                'include=' + queryObj.include.join('&include=')
              ).toString()
            : '')
        }`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: access_token,
        },
      }
    )
    if (res.status === 404) return 404
    if (!res.ok) return null
    return await res.json()
  } catch (e) {
    console.error(e)
    return null
  }
}

export const getAllVacancies = async (access_token, query = '?') => {
  try {
    const response = await fetch(
      `${SOURCE.url}vacancies${
        query === '?' ? '' : '?' + new URLSearchParams(query).toString()
      }`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: access_token,
        },
      }
    )
    if (!response.ok) return null
    return await response.json()
  } catch (e) {
    console.error(e)
    return null
  }
}

export const getCities = async () => {
  try {
    const response = await fetch(`${SOURCE.url}vacancies/cities`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) return null
    return await response.json()
  } catch (e) {
    console.error(e)
    return null
  }
}

export const addViewed = async (access_token, id) => {
  try {
    const response = await fetch(`${SOURCE.url}vacancies/${id}/viewed`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
    })
    return response.ok
  } catch (e) {
    console.error(e)
    return false
  }
}

export const applyToVacancy = async (access_token, id) => {
  try {
    const response = await fetch(`${SOURCE.url}vacancyResponses/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
      body: JSON.stringify({
        vacancyId: id,
        _requester: 'APPLICANT',
      }),
    })
    if (!response.ok) return false
    return await response.json()
  } catch (e) {
    console.error(e)
    return false
  }
}

export const getEmployer = async (access_token, id, queryObj = {}) => {
  try {
    const res = await fetch(
      SOURCE.url +
        `employer/${
          id +
          (queryObj.include?.length
            ? '?' +
              new URLSearchParams(
                'include=' + queryObj.include.join('&include=')
              )
            : '')
        }`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: access_token,
        },
      }
    )
    if (!res.ok) return null
    return await res.json()
  } catch (e) {
    console.error(e)
    return null
  }
}
