import { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { isProcessingPDF } from '../../../api/applicant'
import { COLORS, SOURCE } from '../../../constants'
import { ReactComponent as DownloadSvg } from '../../../icons/download.svg'
import { showErrorMsg } from '../../../store/slices/alertSlice'

export const ImportPDFResume = () => {
  const access_token = useSelector(
    (state) => state.userSlice.user.access_token
  )
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      multiple: false,
      accept: { 'application/pdf': ['.pdf'] },
    })

  useEffect(() => {
    if (!access_token) return navigate('/auth')
    isProcessingPDF(dispatch, access_token).then((flag) => {
      console.log('ImportPDFResume TOKEN', access_token)
      flag && navigate('/auth/next', { state: { step: 'create_resume' } })
    })
  }, [])

  return (
    <>
      <div
        className='container d-flex flex-column justify-content-center w-100 my-auto'
        style={{ maxWidth: '900px' }}
      >
        <p className='text-center mb-3 fs-1' style={{ fontWeight: '400' }}>
          Импорт резюме из PDF
        </p>
        <div
          {...getRootProps({
            className:
              'w-100 d-flex flex-column text-center justify-content-center align-items-center p-5 rounded-5 gap-3 mb-3',
            style: {
              border:
                '2px dashed ' + (isDragActive ? COLORS.primary : 'lightgray'),
              cursor: 'pointer',
            },
          })}
        >
          <input {...getInputProps()} />
          <DownloadSvg />
          <span
            style={{
              color: isDragActive ? COLORS.primary : COLORS.dark_neutral,
            }}
          >
            Перенеси или загрузи из проводника
            <br />
            PDF файл своего резюме
          </span>
          {acceptedFiles.length > 0 && <span>{acceptedFiles[0].name}</span>}
        </div>
        <button
          className='align-self-end btn rounded-pill py-2 px-4 primary'
          disabled={acceptedFiles.length === 0}
          onClick={() => {
            nextBtnHandler(access_token, acceptedFiles, navigate, dispatch)
          }}
        >
          Продолжить
        </button>
      </div>
    </>
  )
}

async function nextBtnHandler(access_token, acceptedFiles, navigate, dispatch) {
  try {
    if (acceptedFiles.length === 0)
      return await dispatch(showErrorMsg('Выберите файл'))
    const formData = new FormData()
    formData.append('file', acceptedFiles[0], acceptedFiles[0].name)
    const res = await fetch(SOURCE.url + 'resumeOcr/queuedImportWithOcr', {
      headers: { Authorization: access_token },
      method: 'POST',
      body: formData,
    })
    if (res.status === 413)
      return await dispatch(showErrorMsg('Файл слишком большой'))
    else if (res.status === 415)
      return await dispatch(showErrorMsg('Неверный тип файла'))
    else if (res.status === 429)
      return await dispatch(
        showErrorMsg('Вы отправляете файлы слишком часто. Попробуйте позже')
      )
    else if (!res.ok) return await dispatch(showErrorMsg('Ошибка на сервере'))
    else {
      const data = await res.json()
      console.log('DATA', data)
      localStorage.setItem('pdfJobId', data.jobId)
      navigate('/auth/next', { state: { step: 'create_resume' } })
    }
  } catch (error) {
    console.log(error)
    dispatch(showErrorMsg('Ошибка при создании резюме'))
  }
}
