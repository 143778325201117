import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { createMeeting, getAvailableMeetingSlots } from '../../../api/meetings'
import { Loader } from '../../../components/Loader'
import { MonthCalendar, SlotTime } from '../../../components/ui/MonthCalendar'
import { COLORS, meetingDescriptions, meetingTypes } from '../../../constants'
import { showErrorMsg } from '../../../store/slices/alertSlice'

export const SetInterview = () => {
	const user = useSelector(state => state.userSlice.user)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const type = 'INTERVIEW'
	const createHandler = async slotId => {
		const data = await createMeeting(
			slotId,
			user.access_token,
			type,
			meetingTypes[type],
			meetingDescriptions[type],
			dispatch
		)

		if (data === null) dispatch(showErrorMsg('Произошла ошибка'))
		else if (data.id)
			navigate('/auth/next', { state: { step: 'set_interview' } })
	}
	return (
		<div className='w-100'>
			<div className='mx-auto mb-3 mb-md-5' style={{ maxWidth: '900px' }}>
				<p className='text-center mb-3 fs-1' style={{ fontWeight: '400' }}>
					Приглашаем тебя на интервью
				</p>
				<p className='text-center fs-5' style={{ fontWeight: '200' }}>
					Мы будем рады пообщаться с тобой и уже сейчас готовы позвать тебя на
					собеседование! Тебе остаётся лишь выбрать время
				</p>
			</div>
			<SelectDate
				onCreate={createHandler}
				onNext={() =>
					navigate('/auth/next', { state: { step: 'set_interview' } })
				}
				type={type}
				access_token={user.access_token}
			/>
		</div>
	)
}

export const SelectDate = ({
	onCreate,
	onNext = () => {},
	move = false,
	type,
	access_token,
	loading = false,
	isRegister = true,
}) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [state, setState] = useState({
		availableSlots: {},
		selectedDate: '',
		selectedTimeId: -1,
		loaded: false,
	})
	useEffect(() => {
		(async () => {
			let data = await getAvailableMeetingSlots(access_token, [type])
			const items = data.items.filter((value, index, self) => {
				return self.findIndex(v => v.dateTime === value.dateTime) === index
			})
			data.items = items
			if (data === null) return dispatch(showErrorMsg('Произошла ошибка!'))
			setState(p => {
				let result = {}
				data.items.forEach(slot => {
					const splittedDate = slot.dateTime.split('T')[0].split('-')
					const key =
						splittedDate[1] + '-' + splittedDate[2] + '-' + splittedDate[0]
					if (!Object.prototype.hasOwnProperty.call(result, key)) result[key] = []
					result[key].push(slot)
				})
				let selectedDate = ''
				if (Object.keys(result).length !== 0) {
					selectedDate = new Date(Object.values(result)[0][0].dateTime)
					selectedDate = [
						(selectedDate.getMonth() + 1).toString().padStart(2, '0'),
						selectedDate.getDate().toString().padStart(2, '0'),
						selectedDate.getFullYear().toString(),
					].join('-')
				}

				return {
					...p,
					availableSlots: result,
					selectedDate: data.items.length ? selectedDate : '',
					loaded: true,
				}
			})
		})()
	}, [access_token])

	return !state.loaded ? (
		<Loader />
	) : (
		<div className='row justify-content-center' style={{ minHeight: '450px' }}>
			<div className='col-md-5 d-flex flex-column mb-4'>
				<MonthCalendar
					selectDate={setState}
					selectedDate={state.selectedDate}
					datesArray={Object.keys(state.availableSlots)}
				/>
			</div>
			<div className='col d-flex flex-column justify-content-between mb-4 px-lg-5 px-2'>
				<div className='d-flex flex-column h-100'>
					<p className='p-0 mb-3 fs-4'>Доступное время</p>
					<div className='h-100'>
						<div
							className='row g-lg-3 g-2 '
							style={{ overflowY: 'scroll', scrollbarWidth: 'none' }}
						>
							{state.availableSlots[state.selectedDate]
								?.sort((a, b) => new Date(a.dateTime) > new Date(b.dateTime))
								.map((slot, i) => (
									<SlotTime
										slot={slot}
										key={i}
										state={state}
										setState={setState}
									/>
								)) || (
								<div className='col h-100 d-flex'>
									<div className='d-flex flex-column justify-content-center m-auto'>
										<p
											className='fs-5 text-center m-auto'
											style={{ color: COLORS.primary, maxWidth: '500px' }}
										>
											Сейчас нельзя записаться на собеседование, но вы можете
											заполнить свое резюме сейчас, а записаться позже
										</p>
										<button
											onClick={() => navigate('/account/resume')}
											className='btn primary py-2 fs-6 px-3 mt-3 mx-auto'
										>
											Перейти в резюме
										</button>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className='d-flex w-100 flex-column mt-4'>
					<hr />
					<div className='d-flex w-100 flex-row flex-wrap  align-items-center'>
						{type !== 'CONSULTATION_B2C_EXPERT' && !move && (
							<p
								className='me-3 mb-2 fs-6'
								onClick={onNext}
								style={{ color: COLORS.primary, cursor: 'pointer' }}
							>
								Выберу позже
							</p>
						)}
						<button
							onClick={() => {
								onCreate(
									state.availableSlots[state.selectedDate].find(
										el => el.id === state.selectedTimeId
									).id
								)
							}}
							disabled={state.selectedTimeId === -1}
							className='btn btn-md py-2 px-3 mb-2 ms-auto fs-6'
							style={{
								backgroundColor: COLORS.primary,
								color: '#fff',
							}}
						>
							{loading ? (
								<Loader animation={false} color='white' size='small' />
							) : move ? (
								'Перенести'
							) : isRegister ? (
								'Найти работу'
							) : (
								'Забронировать'
							)}
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
