import {SOURCE} from "../constants"

export const getFullOffers = async (token, queryObj = {}) => {
    try {
        const include = queryObj.include || []
        let query = '?'
        include.forEach(el => {
            if (query !== '?') query += '&'
            query += 'include=' + el
        })
        const res = await fetch(SOURCE.url + "offers/my" + (query === '?' ? '' : query), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token
            }
        })
        if (!res.ok) return []
        const data = await res.json()
        return data.items.map(item => ({...item, vacancy: item.vacancyResponse.vacancy}))
    } catch (e) {
        console.error(e)
        return []
    }
}

export const changeOfferStatus = async (access_token, offerId, status) => {
    try {
        const res = await fetch(SOURCE.url + "offers/" + offerId + "/status", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": access_token
            },
            body: JSON.stringify({status})
        })
        if (!res.ok) return null
        return await res.json()
    } catch (e) {
        console.error(e)
        return null
    }
}