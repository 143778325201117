import { SOURCE } from '../../constants'
import { showErrorMsg } from '../../store/slices/alertSlice'
import {
	setCertificates,
	setEducation,
	setExperience,
	setLanguages,
	setResume,
	setSkills,
} from '../../store/slices/resumeSlice'
import {
	BasicResumeCertificateSchema,
	BasicResumeEducationSchema,
	BasicResumeExperienceSchema, BasicResumeLanguageSchema,
	BasicResumeSchema, createResumeCertificate, createResumeEducation,
	createResumeExperience, createResumeLanguage, deleteResumeCertificate, deleteResumeEducation,
	deleteResumeExperience, deleteResumeLanguage
} from "../../api/resume"

export const createSkill = (
	access_token,
	resume,
	dispatch
) => {
	const updatedSkills = [...resume.skills, '']
	if (updatedSkills.length > 30) {
		dispatch(showErrorMsg('Максимум 30 навыков'))
		return
	}
	dispatch(setSkills(updatedSkills))
}

export const updateSkill = (
	access_token,
	resume,
	skillId,
	newSkill,
	dispatch,
	updateServerResume
) => {
	const updatedSkills = resume.skills.map((s, i) =>
		i === skillId ? newSkill : s
	)
	if (updatedSkills.length > 30) {
		dispatch(showErrorMsg('Максимум 30 навыков'))
		return
	}
	dispatch(setSkills(updatedSkills))
	updateServerResume(
		access_token,
		resume.id,
		{ skills: updatedSkills.map(s => s.trim()).filter(s => s.length > 0) }
	)
}

export const deleteSkill = (
	access_token,
	resume,
	skillIdx,
	dispatch,
	updateServerResume
) => {
	const updatedSkills = resume.skills.filter((s, i) => i !== skillIdx)
	dispatch(setSkills(updatedSkills))
	updateServerResume(
		access_token,
		resume.id,
		{ skills: updatedSkills }
	)
}

export const updateResume = async (
	access_token,
	resume,
	updated,
	dispatch,
	updateServerResume
) => {
	const formatted = structuredClone(updated)
	if (formatted.desiredSalary)
		formatted.desiredSalary = formatted.desiredSalary.toLocaleString()
	dispatch(
		setResume({
			...resume,
			...formatted,
		})
	)

	try {
		await BasicResumeSchema.validate(updated)
	} catch (err) {
		console.log(updated)
		dispatch(showErrorMsg(err.errors))
		return
	}

	updateServerResume(access_token, resume.id, updated)
}

export const createExperience = async (access_token, resume, dispatch) => {
	const data = await createResumeExperience(access_token, resume)
	if (data === false) return
	data.startMonth = data.startMonth.toString().padStart(2, '0')
	data.endMonth = data.endMonth.toString().padStart(2, '0')
	await dispatch(setExperience([...resume.experience, data]))
}

export const editExperience = async (
	access_token,
	experienceId,
	updated,
	resumeExp,
	dispatch,
	updateServerExperience
) => {
	const experience = {
		...resumeExp.find(e => e.id === experienceId),
		...updated,
	}

	await dispatch(
		setExperience(resumeExp.map(e => (e.id === experienceId ? experience : e)))
	)
	if (Object.prototype.hasOwnProperty.call(updated, 'startMonth'))
		updated.startMonth = '' + updated.startMonth
	if (Object.prototype.hasOwnProperty.call(updated, 'endMonth'))
		updated.endMonth = '' + updated.endMonth
	if (Object.prototype.hasOwnProperty.call(updated, 'endYear')) {
		updated.endYear = updated.endYear || null
	}

	try {
		await BasicResumeExperienceSchema.validate(updated)
	} catch (err) {
		dispatch(showErrorMsg(err.errors))
		return
	}

	updateServerExperience(access_token, experienceId, updated)
}

export const deleteExperience = async (
	access_token,
	id,
	resumeExp,
	dispatch
) => {
	const ok = await deleteResumeExperience(access_token, id)
	if (!ok) return
	await dispatch(setExperience(resumeExp.filter(e => e.id !== id)))
}

export const createEducation = async (access_token, resume, dispatch) => {
	const data = await createResumeEducation(access_token, resume)
	if (data === false) return
	await dispatch(setEducation([...resume.education, data]))
}

export const editEducation = async (
	access_token,
	educationId,
	updated,
	resumeEd,
	dispatch,
	updateServerEducation
) => {
	const education = { ...resumeEd.find(e => e.id === educationId), ...updated }
	await dispatch(
		setEducation(resumeEd.map(e => (e.id === educationId ? education : e)))
	)

	try {
		await BasicResumeEducationSchema.validate(updated)
	} catch (err) {
		dispatch(showErrorMsg(err.errors))
		return
	}
	updateServerEducation(access_token, educationId, updated)
}

export const deleteEducation = async (access_token, id, resumeEd, dispatch) => {
	const ok = await deleteResumeEducation(access_token, id)
	if (!ok) return
	await dispatch(setEducation(resumeEd.filter(e => e.id !== id)))
}

export const createCertificate = async (access_token, resume, dispatch) => {
	const data = await createResumeCertificate(access_token, resume)
	if (data === false) return
	await dispatch(setCertificates([...resume.certificates, data]))
}

export const editCertificate = async (
	access_token,
	certificateId,
	updated,
	resumeCert,
	dispatch,
	updateServerCertificates
) => {
	const education = {
		...resumeCert.find(e => e.id === certificateId),
		...updated,
	}

	await dispatch(
		setCertificates(
			resumeCert.map(e => (e.id === certificateId ? education : e))
		)
	)
	try {
		await BasicResumeCertificateSchema.validate(updated)
	} catch (err) {
		dispatch(showErrorMsg(err.errors))
		return
	}

	updateServerCertificates(access_token, certificateId, updated)
}

export const deleteCertificate = async (
	access_token,
	id,
	resumeCert,
	dispatch
) => {
	const ok = await deleteResumeCertificate(access_token, id)
	if (!ok) return
	await dispatch(setCertificates(resumeCert.filter(e => e.id !== id)))
}

export const createLanguage = async (access_token, resume, dispatch) => {
	const data = await createResumeLanguage(access_token, resume)
	console.log(data)
	if (data === false) return
	await dispatch(setLanguages([...resume.languages, data]))
}

export const editLanguage = async (
	access_token,
	languageId,
	updated,
	resumeLang,
	dispatch,
	updateServerLanguage
) => {
	const language = { ...resumeLang.find(e => e.id === languageId), ...updated }
	await dispatch(
		setLanguages(resumeLang.map(e => (e.id === languageId ? language : e)))
	)
	
	try {
		await BasicResumeLanguageSchema.validate(updated)
	} catch (err) {
		dispatch(showErrorMsg(err.errors))
		return
	}

	updateServerLanguage(access_token, languageId, updated)
}

export const deleteLanguage = async (
	access_token,
	id,
	resumeLang,
	dispatch
) => {
	const ok = await deleteResumeLanguage(access_token, id)
	if (!ok) return
	await dispatch(setLanguages(resumeLang.filter(e => e.id !== id)))
}

export const uploadAvatar = async (access_token, userId, file, dispatch) => {
	try {
		const formData = new FormData()
		formData.append('file', file)
		const res = await fetch(`${SOURCE.url}applicants/${userId}/avatar`, {
			method: 'PUT',
			headers: {
				Authorization: access_token,
			},
			body: formData,
		})
		if (!res.ok) {
			const data = await res.json()
			dispatch(showErrorMsg(data.error))
		}
	} catch (error) {
		console.error(error)
		dispatch(showErrorMsg('Произошла ошибка'))
	}
}
