// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_dropdown__KaUsC {
  z-index: 1;
  position: relative;
  padding: 0;
}

.styles_list__ZZqyO {
  padding: 30px;
  position: absolute;
  list-style-type: none;
  background-color: white;
  border: 1px solid gray;
  border-radius: 15px;
  left: 0;
  top: 3rem;
  display: flex;
  flex-direction: column;
}

.styles_item__M7JWY {
  padding: 6px 24px;
  text-align: center;
  background-color: white;
}

.styles_item__M7JWY:hover {
  transition: all 0.025s;
  background-color: var(--primary);
  color: white;
}

.styles_closeBtn__yhXGF {
  top: 8px;
  right: 8px;
  position: absolute;
}

@media screen and (max-width: 575px) {
  .styles_list__ZZqyO {
    padding: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/SaveResumeButton/styles.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,qBAAqB;EACrB,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;EACnB,OAAO;EACP,SAAS;EACT,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,gCAAgC;EAChC,YAAY;AACd;;AAEA;EACE,QAAQ;EACR,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".dropdown {\n  z-index: 1;\n  position: relative;\n  padding: 0;\n}\n\n.list {\n  padding: 30px;\n  position: absolute;\n  list-style-type: none;\n  background-color: white;\n  border: 1px solid gray;\n  border-radius: 15px;\n  left: 0;\n  top: 3rem;\n  display: flex;\n  flex-direction: column;\n}\n\n.item {\n  padding: 6px 24px;\n  text-align: center;\n  background-color: white;\n}\n\n.item:hover {\n  transition: all 0.025s;\n  background-color: var(--primary);\n  color: white;\n}\n\n.closeBtn {\n  top: 8px;\n  right: 8px;\n  position: absolute;\n}\n\n@media screen and (max-width: 575px) {\n  .list {\n    padding: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `styles_dropdown__KaUsC`,
	"list": `styles_list__ZZqyO`,
	"item": `styles_item__M7JWY`,
	"closeBtn": `styles_closeBtn__yhXGF`
};
export default ___CSS_LOADER_EXPORT___;
