import styles from './styles.module.css'

export const HeaderPage = ({phone, user_mail, user_image}) => {
    return (
        <header className='d-flex justify-content-between aligin-items-center'>
            <img width='30px' height='30px' src={user_image} alt="avatar"/>

            <div className={styles.contacts}>
                <div className='fs-6'>{phone}</div>
                <div className='fs-6'>{user_mail}</div>
            </div>
        </header>
    )
}