import {ReactComponent as MoreLine} from "../../icons/more-line.svg"
import {ReactComponent as DateLine} from "../../icons/date-line.svg"
import {COLORS} from "../../constants"
import {useState} from "react"
import {MeetingInfoModal} from "../Modals"

export default function ProfileMeetingsBlock({meetings}) {
    const [state, setState] = useState({
        meetingInfoModal: {
            shown: false,
            meeting: {}
        }
    })
    return <>
        <MeetingInfoModal meetingInfoModal={state.meetingInfoModal} setState={setState} past={false} />
        {meetings.slice(0,2).map(m => <div key={m.id}
                                className="bg-white rounded-3 w-sm-max px-4 py-3 d-flex flex-column gap-2 light_light_primary"
                                style={{cursor: "pointer"}}
                                onClick={() => setState(p => ({...p, meetingInfoModal: {shown: true, meeting: m}}))}>
            <div className="w-100 d-flex justify-content-between align-items-center">
                <span className="fs-5 fw-semibold">{m.name}</span>
                {/*<span style={{cursor: "pointer"}}><MoreLine/></span>*/}
            </div>
            <div className="d-flex flex-row align-items-center gap-2">
                <DateLine/>
                <span className="fs-6 fw-semibold"
                      style={{color: COLORS.header_text}}>{m.slot && new Date(m.slot.dateTime).toLocaleString().slice(0, -3)}</span>
            </div>
            <button className="w-full text-center py-2 px-3 mt-3 rounded-2 text-white fw-medium"
                    style={{backgroundColor: COLORS.warning}}
                    onClick={(e) => {
                        e.stopPropagation()
                        window.open(m.roomUrl)
                    }}>Подключиться
            </button>
        </div>)}
        {!meetings.length ?
            <span className="w-100 font-medium fs-5 text-center" style={{color: COLORS.header_text}}>Встреч еще не назначено</span> : ""}
    </>
}