import { Nav, Navbar } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { ReactComponent as Arrow } from '../../icons/long-arrow.svg'

export const NavbarToBackSample = ({ children, link_classes }) => {
	const navigate = useNavigate()

	return (
		<Navbar className='w-sm-100 w-sm-max bg-white d-flex flex-row flex-wrap px-4 py-3 rounded-3 mb-sm-4 mb-3'>
			<Nav className='w-100 gap-3 justify-content-center justify-content-md-start'>
				<Nav.Link
					className={
						'd-flex align-items-center text-nowrap rounded-3 px-3 px-md-4 py-2 ' +
						link_classes
					}
					onClick={() => navigate(-1)}
					eventKey='1'
					style={{
						fontSize: '1rem',
						fontWeight: 600,
						outline: 'none',
						gap: '.75rem',
						flex: 1,
						color: '#717683',
					}}
				>
					<Arrow />
					<span className='ps-2'>Назад</span>
				</Nav.Link>
				{children}
				<div style={{ flex: 1 }}></div>
			</Nav>
		</Navbar>
	)
}
