import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { SOURCE } from '../../constants'
const url = SOURCE.url

const initialState = {
	user: {
		loaded: false,
		access_token: Cookies.get('access_token'),
		id: '',
		createdAt: '',
		updatedAt: '',
		email: '',
		login: '',
		contact: '',
		firstName: '',
		middleName: '',
		lastName: '',
		phone: '',
		birthDate: '',
		gender: '',
		city: '',
		country: '',
		aboutMe: '',
		specialty: '',
		nickname: '',
		isReadyToRelocate: false,
		isVisibleToEmployers: false,
		isConfirmedByManager: false,
		isEmailConfirmed: false,
		resumeId: '',
	},
	meetingsData: {
		loaded: false,
		meetings: [],
	},
	cookie_accepted: Cookies.get('cookie_accepted'),
}
export const userSlice = createSlice({
	name: 'user',
	initialState: initialState,
	reducers: {
		setAuth: (state, action) => {
			Cookies.set('access_token', action.payload.access_token, { expires: 365 })
			state.user.access_token = action.payload.access_token
		},
		setUser: (state, action) => {
			state.user = action.payload
		},
		setMeetings: (state, action) => {
			state.meetingsData = {
				loaded: true,
				meetings: action.payload,
			}
		},
		setCookieAcception: (state, action) => {
			Cookies.set('cookie_accepted', action.payload.accepted)
			state.cookie_accepted = action.payload.accepted
		},
		removeGuest: (state, action) => {
			Cookies.remove('guest_access_token')
			state.guest = {
				access_token: undefined,
				email: '',
			}
		},
		clearUser: (state, action) => {
			state = initialState
		},
	},
})

export const { setAuth, setUser, setMeetings, setCookieAcception, clearUser } =
	userSlice.actions

export default userSlice.reducer
