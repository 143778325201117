import {useSelector} from "react-redux"
import ConstructorResumeBlock from "../../../components/ConstructorResumeBlock/ConstructorResumeBlock"
import ConstructorSectionsBlock from "../../../components/ConstructorSectionsBlock/ConstructorSectionsBlock"
import { Loader } from "../../../components/Loader"

export default function ResumePage({ pdfProcessing }) {
    const user = useSelector(state => state.userSlice.user)
    const resume = useSelector(state => state.resumeSlice.resume)

    return !resume.loaded ? <Loader/> : <div className="row gy-sm-3 gy-2">
        <div className="col-xl-3 ">
            <div className="w-sm-max d-flex flex-column p-0 ">
                { pdfProcessing ?
                    <span className="w-100 text-center fs-4">Дождитесь окончания распознавания PDF</span> :
                    <ConstructorSectionsBlock user={user} resume={resume || {}} />
                }
            </div>
        </div>
        <div className="col order-lg-first"
             style={{height: "fit-content"}}>
            <div className="bg-white w-sm-max rounded-3 p-sm-5 p-4 d-flex flex-column  gap-3 gap-sm-4">
                { pdfProcessing ?
                    <span className="w-100 text-center fs-4">Дождитесь окончания распознавания PDF</span> :
                    <ConstructorResumeBlock user={user} resume={resume} />
                }
            </div>
        </div>
    </div>
}