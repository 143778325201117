import {SOURCE} from "../constants"

export const createGuestVacancyResponse = async (vacancyId, file, fileName) => {
    try {
        const formData = new FormData()
        formData.append("file", file, fileName)
        return await fetch(SOURCE.url + "guestVacancyResponses?vacancyId=" + vacancyId, {
            method: "POST",
            body: formData
        })
    } catch (error) {
        console.error(error)
        return null
    }
}

export const createGuestVacancyResponseResumeContact = async (data) => {
    try {
        return await fetch(`${SOURCE.url}guestVacancyResponseResumes/contacts`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
    } catch (error) {
        console.error(error)
        return null
    }
}