import styles from './styles.module.css'

export const ListSkills = ({title_section, listSkills}) => {
    return (
        <div className='row'>
            <h2 className={styles.section}>{title_section}</h2>
            <ul className={styles.list}>
                {listSkills.map((elem, ind) => {
                    return (
                        <li
                            key={ind}
                            className={styles.item_skill}
                        >
                            {elem}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}