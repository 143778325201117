import styles from './styles.module.css'

export const ListLanguages = ({title_section, list_languages}) => {
    return (
        <div className='row'>
            <h2 className={styles.section}>{title_section}</h2>
            <ul className={styles.list}>
                {list_languages.map((elem) => {
                    return (
                        <li
                            key={elem.id}
                            className={styles.item}
                        >
                            {`${elem.name} ${elem?.level}`}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}