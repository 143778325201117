export const AppCheckbox = ({label, onChange = () => {}, className = '', checked, disabled=false, size = '20px', fs = 'fs-6'}) => {
    return <div className={'d-flex flex-row flex-nowrap align-items-center ' + className}>
        <div className='d-inline-block me-sm-4 me-3 my-auto'>
            <input className="form-check-input m-0 my-auto"
                   style={{width: size, height: size, borderRadius: '5px', boxShadow: 'none', cursor: 'pointer'}}
                   type="checkbox" checked={checked} disabled={disabled}
                   onChange={e => onChange(e)}/>
        </div>
        <p className={"text-wrap " + fs} style={{cursor: 'pointer'}}>{label}</p>
    </div>
}