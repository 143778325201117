// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppSwitch_switch__CLesB {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.AppSwitch_switch__CLesB input {
  opacity: 0;
  width: 0;
  height: 0;
}

.AppSwitch_slider__-YOPp {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.2s;
  border-radius: 20px;
}

.AppSwitch_slider__-YOPp:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.2s;
  border-radius: 50%;
}

input:checked + .AppSwitch_slider__-YOPp {
  background-color: var(--primary);
}

input:focus + .AppSwitch_slider__-YOPp {
  box-shadow: 0 0 1px var(--primary);
}

input:checked + .AppSwitch_slider__-YOPp:before {
  transform: translateX(20px);
}`, "",{"version":3,"sources":["webpack://./src/components/ui/AppSwitch/AppSwitch.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,UAAA;EACA,QAAA;EACA,SAAA;AACF;;AAEA;EACE,kBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,sBAAA;EAEA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,uBAAA;EAEA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,gCAAA;AACF;;AAEA;EACE,kCAAA;AACF;;AAEA;EAGE,2BAAA;AACF","sourcesContent":[".switch {\n  position: relative;\n  display: inline-block;\n  width: 40px;\n  height: 20px;\n}\n\n.switch input {\n  opacity: 0;\n  width: 0;\n  height: 0;\n}\n\n.slider {\n  position: absolute;\n  cursor: pointer;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: #ccc;\n  -webkit-transition: .2s;\n  transition: .2s;\n  border-radius: 20px;\n}\n\n.slider:before {\n  position: absolute;\n  content: \"\";\n  height: 14px;\n  width: 14px;\n  left: 3px;\n  bottom: 3px;\n  background-color: white;\n  -webkit-transition: .2s;\n  transition: .2s;\n  border-radius: 50%;\n}\n\ninput:checked + .slider {\n  background-color: var(--primary);\n}\n\ninput:focus + .slider {\n  box-shadow: 0 0 1px var(--primary);\n}\n\ninput:checked + .slider:before {\n  -webkit-transform: translateX(20px);\n  -ms-transform: translateX(20px);\n  transform: translateX(20px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": `AppSwitch_switch__CLesB`,
	"slider": `AppSwitch_slider__-YOPp`
};
export default ___CSS_LOADER_EXPORT___;
