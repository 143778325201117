export const DefaultAvatar = ({ src = '', type = 'b2c', ...props }) => {
  return (
    <object data={src} type='image/png' {...props}>
      <img
        alt='avatar'
        style={{ ...props.styles, objectFit: 'cover', flexShrink: '0' }}
        src={require(`../../icons/default-avatar-${type}.svg`)}
        {...props}
      />
    </object>
  )
}
