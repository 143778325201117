import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import {
    sendVerificationCode,
    verifyEmail,
} from '../../../api/emailVerifications'
import { COLORS, SOURCE } from '../../../constants'
import { showErrorMsg, showSuccessMsg } from '../../../store/slices/alertSlice'

const url = SOURCE.url
export const ConfirmMail = () => {
	const [state, setState] = useState({
		code: '',
		disabled: false,
	})
	const user = useSelector(state => state.userSlice.user)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		if (state.disabled)
			setTimeout(() => {
				setState(p => ({ ...p, disabled: false }))
			}, 30000)
	}, [state.disabled])

	useEffect(() => {
		getCode(user.access_token, setState, dispatch)
	}, [])

	return (
		<form
			onSubmit={e => e.preventDefault()}
			className='container d-flex flex-column justify-content-center w-100 my-auto'
			style={{ maxWidth: '900px' }}
		>
			<p className='text-center mb-3 fs-1' style={{ fontWeight: '400' }}>
				А ты не робот?
			</p>
			<p className='text-center mb-5 fs-5' style={{ fontWeight: '200' }}>
				Мы отправим тебе на почту код подтверждения, скорее вводи его здесь
			</p>
			<SeparateCodeInput
				isLoading={state.isLoading}
				callback={code =>
					submitConfirmCode(
						user.access_token,
						code,
						setState,
						navigate,
						dispatch
					)
				}
			/>

			<div className='container' style={{ maxWidth: '600px' }}>
				<div className='d-flex justify-content-between align-items-center mb-4 flex-wrap'>
					<button
						onClick={() => getCode(user.access_token, setState, dispatch)}
						style={{
							backgroundColor: COLORS.secondary,
							color: COLORS.primary,
							borderWidth: 0,
							maxWidth: '250px',
						}}
						className='btn btn-md py-2 w-100 text-nowrap mx-auto'
						disabled={state.disabled}
						title={
							state.disabled
								? 'Следующий код можно запросить через 30 секунд'
								: ''
						}
					>
						Отправить код
					</button>
				</div>
				<div className='row justify-content-center mx-auto mb-2'>
					<hr />
					или
				</div>
				<div className='d-flex justify-content-center mb-2'>
					<button
						style={{ minWidth: '200px', color: COLORS.primary }}
						onClick={() => {
							navigate('/auth/next', { state: { step: 'confirm_mail' } })
						}}
						className='col-5 btn btn-link btn-md p-1 text-decoration-none text-nowrap'
					>
						Подтвердить позже
					</button>
				</div>
			</div>
		</form>
	)
}

export const SeparateCodeInput = ({ isLoading, callback }) => {
	const [code, setCode] = useState('')
	const CODE_LENGTH = 6
	// Refs to control each digit input element
	const inputRefs = [
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
	]

	// Reset all inputs and clear state
	const resetCode = () => {
		inputRefs.forEach(ref => {
			ref.current.value = ''
		})
		inputRefs[0].current.focus()
		setCode('')
	}

	// Call our callback when code = 6 chars
	useEffect(() => {
		if (code.length === CODE_LENGTH) {
			if (typeof callback === 'function') callback(code)
			resetCode()
		}
	}, [code]) //eslint-disable-line

	function handleInput(e, index) {
		const input = e.target
		const previousInput = inputRefs[index - 1]
		const nextInput = inputRefs[index + 1]
		if (!/^[0-9]\d*$/.test(input.value) && input.value !== '') return
		// Update code state with single digit
		const newCode = [...code]
		newCode[index] = input.value
		setCode(newCode.join(''))

		input.select()

		if (input.value === '') {
			// If the value is deleted, select previous input, if exists
			if (previousInput) {
				previousInput.current.focus()
			}
		} else if (nextInput) {
			// Select next input on entry, if exists
			nextInput.current.select()
		}
	}

	// Select the contents on focus
	function handleFocus(e) {
		e.target.select()
	}

	// Handle backspace key
	function handleKeyDown(e, index) {
		const input = e.target
		const previousInput = inputRefs[index - 1]
		const nextInput = inputRefs[index + 1]

		if ((e.keyCode === 8 || e.keyCode === 46) && input.value === '') {
			e.preventDefault()
			setCode(prevCode => prevCode.slice(0, index) + prevCode.slice(index + 1))
			if (previousInput) {
				previousInput.current.focus()
			}
		}
	}

	// Capture pasted characters
	const handlePaste = e => {
		const pastedCode = e.clipboardData.getData('text')
		if (pastedCode.length === CODE_LENGTH) {
			setCode(pastedCode)
			inputRefs.forEach((inputRef, index) => {
				inputRef.current.value = pastedCode.charAt(index)
			})
		}
	}
	return (
		<div className='mx-auto d-flex justify-content-center mt-5 mb-4'>
			{[...Array(CODE_LENGTH).keys()].map(index => (
				<>
					<input
						className='form-control fs-4 px-2 text-center mx-1 mx-sm-2 rounded-2'
						key={index}
						style={{ color: COLORS.primary, maxWidth: '40px' }}
						type='text'
						inputmode='numeric'
						maxLength={1}
						value={code[index] || ''}
						onChange={e => handleInput(e, index)}
						ref={inputRefs[index]}
						autoFocus={index === 0}
						onFocus={handleFocus}
						onKeyDown={e => handleKeyDown(e, index)}
						onPaste={handlePaste}
						disabled={isLoading}
					/>
					{index === 2 && <p className='fs-2'>-</p>}
				</>
			))}
		</div>
	)
}

export async function getCode(access_token, setState, dispatch) {
	const res = await sendVerificationCode(access_token)
	if (!res || !res.ok) dispatch(showErrorMsg('Ошибка на сервере!'))
	else dispatch(showSuccessMsg('Код отправлен'))
	setState(prev => ({
		...prev,
		disabled: true,
	}))
}

export async function submitConfirmCode(
	access_token,
	code,
	setState,
	navigate,
	dispatch
) {
	setState(p => ({ ...p, isLoading: true }))
	if (code.indexOf('-') < 0) {
		const res = await verifyEmail(access_token, code)
		setState(p => ({ ...p, isLoading: false }))
		if (!res || !res.ok) return dispatch(showErrorMsg('Неверный код!'))
		dispatch(showSuccessMsg('Почта успешно подтверждена'))
		navigate('/auth/next', { state: { step: 'confirm_mail' } })
	}
}
