import { SOURCE } from '../constants'
import * as yup from 'yup'
import { setResume } from '../store/slices/resumeSlice'
import {
  createResumeCertificate,
  createResumeCertificateForPDF,
  createResumeContact,
  createResumeContactForPDF,
  createResumeEducation,
  createResumeEducationForPDF,
  createResumeExperience,
  createResumeExperienceForPDF,
  createResumeLanguage,
  createResumeLanguageForPDF,
  createResumeLanguageForResume,
  patchResume,
  patchResumeCertificate,
  patchResumeContact,
  patchResumeLanguage,
} from './resume'
import Cookie from 'js-cookie'

export const BasicApplicantSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, 'Имя от 2-х букв')
    .max(50, 'Имя до 50-ти букв'),
  lastName: yup
    .string()
    .trim()
    .min(2, 'Фамилия от 2-х букв')
    .max(50, 'Фамилия до 50-ти букв'),
  middleName: yup.string().trim().max(50, 'Отчество до 50-ти букв').nullable(),
  email: yup
    .string()
    .email()
    .min(3, 'email от 3 символов')
    .max(255, 'email до 255 символов'),
  login: yup
    .string()
    .trim()
    .min(3, 'логин от 3 символов')
    .max(255, 'логин до 255 символов'),
  contact: yup
    .string()
    .trim()
    .min(1, 'контакт обязателен')
    .max(36, 'контакт до 36 символов'),
  phone: yup
    .string()
    .trim()
    .min(2, 'телефон от 3 символов')
    .max(15, 'телефон до 15 символов')
    .nullable(),
  birthDate: yup
    .date()
    .min(new Date(1900, 0), 'неверная дата рождения')
    .max(new Date().getFullYear() - 13, 'неверная дата рождения')
    .nullable(),
  gender: yup.string().nullable(),
  city: yup
    .string()
    .trim()
    .min(3, 'имя от 3 символов')
    .max(255, 'город до 255 символов')
    .nullable(),
  country: yup
    .string()
    .trim()
    .min(3, 'имя от 3 символов')
    .max(62, 'страна до 62 символов')
    .nullable(),
  aboutMe: yup
    .string()
    .trim()
    .min(2, 'имя от 2 символов')
    .max(3000, 'история до 3000 символов')
    .nullable(),
  nickname: yup
    .string()
    .trim()
    .min(2, 'имя от 2 символов')
    .max(50, 'никнейм до 50 символов')
    .nullable(),
  isReadyToRelocate: yup.boolean().nullable(),
  isVisibleToEmployers: yup.boolean(),
  isConfirmedByManager: yup.boolean(),
})

export const getMyAccount = async (access_token, include = []) => {
  try {
    let query = '?'
    if (include.length) query += include.map((i) => 'include=' + i).join('&')
    return await fetch(SOURCE.url + 'applicants/me' + query, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: access_token,
      },
    })
  } catch (error) {
    console.error(error)
    return null
  }
}

export const deleteMyAccount = async (access_token) => {
  try {
    const res = await fetch(SOURCE.url + 'applicants/me', {
      method: 'DELETE',
      headers: {
        Authorization: access_token,
      },
    })
    return res.ok
  } catch (error) {
    console.error(error)
    return false
  }
}

export const getMyStatus = async (access_token, id) => {
  try {
    const res = await fetch(SOURCE.url + 'applicants/' + id + '/status', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: access_token,
      },
    })
    if (!res.ok) return false
    return await res.json()
  } catch (error) {
    console.error(error)
    return false
  }
}

export const patchMyAccount = async (patchUser, access_token, dispatch) => {
  await BasicApplicantSchema.validate(patchUser)

  const res = await fetch(SOURCE.url + 'applicants/me', {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: access_token,
    },
    body: JSON.stringify(patchUser),
  })
  return res.json()
}

export const updateUserResume = async (access_token, dispatch) => {
  const res = await fetch(
    SOURCE.url +
      `resumes/my?${new URLSearchParams(
        '?include=certificates&include=contacts&include=education&include=experience&include=languages'
      ).toString()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: access_token,
      },
    }
  )
  if (!res.ok) return dispatch(setResume({ loaded: true }))
  const data = await res.json()
  data.experience = (data.experience || []).map((ex) => ({
    ...ex,
    startMonth: ex.startMonth
      ? ex.startMonth.toString().padStart(2, '0')
      : null,
    endMonth: ex.endMonth ? ex.endMonth.toString().padStart(2, '0') : null,
  }))
  if (data.desiredSalary)
    data.desiredSalary = data.desiredSalary.toLocaleString()
  data.loaded = true
  await dispatch(setResume(data))
}

export const getUserPublicProfile = async (nickname) => {
  try {
    const user_res = await fetch(
      `${SOURCE.url}applicants/${nickname}?uniqueFieldType=nickname&include=resume&include=aiChats`
    )
    if (!user_res.ok) return false
    const user = await user_res.json()
    if (user.error || !user.resume) return false
    const resume_res = await fetch(
      `${SOURCE.url}resumes/${user.resume.id}?include=certificates&include=contacts&include=education&include=experience&include=languages`
    )
    const resume = await resume_res.json()
    if (resume.error) return false
    resume.experience = (resume.experience || []).map((ex) => ({
      ...ex,
      startMonth: ex.startMonth
        ? ex.startMonth.toString().padStart(2, '0')
        : null,
      endMonth: ex.endMonth ? ex.endMonth.toString().padStart(2, '0') : null,
    }))
    return { user, resume }
  } catch (error) {
    console.error(error)
    return false
  }
}

export const isProcessingPDF = async (dispatch, access_token) => {
  try {
    const pdfJobId = localStorage.getItem('pdfJobId')
    console.log('pdfJobId', pdfJobId)
    if (!pdfJobId) return false
    const res = await fetch(
      `${SOURCE.url}resumeOcr/queuedImportWithOcr/${pdfJobId}`
    )
    if (!res.ok) return false
    const data = await res.json()
    console.log('isProcessingPDF RESULT', data)
    if (data.status === 'SUCCESS') {
      localStorage.removeItem('pdfJobId')
      dispatch(setResume(data.data.mappedResume))

      const resumeID = Cookie.get('resumeID')

      // updating resume one by one

      const acc = Cookie.get('access_token')

      console.log('isProcessing ACCESS_TOKEN', acc)

      // language
      data.data.mappedResume?.languages?.map((el) => {
        createResumeLanguageForPDF(acc, {
          name: el.name || '',
          level: el.level || '',
          resumeId: resumeID,
        })
      })

      // certificate
      data.data.mappedResume?.certificates?.map((el) => {
        createResumeCertificateForPDF(acc, {
          name: el.name || '',
          description: el.description || '',
          resumeId: resumeID,
          year: el.year,
        })
      })

      // contacts
      data.data.mappedResume?.contacts?.map((el) => {
        createResumeContactForPDF(acc, {
          name: el.name || '',
          type: el.type || '',
          value: el.value || '',
          preferred: el.preferred || false,
          resumeId: resumeID,
        })
      })

      const dateNow = new Date()
      // education
      data.data.mappedResume?.education?.map((el) => {
        createResumeEducationForPDF(acc, {
          name: el.name,
          description: el.description,
          resumeId: resumeID,
          degree: el.degree,
          startYear: el.startYear || dateNow.getFullYear(),
          endYear: el.endYear || dateNow.getFullYear(),
        })
      })

      // experience
      data.data.mappedResume?.experience?.map((el) => {
        createResumeExperienceForPDF(acc, {
          description: el.description,
          resumeId: resumeID,
          startYear: el.startYear || dateNow.getFullYear(),
          endYear: el.endYear || dateNow.getFullYear(),
          company: el.company,
          position: el.position,
          startMonth: el.startMonth || dateNow.getMonth(),
          endMonth: el.endMonth || dateNow.getMonth(),
        })
      })

      // left resume
      patchResume(acc, resumeID, {
        city: data.data.mappedResume?.city || '',
        isVisibleToEmployers: false,
        title: data.data.mappedResume?.title || '',
        summary: data.data.mappedResume?.summary || '',
        skills: data.data.mappedResume?.skills || [],
        desiredSalary: data.data.mappedResume?.desiredSalary,
        desiredSalaryCurrency: data.data.mappedResume?.desiredSalaryCurrency,
      })

      Cookie.remove('resumeID')
      return false
    }
    return data.status === 'PROCESSING'
  } catch (error) {
    console.error(error)
    return false
  }
}

export const pollingPDFJob = async (
  access_token,
  dispatch,
  state,
  setState
) => {
  let intervalId
  const intervalHandler = async () => {
    console.log()
    if (!(await isProcessingPDF(dispatch))) {
      localStorage.removeItem('pdfJobId')
      clearInterval(intervalId)
      setState((p) => ({ ...p, pdfProcessing: false }))
      console.log('pollingPDFJob state', state)
    }
  }
  intervalId = setInterval(intervalHandler, 3000)

  return () => clearInterval(intervalId)
}
