import ProfileResumeBlock from "../../components/ProfileResumeBlock/ProfileResumeBlock"
import {useEffect, useState} from "react"
import {Loader} from "../../components/Loader"
import {useDispatch, useSelector} from "react-redux"
import {useNavigate, useParams} from "react-router"
import {showErrorMsg} from "../../store/slices/alertSlice"
import {COLORS, SOURCE} from "../../constants"
import {getUserPublicProfile} from "../../api/applicant"

export const PublicProfile = () => {
    const { nickname } = useParams()
    const [state, setState] = useState({
        user: {},
        resume: {},
        loaded: false
    })
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if (nickname) getUserPublicProfile(nickname).then(result => {
            if (result) setState({user: result.user, resume: result.resume, loaded: true})
            else {
                dispatch(showErrorMsg("Пользователь с данными никнеймом не найден"))
                navigate("/")
            }
        })
    }, [])

    return !state.loaded ? <Loader /> : <>
        <div className="position-fixed top-0 start-0 w-100 h-100 min-vw-100 min-vh-100" style={{backgroundColor: COLORS.mainBg, zIndex: -1}}></div>
        <div className="w-100 mx-auto pb-4 pb-sm-5" style={{maxWidth: '1000px'}}>
            <div className="bg-white rounded-3 p-sm-5 p-4 w-sm-max d-flex flex-column gap-3 gap-sm-4">
                <ProfileResumeBlock resume={state.resume} user={state.user} showPublishBtn={false}
                                    avatarUrl={`${SOURCE.url}applicants/${state.user.id}/avatar`} />
            </div>
        </div>
    </>
}