import { useState } from 'react'
import { AppInput } from '../../components/ui/AppInput'
import { COLORS, PADDINGS, SOURCE } from '../../constants'
import { ReactComponent as ArrowDownRightSvg } from '../../icons/consultations_landing/arrow_down_right.svg'
import { ReactComponent as JackdawSvg } from '../../icons/startups_landing/jackdaw.svg'
import { ReactComponent as JackdawBlackSvg } from '../../icons/startups_landing/jackdaw_black.svg'
import './styles.css'

export const Startups = () => {
	const [state, setState] = useState({
		email: '',
	})

	return (
		<div className='pt-3'>
			<div className='row mb-5'>
				<div className='col-xl-7 col-xxl-9'>
					<p className='fs-1 my-4 py-2 fw-bold'>
						Нанимай бесплатно вместе с нами
					</p>
					<div className='row'>
						<div className='col-md-7 d-flex flex-column'>
							<div className='purple rounded-5 p-md-5 p-4 mb-3 order-xl-1 order-2'>
								<p className='fs-5' style={{ color: 'white' }}>
									Помогаем стартапам бесплатно найти своих лучших сотрудников.{' '}
									<br />
									<span className='fw-bold'>Абсолютно бесплатно</span>
								</p>
							</div>
							<div
								style={{
									background: 'linear-gradient(0.25turn, #4EA1ED, #5AEBFF)',
								}}
								className='rounded-5 mb-3 order-xl-2 order-1 px-5'
							>
								<img
									style={{ marginTop: '-40px', width: '100%', height: 'auto' }}
									className='w-100'
									src={require('../../icons/startups_landing/laptop.png')}
								/>
							</div>
						</div>
						<div className='col mb-3'>
							<div className='d-flex flex-column gray rounded-5 p-4 h-100'>
								<p className='fs-5 mb-3' style={{ fontWeight: '600' }}>
									Возник вопрос?
								</p>
								<p className='fs-6'>
									Тогда свяжись с нами и мы <br /> постараемся его решить!
								</p>
								<ArrowDownRightSvg
									onClick={() => window.open('https://vk.com/hurtleru')}
									cursor='pointer'
									fill='black'
									height='35px'
									className='mt-auto ms-auto'
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='col d-flex flex-column mb-3'>
					<div
						className='yellow px-5 py-xl-5 py-4'
						style={{
							borderTopRightRadius: '35px',
							borderBottomLeftRadius: '35px',
							borderTopLeftRadius: '35px',
						}}
					>
						<button className='btn py-2 black w-100 rounded-pill'>Хочу!</button>
					</div>
					<div
						className='yellow flex-grow-1 p-4 pb-5'
						style={{
							borderBottomRightRadius: '35px',
							borderBottomLeftRadius: '35px',
							borderTopLeftRadius: '35px',
						}}
					>
						<p
							className='fs-5 mb-5'
							style={{ fontWeight: '600', color: COLORS.black }}
						>
							Будьте уверены в качестве кандидата:
						</p>
						{[
							'Запись собеседования',
							'Полное резюме и профиль\n кандидата',
							'Карта навыков',
							'Рекомендации от наших\n менеджеров',
						].map(t => (
							<Statement color='black' title={t} />
						))}
					</div>
				</div>
			</div>
			<div className={'black rounded-5 py-5 mt-5 w-max' + PADDINGS}>
				<div className={'mx-auto w-max-inner'}>
					<div className='row mb-4' style={{ color: 'white' }}>
						<div className='col-xl-6 d-flex flex-column'>
							<div className='mx-4 mx-sm-0'>
								<p className='fs-1 fw-bold'>Инновационный подход</p>
								<p className='fs-4 mb-5'>
									Хартл – это цифровая платформа для быстрого найма в команды.{' '}
								</p>
							</div>
							<div className='pt-sm-5' style={{ maxWidth: '500px' }}>
								{[
									'Мы помогаем кандидатам найти свое идеальное место работы, а компаниям – быстро закрывать вакансии',
									'Сами проводим собеседование с кандидатом, где проверяем его hard skills',
									'Составляем карту «мягких» навыков',
									'После трудоустройства мы дарим сотруднику 3 бесплатные консультации с психологом ',
								].map(t => (
									<Statement fs='fs-4' margin='mb-4' title={t} />
								))}
							</div>
						</div>
						<div className='col'>
							<div
								className='skyblue d-flex rounded-5 mb-3'
								style={{ overflow: 'hidden', width: '100%' }}
							>
								<img
									className='ms-auto'
									src={require('../../icons/startups_landing/bubble_cube.png')}
								/>
							</div>
							<div
								className='yellow d-flex rounded-5 d-none d-xl-block'
								style={{}}
							>
								<img
									className='ms-auto'
									style={{ marginBottom: '-10px' }}
									src={require('../../icons/startups_landing/glassy_pie.png')}
								/>
							</div>
						</div>
					</div>
					<div
						className='gray rounded-5 py-4'
						style={{ marginBottom: '100px' }}
					>
						<p className='fs-1 fw-bold text-center'>
							Хартл – быстрорастущая HR-tech компания
						</p>
						<div
							className='d-flex flex-row flex-wrap mx-auto'
							style={{ maxWidth: '900px' }}
						>
							<img
								onClick={() =>
									window.open(
										'https://trends.rbc.ru/trends/education/64d0cfb49a79477a524466f4'
									)
								}
								alt=''
								src={SOURCE.source_url + 'rbc.png'}
								style={{ cursor: 'pointer' }}
								className='mx-auto'
							/>
							<img
								onClick={() =>
									window.open(
										'https://vc.ru/tribuna/673228-hartl-cifrovoe-kadrovoe-agentstvo-kotoroe-proveryaet-aytishnikov?ysclid=lnixkc3xgg509999511'
									)
								}
								alt=''
								style={{ cursor: 'pointer' }}
								src={SOURCE.source_url + 'vcru.png'}
								className='mx-auto'
							/>
							<img
								onClick={() =>
									window.open(
										'https://developers.sber.ru/portal/cases/hurtle?ysclid=lni5vypg4m821047307'
									)
								}
								alt=''
								style={{ cursor: 'pointer' }}
								src={SOURCE.source_url + 'sber_devices.png'}
								className='mx-auto'
							/>
						</div>
					</div>
				</div>
			</div>
			<div
				className={'w-max purple rounded-5 py-xl-5 py-4 pb-5' + PADDINGS}
				style={{ marginTop: '-70px' }}
			>
				<div className='d-flex flex-column mx-auto mb-5 w-max-inner'>
					<p
						className='fs-1 ms-auto fw-bold mb-sm-4 mt-sm-0 my-3'
						style={{ color: 'white' }}
					>
						Как это работает?
					</p>
					<p className='fs-3 mb-4' style={{ color: 'white' }}>
						Условия сотрудничества
					</p>
					<div className='row' style={{ marginBottom: '40px' }}>
						<div className='col-md-7 mb-4'>
							<div className='rounded-5 gray p-sm-5 p-4 h-100'>
								<p className='fs-4'>
									Бесплатный доступ к найму на нашей платформе
									<br /> доступен только для вакансий
									<br /> с зарплатой до 45 тыс. руб.
								</p>
							</div>
						</div>
						<div className='col mb-sm-4'>
							<div className='rounded-5 yellow p-sm-5 p-4'>
								<p className='fs-4'>
									Больше никаких ограничений, просто берете и ищете себе
									сотрудников
									<span style={{ color: COLORS.primary }}> бесплатно</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className={'w-max gray p-xl-5 p-4' + PADDINGS}
				style={{
					marginTop: '-70px',
					borderTopRightRadius: '35px',
					borderTopLeftRadius: '35px',
				}}
			>
				<div className='d-flex flex-column mx-auto mt-5 w-max-inner'>
					<div className='row'>
						<div className='col-md'>
							<p className='fs-1 ms-auto fw-bold mb-4'>
								Заинтересовало?
								<br />
								Давайте сотрудничать!
							</p>
							<img
								src={require('../../icons/startups_landing/saturn.png')}
								className='ms-auto d-block d-md-none'
								style={{ overflow: 'hidden', width: '100%', height: '300px' }}
							/>
							<div className='d-flex flex-column mt-4'>
								<p className='fs-4'>Почта</p>
								<AppInput
									placeholder='somebodysemail@gmail.com'
									label='Почта'
									value={state.email}
									onChange={v => setState(p => ({ ...p, email: v }))}
								/>
								<button className='btn black rounded-5 py-2 px-4 ms-auto ms-sm-0 me-sm-auto me-0 ms-auto mt-4'>
									Отправить
								</button>
							</div>
						</div>
						<div className='col d-md-flex d-none d-flex'>
							<img
								src={require('../../icons/startups_landing/saturn.png')}
								className='ms-auto'
								style={{ maxWidth: '700px', width: '100%', height: 'auto' }}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export const Statement = ({
	title,
	color = 'white',
	margin = 'mb-3',
	fs = 'fs-6',
}) => {
	return (
		<div className={'row ' + margin}>
			<div className='col-auto'>
				{color === 'white' ? (
					<JackdawSvg height='25px' />
				) : (
					<JackdawBlackSvg height='25px' />
				)}
			</div>
			<div className='col'>
				<p className={fs} style={{ color: color }}>
					{title}
				</p>
			</div>
		</div>
	)
}
