// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_contacts__jx-HY {
    font-weight: 400;
    font-size: 0.625rem;
    color: #777777;
}

.styles_initial__UqcN6 {
    margin: 0;
    padding: 0;
    font-size: 9px;
    font-weight: 400;
    color: white;
}

.styles_wrapper__jzg\\+m {
    margin-bottom: 15px;
}

.styles_section__Gif23 {
    font-weight: 500;
    font-size: 1rem;
    color: #A1A1A1;
}

.styles_company__Us2Ha {
    font-weight: 500;
    color: black;
    font-size: 0.93rem;
    line-height: 1.31rem;
}

.styles_job__j2yRa {
    font-size: 0.93rem;
    color: #777777;
}

.styles_description__JzESW {
    font-size: 0.93rem;
    color: black;
}


.styles_list__Jwhl2 {
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
}


.styles_item__kjiRs, .styles_item_skill__GZ25X {
    font-size: 12px;
    padding: 5px;
    font-weight: 400;
    background-color: #E6EAED;
    color: black;
}

.styles_item_skill__GZ25X {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PdfResumePages/pageOne/styles.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,SAAS;IACT,UAAU;IACV,cAAc;IACd,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;;AAGA;IACI,qBAAqB;IACrB,aAAa;IACb,2BAA2B;IAC3B,eAAe;IACf,aAAa;IACb,gBAAgB;AACpB;;;AAGA;IACI,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;AACjB","sourcesContent":[".contacts {\n    font-weight: 400;\n    font-size: 0.625rem;\n    color: #777777;\n}\n\n.initial {\n    margin: 0;\n    padding: 0;\n    font-size: 9px;\n    font-weight: 400;\n    color: white;\n}\n\n.wrapper {\n    margin-bottom: 15px;\n}\n\n.section {\n    font-weight: 500;\n    font-size: 1rem;\n    color: #A1A1A1;\n}\n\n.company {\n    font-weight: 500;\n    color: black;\n    font-size: 0.93rem;\n    line-height: 1.31rem;\n}\n\n.job {\n    font-size: 0.93rem;\n    color: #777777;\n}\n\n.description {\n    font-size: 0.93rem;\n    color: black;\n}\n\n\n.list {\n    list-style-type: none;\n    display: flex;\n    justify-content: flex-start;\n    flex-wrap: wrap;\n    row-gap: 10px;\n    column-gap: 10px;\n}\n\n\n.item, .item_skill {\n    font-size: 12px;\n    padding: 5px;\n    font-weight: 400;\n    background-color: #E6EAED;\n    color: black;\n}\n\n.item_skill {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    text-align: center;\n    padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contacts": `styles_contacts__jx-HY`,
	"initial": `styles_initial__UqcN6`,
	"wrapper": `styles_wrapper__jzg+m`,
	"section": `styles_section__Gif23`,
	"company": `styles_company__Us2Ha`,
	"job": `styles_job__j2yRa`,
	"description": `styles_description__JzESW`,
	"list": `styles_list__Jwhl2`,
	"item": `styles_item__kjiRs`,
	"item_skill": `styles_item_skill__GZ25X`
};
export default ___CSS_LOADER_EXPORT___;
