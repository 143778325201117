import {ReactComponent as BaselinePlace} from "../../icons/baseline-place.svg"
import {ReactComponent as TwoCoins} from "../../icons/two-coins.svg"
import {COLORS, currencySymbols, labelsBySalaryCurrency} from "../../constants"
import {useState} from "react"
import {OfferInfoModal} from "../Modals"
import {Link} from "react-router-dom"
import {changeOfferStatus} from "../../api/offers"
import {useSelector} from "react-redux"

export default function ProfileOffersBlock({ offers, setParentState }) {
    const [state, setState] = useState({
        offerInfoModal: {
            shown: false,
            offer: {}
        }
    })
    const access_token = useSelector(state => state.userSlice.user.access_token)
    const makeDeclineHandler = (id) => async () => {
        const res = await changeOfferStatus(access_token, id, "DECLINED")
        if (res !== null) setParentState(p => ({...p, offers: p.offers.map(o => o.id === id ? {...o, status: "DECLINED"} : o)}))
    }
    return <>
        <OfferInfoModal offerInfoModal={state.offerInfoModal} setState={setState} />
        { offers.slice(0,2).map(o => {
            let workplaceStr
            switch (o.vacancy.workplaceModel) {
                case "OFFICE":
                    workplaceStr = "Офис в г. " + o.vacancy.city
                    break
                case "REMOTE":
                    workplaceStr = "Удаленка"
                    break
                case "HYBRID":
                    workplaceStr = o.vacancy.city + " + удаленка"
            }
            return <div key={o.id} className="bg-white w-sm-max rounded-3 w-100 px-4 py-3 d-flex flex-column gap-2">
                <span className="fs-5 fw-semibold">{o.vacancy.employer && o.vacancy.employer.name}</span>
                <div className="d-flex flex-row align-items-center gap-2">
                    <BaselinePlace />
                    <span className="fs-6 fw-semibold" style={{color: COLORS.header_text}}>{workplaceStr}</span>
                </div>
                <div className="d-flex flex-row align-items-center gap-2">
                    <TwoCoins />
                    <span className="fs-6 fw-semibold" style={{color: COLORS.header_text}}>{o.salary.toLocaleString() || o.vacancy.salary.toLocaleString()} {labelsBySalaryCurrency[o.salaryCurrency || o.vacancy.salaryCurrency]}</span>
                </div>
                <div className="row m-0 mt-2 gap-2 gap-sm-4 gap-lg-2 gap-xl-4">
                    <button className="col btn success" disabled={o.status !== "PENDING"}
                            onClick={() => setState(p => ({...p, offerInfoModal: {shown: true, offer: o}}))}>Рассмотреть</button>
                    <button className="col btn danger" disabled={o.status !== "PENDING"}
                            onClick={makeDeclineHandler(o.id)}>Отказать</button>
                </div>
            </div>
        }) }
        { (offers.length || 0) > 2 ? <Link to="offers" className="text-black text-center fs-5">Еще {offers.length - 2} приглашений</Link> : "" }
        { !offers.length ? <span className="w-100 font-medium fs-5 text-center" style={{color: COLORS.header_text}}>Пока нет предложений</span> : "" }
    </>
}