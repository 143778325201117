import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { SOURCE, meetingTypes } from '../../constants'
import { showErrorMsg } from './alertSlice'
const url = SOURCE.url

export const createGuestAsync = createAsyncThunk(
  'GET',

  async (email, { rejectWithValue, getState, dispatch }) => {
    const response = await fetch(`${SOURCE.url}auth/guest/accessToken`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
      }),
    })
    if (!response.ok) {
      return dispatch(showErrorMsg('Произошла ошибка на сервере'))
    }
    const data = await response.json()
    return { ...data, email: email }
  }
)

export const bookSlotAsync = createAsyncThunk(
  'POST',

  async (props, { rejectWithValue, getState, dispatch }) => {
    const state = getState()
    const token = state.guestSlice.guest.access_token
    const response = await fetch(SOURCE.url + 'meetingPayments', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({
        type: props.type,
        slotId: props.slotId,
        appliedPromoCodeValue: props.promo
      }),
    })
    if (response.status === 409)
      return dispatch(
        showErrorMsg('Данный слот занят или в процессе бронирования')
      )
    if (!response.ok)
      return dispatch(showErrorMsg('Произошла ошибка на сервере'))
    const data = await response.json()
    return data
  }
)

export const createMeetingAsync = createAsyncThunk(
  'POST',

  async ({ type, slotId, code }, { rejectWithValue, getState, dispatch }) => {
    const state = getState()
    const token = state.guestSlice.guest.access_token
    const response = await fetch(SOURCE.url + 'meetings', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({
        type: type,
        slotId: slotId,
        name: meetingTypes[type],
        _requester: 'GUEST',
        successCode: code,
      }),
    })
    const data = await response.json()
    if (!response.ok) return { status: response.status, ...data }
    Cookies.set('meeting_url', data.roomUrl, { expires: 365 })
    dispatch(addMeeting(data))
    return data
  }
)

const initialState = {
  guest: {
    access_token: Cookies.get('guest_access_token'),
    email: '',
    loaded: false,
  },
  payment: {
    loaded: false,
  },
  meeting: {
    loaded: false,
  },
  loading: false,
}
export const guestSlice = createSlice({
  name: 'guest',
  initialState: initialState,
  reducers: {
    addMeeting: (state, action) => {
      state.meeting = {
        ...action.payload,
        loaded: true,
      }
    },
    removeGuest: (state, action) => {
      Cookies.remove('guest_access_token')
      state = initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createGuestAsync.pending, (state, action) => {
        state.loading = true
      })
      .addCase(createGuestAsync.fulfilled, (state, action) => {
        if (action.payload.token) {
          Cookies.set('guest_access_token', action.payload.token, {
            expires: 365,
          })
          state.guest = {
            access_token: action.payload.token,
            email: action.payload.email,
            loaded: true,
          }
        }
        state.loading = false
      })
      .addCase(bookSlotAsync.pending, (state, action) => {
        state.loading = true
      })
      .addCase(bookSlotAsync.fulfilled, (state, action) => {
        if (action.payload.id) {
          state.payment = {
            ...action.payload,
            loaded: true,
          }
        }
        state.loading = false
      })
  },
})

export const { addMeeting, removeGuest } = guestSlice.actions

export default guestSlice.reducer
