import { SOURCE } from '../constants'

export const getHHResumes = async (token) => {
  try {
    const res = await fetch(`${SOURCE.url}hh/resumes`, {
      method: 'GET',
      headers: {
        Authorization: token,
      },
    })
    if (!res.ok) return []
    return await res.json()
  } catch (error) {
    console.error(error)
    return []
  }
}
