import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { resetPassword } from '../../../api/passwordResets'
import { AppInput } from '../../../components/ui/AppInput'
import { Validation } from '../../../constants'
import { showErrorMsg } from '../../../store/slices/alertSlice'

export const ResetPassword = () => {
	const { code } = useParams()
	const [searchParams] = useSearchParams()
	const email = searchParams.get('email')
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [state, setState] = useState({
		pswd1: '',
		pswd2: '',
		passwordVisible: false,
		sent: false,
	})

	return (
		<div
			className='container-fluid d-flex flex-column mx-auto my-5'
			style={{ maxWidth: '500px' }}
		>
			<p className='text-center fs-2 mb-4' style={{ fontWeight: '400' }}>
				Восстановление доступа
			</p>
			{state.sent ? (
				<>
					<p className='fs-3 text-center'>Пароль успешно изменён</p>
					<button
						className='btn light_primary px-4 py-2 mt-2'
						onClick={() => navigate('/auth')}
					>
						Авторизация
					</button>
				</>
			) : (
				<>
					<AppInput
						label='Новый пароль'
						showAlert={!Validation.validPassword(state.pswd1)}
						value={state.pswd1}
						showEye={true}
						type={state.passwordVisible ? 'text' : 'password'}
						switchEye={() =>
							setState(p => ({ ...p, passwordVisible: !p.passwordVisible }))
						}
						onChange={v => setState(prev => ({ ...prev, pswd1: v.trim() }))}
					/>
					<AppInput
						label='Подтвердите пароль'
						showAlert={!Validation.validPassword(state.pswd2)}
						value={state.pswd2}
						showEye={true}
						type={state.passwordVisible ? 'text' : 'password'}
						switchEye={() =>
							setState(p => ({ ...p, passwordVisible: !p.passwordVisible }))
						}
						onChange={v => setState(prev => ({ ...prev, pswd2: v.trim() }))}
					/>
					<button
						className='btn primary py-2 px-4 mt-4'
						onClick={() => save(state, setState, code, dispatch, email)}
					>
						Сохранить
					</button>
				</>
			)}
		</div>
	)
}

async function save(state, setState, code, dispatch, email) {
	if (!Validation.validMail(email)) {
		return dispatch(
			showErrorMsg('Не найдена электронная почта аккаунта. Повторите попытку')
		)
	}
	if (!Validation.validPassword(state.pswd1)) {
		return dispatch(
			showErrorMsg(
				'Пароль должен содержать латинские заглавные и прописные буквы и цифры и быть не короче 8 символов'
			)
		)
	}
	if (state.pswd1 !== state.pswd2) {
		return dispatch(showErrorMsg('Пароли не совпадают'))
	}
	const result = await resetPassword(code, email, state.pswd1)
	if (result) setState(p => ({ ...p, sent: true }))
	else
		dispatch(
			showErrorMsg('Код не найден или уже был использован. Повторите попытку')
		)
}
