import { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { deleteMeeting, updateUserMeetings } from '../../../api/meetings'
import {
	MeetingInfoModal,
	MoveMeetingModal,
	VideoModal,
} from '../../../components/Modals'
import { COLORS, SOURCE, meetingTypes } from '../../../constants'
import { ReactComponent as CalendarSvg } from '../../../icons/calendar-fill.svg'
import { ReactComponent as DeleteSvg } from '../../../icons/delete-fill.svg'
import { ReactComponent as SberJazzSvg } from '../../../icons/sberjazz.svg'
import { ReactComponent as ScreamMaskSvg } from '../../../icons/scream-mask.svg'
import { ReactComponent as ThreeDotsSvg } from '../../../icons/three-dots.svg'
import { showErrorMsg } from '../../../store/slices/alertSlice'

export const Meetings = () => {
	const user = useSelector(state => state.userSlice.user)
	const meetings = useSelector(state => state.userSlice.meetingsData.meetings)

	return (
		<div className='w-100'>
			<p className='mb-3 fs-3' style={{ fontWeight: '300' }}>
				Запланированные встречи
			</p>
			{meetings.filter(m => m.status === 'PLANNED').length > 0 ? (
				<div className='row'>
					{meetings
						.filter(m => m.status === 'PLANNED')
						.map((meeting, i) => (
							<Meeting meeting={meeting} key={i} />
						))}
				</div>
			) : (
				<div
					className='d-flex flex-row align-items-center justify-content-center rounded-3 p-sm-4 p-2 mb-4 bg-white'
					style={{}}
				>
					<ScreamMaskSvg
						className='me-3'
						fill={COLORS.primary}
						style={{ height: '48px' }}
					/>
					<p className='fs-5'>Пока их нет, но всё впереди</p>
				</div>
			)}
			{meetings.filter(m => m.status !== 'PLANNED').length > 0 && (
				<>
					<p className='mb-3 fs-3' style={{ fontWeight: '300' }}>
						Прошедшие встречи
					</p>
					<div className='row'>
						{meetings
							.filter(m => m.status !== 'PLANNED')
							.map((meeting, i) => (
								<PastMeeting
									meeting={meeting}
									key={i}
									access_token={user.access_token}
								/>
							))}
					</div>
				</>
			)}
		</div>
	)
}

const Meeting = ({ meeting }) => {
	const [state, setState] = useState({
		meetingInfoModal: {
			shown: false,
			meeting: meeting,
		},
		moveMeetingModalShown: false,
	})
	const dispatch = useDispatch()
	const access_token = useSelector(state => state.userSlice.user.access_token)

	const handleDeleteMeeting = () => {
		if (window.confirm('Вы действительно хотите отменить встречу?'))
			deleteMeeting(access_token, meeting.id).then(isOk => {
				if (!isOk) dispatch(showErrorMsg('Произошла ошибка'))
				else
					updateUserMeetings(access_token, dispatch, [
						'slot',
						'manager',
						'feedback',
					])
			})
	}

	return (
		<div className='col-md col-xxl-4 col-md-6 mb-3'>
			{state.meetingInfoModal.shown && (
				<MeetingInfoModal
					meetingInfoModal={state.meetingInfoModal}
					setState={setState}
					past={false}
				/>
			)}
			{state.moveMeetingModalShown && (
				<MoveMeetingModal
					meeting={meeting}
					set={setState}
					shown={state.moveMeetingModalShown}
				/>
			)}
			<div
				className='d-flex flex-column px-3 px-sm-4 mb-2 rounded-3 pt-3 pb-2 w-100 light_light_primary bg-white position-relative'
				style={{ cursor: 'pointer' }}
				onClick={() =>
					setState(p => ({
						...p,
						meetingInfoModal: { ...p.meetingInfoModal, shown: true },
					}))
				}
			>
				<p
					className='fs-6'
					style={{ color: COLORS.header_text, fontWeight: '200' }}
				>
					{meetingTypes[meeting.type]}
				</p>
				<p className='mb-3 fs-5' style={{ fontWeight: '400' }}>
					{meeting.name}
				</p>
				<p className='fs-6'>
					Дата и время{' '}
					<span
						style={{
							color: COLORS.primary,
							fontWeight: '400',
						}}
					>
						{meeting.slot &&
							new Date(meeting.slot.dateTime).toLocaleString().slice(0, -3)}
					</span>
				</p>
				<p className='mb-4 fs-6'>
					Менеджер:{' '}
					<span
						style={{
							color: COLORS.primary,
							fontWeight: '400',
						}}
					>
						{meeting.slot && meeting.slot.manager && meeting.slot.manager.name}
					</span>
				</p>
				<div className='d-flex flex-row flex-wrap align-items-center'>
					<div
						className='py-2 px-4 rounded-3 me-4 justify-content-center mb-3 success'
						onClick={() => window.open(meeting.roomUrl)}
						style={{
							backgroundColor: COLORS.success,
							color: '#fff',
							cursor: 'pointer',
						}}
					>
						<SberJazzSvg alt='' className='me-3' />
						Подключиться
					</div>
				</div>
				<div
					className='position-absolute'
					style={{ top: '20px', right: '15px' }}
				>
					<Dropdown onClick={e => e.stopPropagation()}>
						<Dropdown.Toggle
							className='no_outline'
							style={{ background: 'transparent' }}
						>
							<ThreeDotsSvg />
						</Dropdown.Toggle>

						<Dropdown.Menu style={{ minWidth: '200px' }}>
							<Dropdown.Item
								className='d-flex align-items-center gap-2 fw-semibold'
								onClick={() =>
									setState(p => ({ ...p, moveMeetingModalShown: true }))
								}
								style={{ background: 'transparent', color: 'black' }}
							>
								<CalendarSvg />
								&nbsp;Перенести
							</Dropdown.Item>
							<Dropdown.Item
								className='d-flex align-items-center gap-2 fw-semibold'
								style={{ background: 'transparent', color: 'black' }}
								onClick={handleDeleteMeeting}
							>
								<DeleteSvg />
								&nbsp;Отменить
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>
		</div>
	)
}

const PastMeeting = ({ meeting }) => {
	const [state, setState] = useState({
		meetingInfoModal: {
			shown: false,
			feedbackAvailable: !!(meeting.feedback || [{}])[0]?.id,
			meeting: meeting,
		},
	})
	const [stateVideo, setStateVideo] = useState({
		videoModalShown: false,
		videoUrl: SOURCE.source_url + `meetings/${meeting?.id}/video`,
	})
	const access_token = useSelector(state => state.userSlice.user.access_token)

	return (
		<div className='col-md col-xxl-4 col-md-6 mb-3'>
			{state.meetingInfoModal.shown && (
				<MeetingInfoModal
					meetingInfoModal={state.meetingInfoModal}
					setState={setState}
					past={true}
				/>
			)}
			<div
				className='d-flex flex-column px-3 px-sm-4 mb-2 rounded-3 pt-3 pb-2 w-100 light_light_primary bg-white'
				style={{
					cursor: state.meetingInfoModal.feedbackAvailable ? 'pointer' : 'auto',
				}}
				onClick={() =>
					state.meetingInfoModal.feedbackAvailable &&
					setState(p => ({
						...p,
						meetingInfoModal: { ...p.meetingInfoModal, shown: true },
					}))
				}
			>
				<p
					className='fs-6'
					style={{ color: COLORS.header_text, fontWeight: '200' }}
				>
					{meetingTypes[meeting.type]}
				</p>
				<p className='mb-4 fs-5' style={{ fontWeight: '400' }}>
					{meeting.name}
				</p>
				<p className='fs-5'>
					Дата и время:{' '}
					<span
						style={{
							color: COLORS.primary,
							fontWeight: '400',
						}}
					>
						{new Date(meeting.slot.dateTime).toLocaleString().slice(0, -3)}
					</span>
				</p>
				<p className='mb-4 fs-5'>
					Менеджер:{' '}
					<span
						style={{
							color: COLORS.primary,
							fontWeight: '400',
						}}
					>
						{meeting.slot && meeting.slot.manager && meeting.slot.manager.name}
					</span>
				</p>
				<div className='d-flex flex-row flex-wrap align-items-center'>
					<div
						className='py-2 px-4 rounded-3 me-4 justify-content-center mb-3 success'
						onClick={e => {
							e.stopPropagation()
							setStateVideo(p => ({ ...p, videoModalShown: true }))
						}}
						style={{
							backgroundColor: COLORS.primary,
							color: '#fff',
							cursor: 'pointer',
						}}
					>
						Посмотреть запись
					</div>
				</div>
			</div>
			{stateVideo.videoModalShown && (
				<VideoModal state={stateVideo} setState={setStateVideo} />
			)}
		</div>
	)
}
