import './styles.css'
import {useDispatch, useSelector} from "react-redux"
import {
    createCertificate,
    createEducation,
    createExperience, createLanguage,
    createSkill
} from "../ConstructorResumeBlock/utils"
import {COLORS, useDebounceCallback} from "../../constants"
import {patchResume} from "../../api/resume"

export default function ConstructorSectionsBlock() {
    const dispatch = useDispatch()
    const resume = useSelector(state => state.resumeSlice.resume)
    const user = useSelector(state => state.userSlice.user)
    const updateServerResume = useDebounceCallback(patchResume, 500)
    return  <div className="bg-white rounded-3 w-100 px-4 py-4 d-flex flex-column gap-3">
        <span className="fs-5 fw-semibold mb-1">Секции</span>
        {
            !resume.id ? <p className='fs-5' style={{ color: COLORS.danger }}>Укажите специальность для редактирования вашего резюме</p> :
            <>
                <button className="fs-6 section-btn" disabled={resume.experience && resume.experience.length} 
                    onClick={() => createExperience(user.access_token, resume, dispatch)}>Опыт работы</button>
                <button className="fs-6 section-btn" disabled={resume.education && resume.education.length} 
                    onClick={() => createEducation(user.access_token, resume, dispatch)}>Образование</button>
                <button className="fs-6 section-btn" disabled={resume.certificates && resume.certificates.length} 
                    onClick={() =>  createCertificate(user.access_token, resume, dispatch)}>Курсы и сертификаты</button>
                <button className="fs-6 section-btn" disabled={resume.skills && resume.skills.length} 
                    onClick={() => createSkill(user.access_token, resume, dispatch, updateServerResume)}>Навыки</button>
                <button className="fs-6 section-btn" disabled={resume.languages && resume.languages.length} 
                    onClick={() => createLanguage(user.access_token, resume, dispatch)}>Языки</button>
            </>
        }
    </div>
}