import { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import {
  COLORS,
  SOURCE,
  isInt,
  labelsBySalaryCurrency,
  useDebounceCallback,
} from '../../constants'
import { ReactComponent as BaselinePlace } from '../../icons/baseline-place.svg'
import { ReactComponent as DeleteOutline } from '../../icons/delete-outline.svg'
import { ReactComponent as PlusSvg } from '../../icons/plus_circle.svg'
import { ReactComponent as TwoCoins } from '../../icons/two-coins.svg'
import { showErrorMsg } from '../../store/slices/alertSlice'
import { ContactMenuSelect } from '../ContactMenuSelect/ContactMenuSelect'
import { PublishResumeButton } from '../PublishResumeButton/PublishResumeButton'
import { SaveResumeButton } from '../SaveResumeButton/SaveResumeButton'
import { DefaultAvatar } from '../ui/DefaultAvatar'
import InputAutosize from '../ui/InputAutosize'
import TextareaAutosize from '../ui/TextareaAutosize'
import {
  createCertificate,
  createEducation,
  createExperience,
  createLanguage,
  createSkill,
  deleteCertificate,
  deleteEducation,
  deleteExperience,
  deleteLanguage,
  deleteSkill,
  editCertificate,
  editEducation,
  editExperience,
  editLanguage,
  updateResume,
  updateSkill,
  uploadAvatar,
} from './utils'
import './styles.css'
import {
  patchResume,
  patchResumeCertificate,
  patchResumeEducation,
  patchResumeExperience,
  patchResumeLanguage,
} from '../../api/resume'

export default function ConstructorResumeBlock({ user, resume }) {
  const dispatch = useDispatch()

  console.log('ConstructorResumeBlock resume', resume)

  const [state, setState] = useState(1)

  const uploadAvatarHandler = async (file) => {
    await uploadAvatar(user.access_token, user.id, file, dispatch)
    setState(state ? 0 : 1)
  }

  const updateServerResume = useDebounceCallback(patchResume, 500)
  const updateServerExperience = useDebounceCallback(
    patchResumeExperience,
    500
  )
  const updateServerEducation = useDebounceCallback(patchResumeEducation, 500)
  const updateServerCertificate = useDebounceCallback(
    patchResumeCertificate,
    500
  )
  const updateServerLanguage = useDebounceCallback(patchResumeLanguage, 500)

  const navigate = useNavigate()

  const loc = useLocation()
  const isAuth = loc.pathname.includes('/auth')

  return (
    <>
      <div className='row gap-2'>
        {/* Personal Details Block */}
        <div className='w-auto position-relative mb-2'>
          <DefaultAvatar
            src={SOURCE.url + `applicants/${user.id}/avatar`}
            key={state}
            alt='avatar'
            className='rounded img-fluid'
            style={{
              objectFit: 'cover',
              width: 'min(110px,20vw)',
              height: 'min(110px,20vw)',
            }}
          />
          <input
            type='file'
            accept='image/png,image/jpeg,image/webp'
            className='position-absolute opacity-0 top-0 start-0'
            style={{ cursor: 'pointer', width: '110px', height: '110px' }}
            onInput={(e) => {
              uploadAvatarHandler(e.target.files[0])
            }}
          />
        </div>
        <div className='col d-flex flex-column mb-2'>
          <span className='fs-4 fw-semibold'>
            {user.firstName} {user.lastName}
          </span>
          <InputAutosize
            type='text'
            className='fs-5 fw-semibold header_input'
            placeholder='Специальность'
            minWidth='1ch'
            defaultWidth='13ch'
            value={resume.title || ''}
            onChange={(e) => {
              updateResume(
                user.access_token,
                resume,
                { title: e.target.value },
                dispatch,
                updateServerResume
              )
            }}
          />
          <div
            className='d-flex d-sm-none mt-3 flex-row justify-content-end'
            style={{ width: 'fit-content' }}
          >
            <ContactMenuSelect user={user} resume={resume} />
          </div>
          <ExtraInfo
            resume={resume}
            display='d-sm-flex d-none mt-2'
            updateServerResume={updateServerResume}
            dispatch={dispatch}
            user={user}
          />
        </div>
        <div
          className='d-sm-flex d-none flex-row justify-content-end'
          style={{ width: 'fit-content' }}
        >
          <ContactMenuSelect user={user} resume={resume} />
        </div>
      </div>
      <ExtraInfo
        resume={resume}
        display='d-sm-none d-flex'
        updateServerResume={updateServerResume}
        dispatch={dispatch}
        user={user}
      />
      {/* About Block */}
      {
        <div>
          <span className='fs-4 fw-semibold'>
            O себе
            <span></span>
          </span>
          <div className='row g-2 g-md-3 mt-1'>
            {
              <About
                resume={resume}
                updateServerResume={updateServerResume}
                dispatch={dispatch}
                user={user}
              />
            }
          </div>
        </div>
      }
      {/* Skills Block */}
      {resume?.skills?.length !== 0 && (
        <div>
          <span className='fs-4 fw-semibold'>
            Навыки
            <span>
              <PlusSvg
                style={{ cursor: 'pointer' }}
                className='ms-2'
                onClick={() =>
                  createSkill(
                    user.access_token,
                    resume,
                    dispatch,
                    updateServerResume
                  )
                }
              />
            </span>
          </span>
          <div className='row g-2 g-md-3 mt-1'>
            {(resume.skills || []).map((s, i) => (
              <Skill
                key={i}
                i={i}
                s={s}
                dispatch={dispatch}
                user={user}
                resume={resume}
                updateServerResume={updateServerResume}
              />
            ))}
          </div>
        </div>
      )}
      {/* Experience Block */}
      {resume?.experience?.length !== 0 && (
        <div className='row gap-3'>
          <span className='fs-4 fw-semibold'>
            Опыт работы
            <span>
              <PlusSvg
                style={{ cursor: 'pointer' }}
                className='ms-2'
                onClick={() =>
                  createExperience(user.access_token, resume, dispatch)
                }
              />
            </span>
          </span>
          {(resume.experience || []).map((exp) => (
            <Experience
              key={exp.id}
              exp={exp}
              dispatch={dispatch}
              user={user}
              resume={resume}
              updateServerExperience={updateServerExperience}
            />
          ))}
        </div>
      )}
      {/* Education Block */}
      {resume?.education?.length !== 0 && (
        <div className='row gap-3'>
          <span className='fs-4 fw-semibold'>
            Образование
            <span>
              <PlusSvg
                style={{ cursor: 'pointer' }}
                className='ms-2'
                onClick={() =>
                  createEducation(user.access_token, resume, dispatch)
                }
              />
            </span>
          </span>
          {(resume.education || []).map((edu) => (
            <Education
              key={edu.id}
              edu={edu}
              dispatch={dispatch}
              user={user}
              resume={resume}
              updateServerEducation={updateServerEducation}
            />
          ))}
        </div>
      )}
      {/* Certificates Block */}
      {resume?.certificates?.length !== 0 && (
        <div className='row gap-3'>
          <span className='fs-4 fw-semibold'>
            Курсы и сертификаты
            <span>
              <PlusSvg
                style={{ cursor: 'pointer' }}
                className='ms-2'
                onClick={() =>
                  createCertificate(user.access_token, resume, dispatch)
                }
              />
            </span>
          </span>
          {(resume.certificates || []).map((cert) => (
            <Certificate
              key={cert.id}
              cert={cert}
              user={user}
              resume={resume}
              dispatch={dispatch}
              updateServerCertificate={updateServerCertificate}
            />
          ))}
        </div>
      )}
      {/* Languages Block */}
      {resume?.languages?.length !== 0 && (
        <div className='row'>
          <span className='fs-4 mb-3 fw-semibold'>
            Языки
            <span>
              <PlusSvg
                style={{ cursor: 'pointer' }}
                className='ms-2'
                onClick={() =>
                  createLanguage(user.access_token, resume, dispatch)
                }
              />
            </span>
          </span>
          <div className='d-flex flex-row flex-wrap gap-3'>
            {(resume.languages || []).map((lang, i) => (
              <Language
                key={lang.id}
                user={user}
                resume={resume}
                lang={lang}
                updateServerLanguage={updateServerLanguage}
                dispatch={dispatch}
              />
            ))}
          </div>
        </div>
      )}
      {/* Resume controls */}
      {isAuth ? (
        <div className='d-flex mt-3'>
          <button
            className='btn primary px-4 py-2 fs-6 ms-sm-auto'
            onClick={() =>
              navigate('/auth/next', { state: { step: 'fill_resume' } })
            }
          >
            Продолжить
          </button>
        </div>
      ) : (
        <div className='row gap-2 gap-sm-4 mt-3'>
          <SaveResumeButton resume={resume} user={user} />
          <PublishResumeButton
            access_token={user.access_token}
            resume={resume}
          />
        </div>
      )}
    </>
  )
}

const ExtraInfo = ({ display, resume, dispatch, updateServerResume, user }) => {
  return (
    <div
      className={'flex-column flex-sm-row flex-wrap gap-2 gap-sm-4 ' + display}
    >
      <div className='d-flex flex-row gap-2'>
        <BaselinePlace />
        <InputAutosize
          type='text'
          className='fs-6 fw-semibold header_input'
          placeholder='Город'
          minWidth='3ch'
          defaultWidth='5ch'
          value={resume.city || ''}
          onChange={(e) => {
            updateResume(
              user.access_token,
              resume,
              { city: e.target.value },
              dispatch,
              updateServerResume
            )
          }}
        />
      </div>
      <div className='d-flex flex-row gap-2'>
        <TwoCoins />
        <InputAutosize
          type='text'
          className='fs-6 fw-semibold header_input'
          placeholder='з/п'
          minWidth='3ch'
          defaultWidth='5ch'
          value={resume.desiredSalary || ''}
          onChange={(e) => {
            const val = +e.target.value.replace(/\D/g, '') || null

            updateResume(
              user.access_token,
              resume,
              {
                desiredSalary: val > 2147483647 ? 2147483647 : val,
                desiredSalaryCurrency: 'RUB',
              },
              dispatch,
              updateServerResume
            )
          }}
        />
        <span
          className='fs-6 fw-semibold '
          style={{ color: COLORS.header_text }}
        >
          {labelsBySalaryCurrency[resume.desiredSalaryCurrency]}
        </span>
      </div>
    </div>
  )
}

const About = ({ resume, dispatch, updateServerResume, user }) => {
  return (
    <TextareaAutosize
      type='text'
      className='p-2 rounded-3'
      placeholder='Расскажи о себе'
      minHeight='8rem'
      defaultHeight='1.5rem'
      style={{ resize: 'none', minWidth: '100px' }}
      value={resume.summary || ''}
      onChange={(e) => {
        updateResume(
          user.access_token,
          resume,
          { summary: e.target.value },
          dispatch,
          updateServerResume
        )
      }}
    />
  )
}

const Skill = ({ s, updateServerResume, user, resume, dispatch, i }) => {
  return (
    <div className='col-auto'>
      <button
        className='rounded-start p-2'
        style={{ top: '5px', backgroundColor: COLORS.light_neutral }}
        onClick={() =>
          deleteSkill(
            user.access_token,
            resume,
            i,
            dispatch,
            updateServerResume
          )
        }
      >
        <DeleteOutline fill='black' />
      </button>
      <InputAutosize
        type='text'
        className='fw-medium px-3 py-2 rounded-end no_outline'
        style={{ backgroundColor: COLORS.light_neutral, marginLeft: '1.5px' }}
        placeholder='Навык'
        minWidth='4ch'
        defaultWidth='6.25rem'
        value={s || ''}
        onChange={(e) => {
          updateSkill(
            user.access_token,
            resume,
            i,
            e.target.value,
            dispatch,
            updateServerResume
          )
        }}
      />
    </div>
  )
}

const DeleteButton = ({ del, id, user, obj, dispatch }) => {
  return (
    <div className='col-auto pe-0'>
      <button
        className='rounded-1 p-1'
        style={{ top: '-2px', backgroundColor: COLORS.light_neutral }}
        onClick={() => del(user.access_token, id, obj, dispatch)}
      >
        <DeleteOutline />
      </button>
    </div>
  )
}

const Experience = ({
  exp,
  user,
  resume,
  dispatch,
  updateServerExperience,
}) => {
  return (
    <div className='row delete-parent'>
      <DeleteButton
        del={deleteExperience}
        id={exp.id}
        obj={resume.experience}
        user={user}
        dispatch={dispatch}
      />
      {/* <div className="col-auto pe-0">
        <button className=" p-1" style={{top: "-2px", backgroundColor: COLORS.light_neutral}} 
                onClick={() =>  deleteExperience(user.access_token, exp.id, resume.experience, dispatch)}>
            <DeleteOutline />
        </button>
    </div> */}
      <div className='col col-sm-5 col-md-4 col-lg-5 col-xl-4 d-flex flex-column'>
        <input
          className='fs-6 fw-medium mb-1 no_outline'
          style={{ color: COLORS.primary }}
          value={exp.company || ''}
          onChange={(ev) =>
            editExperience(
              user.access_token,
              exp.id,
              { company: ev.target.value },
              resume.experience,
              dispatch,
              updateServerExperience
            )
          }
          placeholder='Компания'
        />
        <input
          className='fs-6 fw-medium no_outline'
          value={exp.position || ''}
          onChange={(ev) =>
            editExperience(
              user.access_token,
              exp.id,
              { position: ev.target.value },
              resume.experience,
              dispatch,
              updateServerExperience
            )
          }
          placeholder='Позиция'
        />
        <span>
          <InputMask
            className='fs-6 fw-medium no_outline m-0 p-0 '
            style={{ width: '4rem' }}
            mask='99.9999'
            placeholder='мм.гггг'
            value={(exp.startMonth || '') + '.' + (exp.startYear || '')}
            onChange={(ev) => {
              let a = ev.target.value
              if (a[0] !== '1' && a[0] !== '0') {
                a = '0'.concat(a.slice(1))
              }
              let startMonth = (a.split('.')[0] || '').replaceAll('_', '')
              let startYear = (a.split('.')[1] || '').replaceAll('_', '')

              if (startYear > new Date().getFullYear()) {
                dispatch(
                  showErrorMsg(
                    `Год не должен быть больше ${new Date().getFullYear()}`
                  )
                )
                startYear = new Date().getFullYear()
              }

              if (startMonth > 12) {
                dispatch(showErrorMsg(`Неверный формат месяца`))
                startMonth = 12
              }

              editExperience(
                user.access_token,
                exp.id,
                {
                  startMonth: isInt(startMonth) ? startMonth : '0',
                  startYear: isInt(startYear) ? startYear : '0',
                },
                resume.experience,
                dispatch,
                updateServerExperience
              )
            }}
          />
          &nbsp;-&nbsp;
          <InputMask
            className='fs-6 fw-medium no_outline m-0 p-0'
            style={{ width: '4rem' }}
            mask='99.9999'
            placeholder='мм.гггг'
            value={(exp.endMonth || '') + '.' + (exp.endYear || '')}
            onChange={(ev) => {
              let a = ev.target.value
              // if (value[0] !== '1' && value[0] !== '0') {
              // 	value = '0'.concat(value.slice(1))
              // }

              let endMonth = (a.split('.')[0] || '').replaceAll('_', '')
              let endYear = (a.split('.')[1] || '').replaceAll('_', '')

              if (endYear > new Date().getFullYear()) {
                dispatch(
                  showErrorMsg(
                    `Год не должен быть больше ${new Date().getFullYear()}`
                  )
                )
                endYear = new Date().getFullYear()
              }

              if (endMonth > 12) {
                dispatch(showErrorMsg(`Неверный формат месяца`))
                endMonth = 12
              }

              editExperience(
                user.access_token,
                exp.id,
                {
                  endMonth: isInt(endMonth) ? endMonth : '0',
                  endYear: isInt(endYear) ? endYear : '0',
                },
                resume.experience,
                dispatch,
                updateServerExperience
              )
            }}
          />
        </span>
      </div>
      <div className='col'>
        <TextareaAutosize
          className='fs-6 fw-medium no_outline w-100'
          value={exp.description || ''}
          onChange={(ev) =>
            editExperience(
              user.access_token,
              exp.id,
              { description: ev.target.value },
              resume.experience,
              dispatch,
              updateServerExperience
            )
          }
          placeholder='Описание'
          minHeight='1.5rem'
          defaultHeight='1.5rem'
          style={{ resize: 'none', minWidth: '100px' }}
        />
      </div>
    </div>
  )
}

const Education = ({ user, resume, dispatch, updateServerEducation, edu }) => {
  return (
    <div className='row delete-parent flex-wrap'>
      <DeleteButton
        del={deleteEducation}
        id={edu.id}
        obj={resume.education}
        user={user}
        dispatch={dispatch}
      />
      <div className='col col-sm-5 col-md-4 col-lg-5 col-xl-4 d-flex flex-column'>
        <input
          className='fs-6 fw-medium no_outline delete-moveable'
          style={{ color: COLORS.primary }}
          value={edu.name || ''}
          onChange={(ev) =>
            editEducation(
              user.access_token,
              edu.id,
              { name: ev.target.value },
              resume.education,
              dispatch,
              updateServerEducation
            )
          }
          placeholder='Учебное заведение'
        />
        <span className='fs-6 fw-medium' style={{ overflow: 'hidden' }}>
          <InputAutosize
            className='fs-6 fw-medium no_outline'
            value={edu.degree || ''}
            onChange={(ev) =>
              editEducation(
                user.access_token,
                edu.id,
                { degree: ev.target.value },
                resume.education,
                dispatch,
                updateServerEducation
              )
            }
            placeholder='Степень'
            minWidth='1ch'
            defaultWidth='7ch'
          />
          ,&nbsp;
          <InputMask
            className='fs-6 fw-medium no_outline m-0 p-0'
            style={{ width: '2.5rem' }}
            mask='9999'
            placeholder='гггг'
            value={edu.startYear || ''}
            onChange={(ev) => {
              let a = ev.target.value

              let startYear = (a.split('.')[0] || '').replaceAll('_', '')
              if (startYear > 3000) {
                dispatch(showErrorMsg(`Неверный формат года`))
                startYear = 3000
              }

              editEducation(
                user.access_token,
                edu.id,
                { startYear: isInt(startYear) ? startYear : 0 },
                resume.education,
                dispatch,
                updateServerEducation
              )
            }}
          />
          -&nbsp;
          <InputMask
            className='fs-6 fw-medium no_outline m-0 p-0'
            style={{ width: '2.5rem' }}
            mask='9999'
            placeholder='гггг'
            value={edu.endYear || ''}
            onChange={(ev) => {
              let a = ev.target.value

              let endYear = (a.split('.')[0] || '').replaceAll('_', '')
              if (endYear > 3000) {
                dispatch(showErrorMsg(`Неверный формат года`))
                endYear = 3000
              }

              editEducation(
                user.access_token,
                edu.id,
                {
                  endYear: isInt(endYear) ? endYear : 0,
                },
                resume.education,
                dispatch,
                updateServerEducation
              )
            }}
          />
        </span>
      </div>
      <div className='col'>
        <TextareaAutosize
          className='fs-6 fw-medium no_outline w-100'
          value={edu.description || ''}
          onChange={(ev) =>
            editEducation(
              user.access_token,
              edu.id,
              { description: ev.target.value },
              resume.education,
              dispatch,
              updateServerEducation
            )
          }
          placeholder='Описание'
          minHeight='1.5rem'
          defaultHeight='1.5rem'
          style={{ resize: 'none', minWidth: '100px' }}
        />
      </div>
    </div>
  )
}

const Certificate = ({
  cert,
  updateServerCertificate,
  user,
  resume,
  dispatch,
}) => {
  return (
    <div className='row delete-parent'>
      <DeleteButton
        del={deleteCertificate}
        id={cert.id}
        obj={resume.certificates}
        user={user}
        dispatch={dispatch}
      />
      {/* <button className="delete-btn p-0" style={{top: "-2px", left: "20px"}}
            onClick={() =>  deleteCertificate(user.access_token, cert.id, resume.certificates, dispatch)}>
        <DeleteOutline />
    </button> */}
      <div className='col col-sm-5 col-md-4 col-lg-5 col-xl-4 d-flex flex-column'>
        <input
          className='fs-6 fw-medium no_outline delete-moveable'
          style={{ color: COLORS.primary }}
          value={cert.name || ''}
          onChange={(ev) =>
            editCertificate(
              user.access_token,
              cert.id,
              { name: ev.target.value },
              resume.certificates,
              dispatch,
              updateServerCertificate
            )
          }
          placeholder='Название учреждения'
        />
        <InputMask
          className='fs-6 fw-medium no_outline m-0 p-0'
          style={{ width: '2.85rem' }}
          mask='9999'
          placeholder='гггг'
          value={cert.year || ''}
          onChange={(ev) =>
            editCertificate(
              user.access_token,
              cert.id,
              {
                year: isInt((ev.target.value || '').replaceAll('_', ''))
                  ? (ev.target.value || '').replaceAll('_', '')
                  : 0,
              },
              resume.certificates,
              dispatch,
              updateServerCertificate
            )
          }
        />
      </div>
      <div className='col'>
        <TextareaAutosize
          className='fs-6 fw-medium no_outline w-100'
          value={cert.description || ''}
          onChange={(ev) =>
            editCertificate(
              user.access_token,
              cert.id,
              { description: ev.target.value },
              resume.certificates,
              dispatch,
              updateServerCertificate
            )
          }
          placeholder='Описание'
          minHeight='1.5rem'
          defaultHeight='1.5rem'
          style={{ resize: 'none', minWidth: '100px' }}
        />
      </div>
    </div>
  )
}

const Language = ({ lang, user, resume, updateServerLanguage, dispatch }) => {
  return (
    <div className='d-flex flex-row delete-parent delete-not-mobile'>
      <button
        className='rounded-start'
        style={{
          top: '-3px',
          backgroundColor: COLORS.light_neutral,
          marginRight: '1px',
        }}
        onClick={() =>
          deleteLanguage(user.access_token, lang.id, resume.languages, dispatch)
        }
      >
        <DeleteOutline />
      </button>
      <span
        className='fs-6 fw-medium px-3 py-2 rounded-end delete-moveable'
        style={{ backgroundColor: COLORS.light_neutral }}
      >
        <InputAutosize
          className='fs-6 fw-medium no_outline'
          value={lang.name || ''}
          onChange={(ev) =>
            editLanguage(
              user.access_token,
              lang.id,
              { name: ev.target.value },
              resume.languages,
              dispatch,
              updateServerLanguage
            )
          }
          placeholder='Язык'
          minWidth='1ch'
          defaultWidth='4ch'
        />
        &nbsp;-&nbsp;
        <InputAutosize
          type='text'
          className='fs-6 fw-medium no_outline'
          style={{ color: COLORS.primary }}
          value={lang.level || ''}
          minWidth='1ch'
          defaultWidth='2.5ch'
          onChange={(ev) =>
            editLanguage(
              user.access_token,
              lang.id,
              { level: ev.target.value },
              resume.languages,
              dispatch,
              updateServerLanguage
            )
          }
          placeholder='A1'
        />
      </span>
    </div>
  )
}
