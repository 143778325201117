import Cookies from 'js-cookie'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router'
import { COLORS } from '../../../../constants'
import { showSuccessMsg } from '../../../../store/slices/alertSlice'
import { ReactComponent as ClipboardSvg } from '../../../../icons/clipboard.svg'
import { ReactComponent as CheckMarkSvg } from '../../../../icons/consultations_landing/checkmark.svg'

export const ReloadPayment = ({ key_route }) => {
	const url = useState(Cookies.get('meeting_url'))
	console.log(url)
	const dispatch = useDispatch()

	return (
		<div className='w-100 mx-auto pt-md-5 pb-5 my-sm-5' style={{ flex: '1' }}>
			<div
				className='d-flex flex-column align-items-center text-center mx-auto'
				style={{ maxWidth: '700px' }}
			>
				<CheckMarkSvg className='mb-2' />
				<p className='fs-1 fw-bold ' style={{ color: COLORS.light_success }}>
					Оплата прошла успешно!
				</p>
				{url ? (
					<>
						<p className='fs-3 mb-3'>Твоя ссылка на консультацию</p>
						<div
							className='rounded-3 bg-white p-3 d-flex flex-nowrap mb-3'
							style={{ cursor: 'pointer' }}
							onClick={() =>
								navigator.clipboard.writeText(url) &&
								dispatch(showSuccessMsg('Скопировано в буфер обмена'))
							}
						>
							<span className='text-decoration-underline'>{url || ''}...</span>
							<ClipboardSvg className='ms-3' width={'23px'} height='23px' />
						</div>
						<p className='fs-5 mb-5' style={{ color: COLORS.hr }}>
							Также ссылка будет выслана на твою почту
						</p>
					</>
				) : (
					<>
						<p className='fs-3 mb-3'>Твоя ссылка уже выслана на почту</p>
						<p className='fs-3 mb-5'>
							Если ссылки еще нет — обратись в поддержку и мы постараемся решить
							твою проблему
						</p>
						<div className='row gap-3'>
							<button
								className='col-auto btn primary_outline text-nowrap rounded-pill py-3 px-5 mt-sm-5 fs-6'
								style={{ flex: '1' }}
								onClick={() => window.open('https://t.me/hurtle_support_bot')}
							>
								Обратиться в поддержку
							</button>
						</div>
					</>
				)}
				<button
					className='btn primary rounded-pill py-3 px-5 mt-sm-5 fs-6'
					onClick={() => <Navigate to={key_route} />}
				>
					Вернуться на главную страницу
				</button>
			</div>
		</div>
	)
}
