import {SOURCE} from "../constants"

export async function sendResetPassLink(email, dispatch) {
    try{
        const result = await fetch(SOURCE.url + 'passwordResets?role=APPLICANT&email=' + email, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        return result.ok
    } catch {
        return false
    }
}

export async function resetPassword(code, email, password) {
    try {
        const result = await fetch(SOURCE.url + 'passwordResets/' + code, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email, password})
        })
        return result.ok

    } catch {
        return false
    }
}