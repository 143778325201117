import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import { useEffect, useState } from 'react'
import { bookSlotAsync } from '../../../store/slices/guestSlice'
import { setShowModal, setTimer } from '../../../store/slices/bookingSlice'
import { BookingModal } from '../../../components/Modals'
import { SelectDate } from '../../auth/layouts/SetInterview'
import Cookies from 'js-cookie'
import { meetingTypes } from '../../../constants'

export const BookSlot = ({ data }) => {
  const dispatch = useDispatch()
  const guestSlice = useSelector((state) => state.guestSlice)
  const bookingState = useSelector((state) => state.bookingSlice)
  const [createdAt, setCreatedAt] = useState(null)


  const handleBookSlot = async (slotId) => {
    dispatch(bookSlotAsync({ type: 'CONSULTATION_B2C_EXPERT', slotId: slotId, promo: data.promo }))
  }

  useEffect(() => {
    const savedShowModal = localStorage.getItem('showModal') === 'true'
    const savedTimer = parseInt(localStorage.getItem('timer'), 10)
    const savedCreatedAt = parseInt(localStorage.getItem('createdAt'), 10)
    const expirationMinutes = parseInt(
      localStorage.getItem('expirationMinutes'),
      10
    )
    const savedPaymentUrl = Cookies.get('paymentUrl')

    if (
      savedShowModal &&
      savedTimer > 0 &&
      !isNaN(savedCreatedAt) &&
      !isNaN(expirationMinutes)
    ) {
      setCreatedAt(savedCreatedAt)
      dispatch(setShowModal(true))
      dispatch(setTimer(savedTimer))
    }

    if (savedPaymentUrl) {
      console.log('Restored payment URL from cookies:', savedPaymentUrl)
      Cookies.set('paymentUrl', savedPaymentUrl, {
        expires: savedTimer / 86400,
      })
    }
  }, [dispatch])

  useEffect(() => {
    if (guestSlice.payment.id) {
      console.log('Payment data:', guestSlice.payment)
      const createdAt = Math.floor(
        new Date(guestSlice.payment.createdAt).getTime() / 1000
      )
      const expirationMinutes = guestSlice.payment.expirationMinutes

      if (isNaN(createdAt)) {
        console.error('Invalid createdAt:', guestSlice.payment.createdAt)
        return
      }
      if (isNaN(expirationMinutes)) {
        console.error(
          'Invalid expirationMinutes:',
          guestSlice.payment.expirationMinutes
        )
        return
      }

      const expirationTime = createdAt + expirationMinutes * 60
      const currentTime = Math.floor(Date.now() / 1000)
      const timer = expirationTime - currentTime

      console.log('expirationMinutes: ', expirationMinutes)
      console.log(
        'Calculated times - CreatedAt:',
        createdAt,
        'ExpirationTime:',
        expirationTime,
        'CurrentTime:',
        currentTime,
        'Timer:',
        timer
      )

      localStorage.setItem('showModal', true)
      localStorage.setItem('timer', timer)
      localStorage.setItem('createdAt', createdAt)
      localStorage.setItem('expirationMinutes', expirationMinutes)
      Cookies.set('paymentUrl', guestSlice.payment.url, {
        expires: timer / 86400,
      })

      window.location.assign(guestSlice.payment.url)

      setCreatedAt(createdAt)
      dispatch(setTimer(timer))
      dispatch(setShowModal(true))
    }
  }, [guestSlice.payment, dispatch])

  useEffect(() => {
    if (bookingState.showModal && bookingState.timer > 0) {
      const countdown = setInterval(() => {
        const currentTime = Math.floor(Date.now() / 1000)
        const expirationMinutes = parseInt(
          localStorage.getItem('expirationMinutes'),
          10
        )

        if (isNaN(expirationMinutes) || isNaN(createdAt)) {
          console.error(
            'Invalid expirationMinutes or createdAt during countdown'
          )
          clearInterval(countdown)
          return
        }

        const expirationTime = createdAt + expirationMinutes * 60
        const newTimer = expirationTime - currentTime

        console.log(
          `Countdown - Current Time: ${currentTime}, Expiration Time: ${expirationTime}, New Timer: ${newTimer}`
        )

        if (newTimer > 0) {
          dispatch(setTimer(newTimer))
          localStorage.setItem('timer', newTimer)
        } else {
          clearInterval(countdown)
          localStorage.removeItem('showModal')
          localStorage.removeItem('timer')
          localStorage.removeItem('createdAt')
          localStorage.removeItem('expirationMinutes')
          Cookies.remove('paymentUrl')
          dispatch(setShowModal(false))
        }
      }, 1000)

      return () => clearInterval(countdown)
    }
  }, [bookingState.showModal, bookingState.timer, dispatch, createdAt])

  const handleCloseModal = () => {
    dispatch(setShowModal(false))
    localStorage.removeItem('showModal')
    localStorage.removeItem('timer')
    localStorage.removeItem('createdAt')
    localStorage.removeItem('expirationMinutes')
    Cookies.remove('paymentUrl')
  }

  const handlePay = () => {
    const paymentURL = Cookies.get('paymentUrl')
    if (paymentURL) {
      console.log('Redirecting to:', paymentURL)
      window.location.assign(paymentURL)
      handleCloseModal()
    } else {
      console.error('Payment URL is not available')
    }
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`
  }

  if (!guestSlice.guest.access_token) {
    return <Navigate to={data.route} />
  }

  return (
    <div className='pb-5 pt-sm-5'>
      <p className='fs-2 mb-4'>Выберите дату</p>
      <SelectDate
        onCreate={handleBookSlot}
        type='CONSULTATION_B2C_EXPERT'
        access_token={guestSlice.guest.access_token}
        loading={guestSlice.loading}
        isRegister={false}
      />
      <BookingModal
        handleCloseModal={handleCloseModal}
        showModal={bookingState.showModal}
        timer={bookingState.timer}
        formatTime={formatTime}
        handlePay={handlePay}
      />
    </div>
  )
}
