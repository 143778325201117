import { useState } from 'react'
import { useNavigate } from 'react-router'
import { AppInput } from '../../../components/ui/AppInput'
import { COLORS, PADDINGS } from '../../../constants'
import { ReactComponent as ArrowRightSvg } from '../../../icons/python_landing/arrow_right.svg'
import { ReactComponent as CalendarSvg } from '../../../icons/python_landing/calendar.svg'
import { ReactComponent as CaseSvg } from '../../../icons/python_landing/case.svg'
import { ReactComponent as ClockSvg } from '../../../icons/python_landing/clock.svg'
import { ReactComponent as HeadphonesSvg } from '../../../icons/python_landing/headphones.svg'
import { ReactComponent as PersonAddSvg } from '../../../icons/python_landing/person_add.svg'
import { ReactComponent as StarSvg } from '../../../icons/python_landing/star.svg'
import '../styles.css'

export const AreaLanding = ({ title, img, text = '' }) => {
	const [state, setState] = useState({
		email: '',
	})
	const navigate = useNavigate()

	const isOzon = img === 'ozon_main.png'
	const isTamara = img === 'tamara_main.png'

	const lendings = {
		isOzon: (
			<div
				className={'w-max mb-5 ' + PADDINGS}
				style={{ backgroundColor: '#181B1F', marginTop: '-84px'  }}
			>
				<div className='w-max-inner mx-auto' style={{paddingTop: '84px', marginBottom: '80px'}}>
					<div className='row'>
						<div className='col-lg d-flex flex-column justify-content-center'>
							<p
								className='fs-1 mb-sm-4 mb-2 fw-bold pe-4'
								style={{ color: 'white' }}
							>
								Дарим 1000₽ на Ozon <br /> за прохождение интервью
							</p>
							<p
								className='fs-5 fs-sm-4 me-sm-5 pe-sm-5'
								style={{ color: 'white', opacity: '0.6' }}
							>
								{text}
							</p>
							<button
								onClick={() => navigate('/auth')}
								className='btn white rounded-pill px-5 py-sm-3 py-2 mt-sm-5 mt-4 me-lg-auto mb-5'
							>
								Начать
							</button>
						</div>
						<div
							className='col d-lg-flex d-none'
									
									style={{ overflow: 'hidden', marginBottom: '-60px', }}
						>
							<img
								src={require('../../../images/' + img)}
								className=''
                                alt='озон карточки'
								style={{
									height: 'auto',
									width: '100%',
									zIndex: 999,
									flexShrink: '0',
									objectFit: 'cover',
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		),
		isTamara: (
			<div
				className={'w-max mb-5' + PADDINGS}
				style={{ backgroundColor: '#181B1F', marginTop: '-84px' }}
			>
				<div className='w-max-inner mx-auto' style={{paddingTop: '84px'}}>
					<div className='row'>
						<div className='col-lg-7 d-flex flex-column justify-content-center'>
							<p
								className='fs-1 mb-sm-4 mb-2 fw-bold pe-4'
								style={{ color: 'white' }}
							>
								Дарим шоппер с Тамарой Кожемятько<br/> за прохождение интервью
							</p>
							<p
								className='fs-5 fs-sm-4 me-sm-5 pe-sm-5'
								style={{ color: 'white', opacity: '0.6' }}
							>
								{text}
							</p>
							<button
								onClick={() => navigate('/auth')}
								className='btn white rounded-pill px-5 py-sm-3 py-2 mt-sm-5 mt-4 me-lg-auto mb-5'
							>
								Начать
							</button>
						</div>
						<div
							className='col d-lg-flex d-none'
							style={{ overflow: 'hidden' }}
						>
							<img
								src={require('../../../images/' + img)}
								className='mx-auto'
                                alt='фото с шоппером с принтом Тамары Кожемятько'
								style={{
									height: 'auto',
									width: '70%',
									overflow: 'hidden',
									flexShrink: '0',
									objectFit: 'cover',
									marginBottom: '30px',
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		),
		default: (
			<div className='primary rounded-5 p-sm-5 p-4 mb-5 mt-sm-4'>
				<div className='row'>
					<div className='col-lg-8 d-flex flex-column'>
						<p className='fs-1 mb-4 fw-bold pe-4' style={{ color: 'white' }}>
							{title}
						</p>
						<p
							className='fs-5 fs-sm-4 mb-auto me-sm-5 pe-sm-5'
							style={{ color: 'white', opacity: '0.6' }}
						>
							{text
								? text
								: 'С помощью активного применения нейросетей на нашей платформе мы помогаем кандидатам максимально быстро найти работу – создаем нейрорезюме и сами рекомендуем тебя в компании'}
						</p>
						<button
							onClick={() => navigate('/auth')}
							className='btn sand rounded-pill px-5 py-2 mt-5 me-sm-auto'
						>
							Начать
						</button>
					</div>
					<div className='col d-md-flex d-none'>
						<img
							src={require('../../../images/' + img)}
							className='h-100'
                            alt="фото по теме"
							style={{ height: 'auto', width: '100%', overflow: 'hidden' }}
						/>
					</div>
				</div>
			</div>
		),
	}

	return (
		<div className='w-100 pb-5'>
			{
                lendings[isOzon ? 'isOzon' : isTamara ? 'isTamara' : 'default']
            }
			<div className='row gy-4 mb-5'>
				{[
					{
						icon: <ClockSvg fill={isOzon | isTamara ? COLORS.black : COLORS.primary} />,
						title: '30 минут на прохождение собеседования',
					},
					{
						icon: <CalendarSvg fill={isOzon | isTamara ? COLORS.black : COLORS.primary} />,
						title: 'Выбор удобной даты интервью',
					},
					{
						icon: (
							<HeadphonesSvg fill={isOzon | isTamara ? COLORS.black : COLORS.primary} />
						),
						title: 'Гарантированная обратная связь',
					},
					{
						icon: <StarSvg fill={isOzon | isTamara ? COLORS.black : COLORS.primary} />,
						title: 'Сами порекомендуем тебя в лучшие компании',
					},
				].map((d, i) => (
					<WhiteCard data={d} key={i} />
				))}
			</div>
			<div className='d-flex mt-sm-5'>
				<ArrowRightSvg height='20px' className='mt-2 me-3' />
				<div className='d-flex flex-column'>
					<p className='fs-2 fw-bold mb-4'>Как работает Хартл?</p>
					<p className='fs-5' style={{ maxWidth: '550px' }}>
						Здесь ты проходишь всего одно собеседование, во время которого мы
						сделаем тебе нейрорезюме – оно ответит на вопросы рекрутера за тебя,
						а также поможет показать тебя с самой выгодной стороны.
					</p>
				</div>
			</div>
			<p className='fs-5 mt-5 fw-bolded mb-4'>
				Для этого необходимо пройти всего 3 этапа:
			</p>
			<div className='row justify-content-center'>
				{[
					{
						img: isTamara ? 'tamara_step_1.png' : 'step1.png',
						title: 'Регистрация',
						icon: <PersonAddSvg fill='white' height='24px' />,
						text: 'Создай свою виртуальную копию, чтобы она проходила собеседования за тебя',
					},
					{
						img: isTamara ? 'tamara_step_2.png' : 'step2.png',
						title: 'Встреча с рекрутером',
						icon: <CalendarSvg fill='white' height='25px' />,
						text: 'На ней ты пообщаешься с нашим специалистом, который создаст тебе нейрорезюме, а также укажет на твои слабые места после собеседования',
					},
					{
						img: isTamara ? 'tamara_step_3.png' : 'step3.png',
						title: 'Получение оффера',
						icon: <CaseSvg fill='white' height='25px' />,
						text: 'В течение первой недели тебе начнут приходить первые приглашения, а чтобы ускорить этот процесс, ты можешь откликаться на вакансии',
					},
				].map((el, i) => (
					<BlackCard key={i} obj={el} isTamara={isTamara}/>
				))}
			</div>

			<div className='row mt-5 mb-sm-5'>
				<div className='col-lg-7 d-flex'>
					<div className='d-flex my-auto'>
						<ArrowRightSvg height='20px' className='mt-2 me-3' />
						<div className='d-flex flex-column'>
							<p className='fs-2 fw-bold mb-4'>Что такое нейрорезюме?</p>
							<p className='fs-5' style={{ maxWidth: '650px' }}>
								<span className='neuro_title fw-bolded'>Нейрорезюме </span>– это
								инструмент анализа кандидата с помощью общения с генеративной
								моделью искусственного интеллекта, обученной на данных из резюме
								и собеседования, которое проводит Хартл.
							</p>
							<p className='fs-5 mt-4' style={{ maxWidth: '650px' }}>
								Проще говоря, мы сделали свою версию ChatGPT, только для
								рекрутеров. С ней они смогут гораздо эффективнее изучить твою
								кандидатуру, а также не писать/звонить лишний раз, просто чтобы
								узнать работал ли ты с Git'ом.
							</p>
						</div>
					</div>
				</div>
				<div className='col d-none d-lg-flex'>
					<img
						src={require('../../../images/python/iphone.png')}
						className='h-100'
						style={{ height: 'auto', width: '100%', overflow: 'hidden' }}
					/>
				</div>
			</div>

			<div className='d-flex mt-5'>
				<ArrowRightSvg height='20px' className='mt-2 me-3' />
				<div className='d-flex flex-column'>
					<p className='fs-2 fw-bold mb-4'>С чем тебе поможет наш рекрутер?</p>
					<p className='fs-5' style={{ maxWidth: '550px' }}>
						Наши собеседования похожи на консультацию с психологом – мы не
						стараемся задавить кандидата вопросами, а наоборот стараемся ему
						помочь
					</p>
				</div>
			</div>
			<div className='row mt-4 gy-3 gy-sm-4 justify-content-center'>
				{[
					{
						img: 'help1.png',
						title: 'Преодолеть страх во время собеседования',
					},
					{
						img: 'help2.png',
						title: 'Отточить навык самопрезентации перед рекрутером',
					},
					{
						img: 'help3.png',
						title: 'Понять почему тебе отказывали в других местах',
					},
					{
						img: 'help4.png',
						title: 'Найти компанию, где тебе понравится',
					},
					{
						img: 'help5.png',
						title: 'Найти слабые места',
					},
				].map((el, i) => (
					<WhiteBlock key={i} data={el} />
				))}
			</div>
			<div className='primary d-flex mt-5' style={{ borderRadius: '40px' }}>
				<div className='d-flex flex-column m-auto py-5 px-4'>
					<div className='row mb-md-0 mb-1' style={{ maxWidth: '870px' }}>
						<div className='col-md-7 d-flex flex-column'>
							<p className='fs-2 fw-bold mb-4' style={{ color: 'white' }}>
								Находи работу с нами
							</p>
							<p className='fs-6 mb-3' style={{ color: 'white' }}>
								Для того чтобы начать поиск работы, тебе нужно пройти простую
								регистрацию – давай начнем с почты.
							</p>
							<p className='fs-4' style={{ color: 'white' }}>
								Почта
							</p>
						</div>
						<div className='col py-4 d-md-flex d-none'>
							<img
								src={require('../../../images/python/line.png')}
								className='mx-auto mt-auto'
								style={{
									maxHeight: '100px',
									width: 'auto',
									overflow: 'hidden',
								}}
							/>
						</div>
					</div>
					<div className='row' style={{ maxWidth: '870px' }}>
						<div className='col-md-9'>
							<AppInput
								placeholder='somebodysemail@gmail.com'
								value={state.email}
								onChange={v => setState({ ...state, email: v })}
							/>
						</div>
						<div className='col d-flex'>
							<button
								onClick={() => navigate('/auth', { state: state })}
								className='btn mb-md-3 mb-2 rounded-pill'
								style={{
									color: 'white',
									backgroundColor: '#2C2C2C',
									flex: '1',
								}}
							>
								Начать
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const WhiteBlock = ({ data }) => {
	return (
		<div className='col-xl-4 col-sm-6'>
			<div
				className='bg-white d-flex flex-column justify-content-center align-items-center p-4 h-100'
				style={{ borderRadius: '40px' }}
			>
				<img
                    alt='иллюстрация надписи'
					src={require('../../../images/python/' + data.img)}
					className='mx-auto'
					style={{ maxWidth: '100%', maxHeight: '250px' }}
				/>
				<p className='fs-6 text-center mt-3'>{data.title}</p>
			</div>
		</div>
	)
}

const BlackCard = ({ obj, isTamara }) => {
	return (
		<div className='col-xl-4 col-md-6 mb-3'>
			<div
				className='rounded-5 p-sm-4 p-3 h-100 mb-sm-5'
				style={{ backgroundColor: '#2C2C2C' }}
			>
				<div className='d-flex flex-column gy-2 flex-nowrap'>
					<div className='mb-3 pe-sm-auto'>
						<div className={' rounded-5 d-flex h-100 ' + (isTamara ? '' : 'p-sm-0 py-3 py-sm-4 bg-white')}>
							<img
                            alt='иллюстрация надписи'
								src={require('../../../images/python/' + obj.img)}
								className={('mx-auto')}
								style={{ maxWidth: '100%', maxHeight: isTamara ? '' : '250px' }}
							/>
						</div>
					</div>
					<div className='d-flex flex-column my-auto'>
						<div className='d-flex mb-md-3 my-1 mt-sm-3'>
							<p
								className='me-auto my-auto fs-4 fw-bolded'
								style={{ color: 'white' }}
							>
								{obj.title}
							</p>
							{obj.icon}
						</div>
						<p className='fs-5 mb-sm-4' style={{ color: '#A4A3A7' }}>
							{obj.text}
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

const WhiteCard = ({ data }) => {
	return (
		<div className='col-sm col-6'>
			<div
				className='bg-white rounded-4 d-flex flex-column justify-content-center align-items-center p-4 h-100'
				style={{ filter: 'drop-shadow(0 0 10 #C9B99FCC)' }}
			>
				{data.icon}
				<p className='fs-6 text-center mt-3'>{data.title}</p>
			</div>
		</div>
	)
}
