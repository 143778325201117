import { SOURCE } from "../constants"
import { setMeetings } from "../store/slices/userSlice"

export const updateUserMeetings = async (access_token, dispatch, include) => {
    const includeManager = include.indexOf("manager")
    if (includeManager) include = include.filter(i => i !== "manager")
    const includeString = (include.length ? "?" : "") + include.map(i => "include=" + i).join("&")
    const res = await fetch(SOURCE.url + "meetings/my?" + new URLSearchParams(includeString).toString(), {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": access_token
        }
    })
    if (!res.ok) return
    const data = await res.json()
    if (includeManager) {
        const result = []
        for (const meeting of data.items) {
            const res = await fetch(SOURCE.url + "meetingSlots/" + meeting.slotId + "?include=manager", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": access_token
                }
            })
            if (!res.ok) continue
            result.push({...meeting, slot: await res.json()})
        }
        await dispatch(setMeetings(result))
    } else {
        await dispatch(setMeetings(data.items))
    }
}

export const getAvailableMeetingSlots = async (access_token, types=[], query={}) => {
    try {
        const params = new URLSearchParams()

        for (const [key, value] of Object.entries(query)) {
            if (value) {
                params.append(key, value)
            }
        }
        types.forEach(el => params.append('types', el))
        const getData = async (page = 1) => {
            return await fetch(SOURCE.url + 'meetingSlots?available=true&page='+ page + '&' + new URLSearchParams(params), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': access_token
                }
            })
        }
        const response = await getData()
        if (!response.ok) return null
        const data = await response.json()

        if (data.totalPages > 1) {
            for (let i = 2; i <= data.totalPages; i++) {
                const response1 = await getData(i)
                const data1 = await response1.json()
                
                data.items.push(...data1.items)
            }
        }
        console.log(data)
        return data
    } catch (e) {
        console.error(e)
        return null
    }
}

export const deleteMeeting = async (access_token, id) => {
    try {
        const res = await fetch(SOURCE.url + `meetings/${id}`, {
            method: "DELETE",
            headers: {
                "Authorization": access_token,
            }
        })
        return res.ok
    } catch (e) {
        console.error(e)
        return false
    }
}

export const createMeeting = async (slotId, access_token, type, name, description) => {
    try {
        const res = await fetch(SOURCE.url + 'meetings',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': access_token
                },
                body: JSON.stringify({
                    type: type,
                    name: name,
                    slotId: slotId,
                    _requester: "APPLICANT"
                })
            }
        )
        if(!res.ok) return null
        return await res.json()
    } catch (e) {
        console.error(e)
        return null
    }
}

export const moveMeeting = async (access_token, meeting, slotId) => {
    try {
        const createRes = await fetch(SOURCE.url + "meetings", {
            method: "POST",
            headers: {
                'Authorization': access_token,
                'Content-Type': "application/json",
            },
            body: JSON.stringify({
                _requester: "APPLICANT",
                name: meeting.name,
                description: meeting.description,
                type: meeting.type,
                slotId
            })
        })
        if (!createRes.ok) return false
        const deleteRes = await fetch(SOURCE.url + `meetings/${meeting.id}`, {
            method: "DELETE",
            headers: {
                'Authorization': access_token,
            },
        })
        return deleteRes.ok
    } catch (e) {
        console.error(e)
        return false
    }
}

export const getMeetingVideo = async (access_token, id) => {
    try {
        const response = await fetch(SOURCE.url + `meetings/${id}/video`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': access_token
            }
        })
        if (!response.ok) return null
        const data = await response.json()
        return data
    } catch (e) {
        console.error(e)
        return null
    }
}