import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Loader } from '../../../components/Loader'
import { COLORS, SOURCE } from '../../../constants'
import { ReactComponent as BaselinePlaceSvg } from '../../../icons/baseline-place.svg'
import { ReactComponent as TwoCoinsSvg } from '../../../icons/two-coins.svg'
import { showErrorMsg } from '../../../store/slices/alertSlice'
import { DefaultAvatar } from '../../../components/ui/DefaultAvatar'
import { isProcessingPDF } from '../../../api/applicant'
import { deleteResume } from '../../../api/resume'
import { getHHResumes } from '../../../api/hhResumes'
import { importHHResume } from '../../../api/auth'

export const SelectStartResume = () => {
  const [state, setState] = useState({
    import_resumes: [],
    loaded: false,
  })
  const user = useSelector((state) => state.userSlice.user)
  const curResume = useSelector((state) => state.resumeSlice.resume)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    if (!user.access_token) return navigate('/auth')
    isProcessingPDF(dispatch, user.access_token).then((flag) => {
      console.log('SelectStartResume TOKEN', user.access_token)
      flag && navigate('/auth/next', { state: { step: 'create_resume' } })
    })
    getHHResumes(user.access_token).then((data) => {
      setState((p) => ({ ...p, import_resumes: data, loaded: true }))
    })
  }, [user.access_token])

  return !state.loaded ? (
    <Loader />
  ) : (
    <div className='d-flex flex-row flex-wrap gap-5 my-3 pb-5'>
      <div
        className='position-fixed top-0 start-0 w-100 h-100 min-vw-100 min-vh-100'
        style={{ backgroundColor: '#F8F7FB', zIndex: -1 }}
      ></div>
      {state.import_resumes.length > 0 && (
        <div className='col-12 col-lg d-flex flex-column gap-4'>
          <span className='fs-3 fw-semibold'>Резюме с HH.RU</span>
          {state.import_resumes.map((resume) => (
            <div className='rounded-3 px-4 py-3 bg-white' key={resume.id}>
              <div className='d-flex flex-row flex-wrap gap-3'>
                <div
                  className='rounded-3'
                  style={{
                    width: '60px',
                    height: '60px',
                    boxShadow: '0px 0px 12px 0px rgba(103, 87, 241, 0.30)',
                    background:
                      'radial-gradient(50% 50% at 50% 50%, #8C7FFF 0%, #6757F1 100%)',
                  }}
                ></div>
                <div className='flex-grow-1 d-flex flex-column justify-content-center'>
                  <span className='fs-5 fw-semibold'>
                    {user.firstName + ' ' + user.lastName}
                  </span>
                  <span
                    className='fs-6 fw-semibold'
                    style={{ color: COLORS.header_text }}
                  >
                    {resume.title || 'Новое резюме'}
                  </span>
                </div>
              </div>
              <div className='w-100 d-flex flex-row flex-wrap mt-4 gap-2 gap-lg-3'>
                <div className='d-flex flex-row align-items-center flex-nowrap'>
                  <BaselinePlaceSvg
                    className='me-1'
                    style={{ width: '22px', height: '22px' }}
                  />
                  <span
                    className='fs-6 fw-semibold'
                    style={{ color: COLORS.header_text }}
                  >
                    город
                  </span>
                </div>
                <div className='d-flex flex-row align-items-center flex-nowrap'>
                  <TwoCoinsSvg
                    className='me-2'
                    style={{ width: '22px', height: '22px' }}
                  />
                  <span
                    className='fs-6 fw-semibold'
                    style={{ color: COLORS.header_text }}
                  >
                    зарплата
                  </span>
                </div>
              </div>
              <button
                className='w-100 px-3 py-2 btn mt-3 fw-semibold'
                onClick={() =>
                  importResumeHandler(
                    resume.id,
                    user.access_token,
                    navigate,
                    dispatch,
                    curResume.id
                  )
                }
                style={{ backgroundColor: COLORS.neutral }}
              >
                Импортировать
              </button>
            </div>
          ))}
        </div>
      )}
      <div className='col-12 col-lg d-flex flex-column gap-4'>
        <span className='fs-3 fw-semibold'>Импорт из PDF</span>
        <div className='rounded-3 px-4 py-3 bg-white'>
          <div className='d-flex flex-row flex-wrap gap-3'>
            <div
              className='rounded-3'
              style={{
                width: '60px',
                height: '60px',
                boxShadow: '0px 0px 12px 0px rgba(103, 87, 241, 0.30)',
                background:
                  'radial-gradient(50% 50% at 50% 50%, #8C7FFF 0%, #6757F1 100%)',
              }}
            ></div>
            <div className='flex-grow-1 d-flex flex-column justify-content-center'>
              <span className='fs-5 fw-semibold'>
                {user.firstName + ' ' + user.lastName}
              </span>
              <span
                className='fs-6 fw-semibold'
                style={{ color: COLORS.header_text }}
              >
                Импортированное резюме
              </span>
            </div>
          </div>
          <div className='w-100 d-flex flex-row flex-wrap mt-4 gap-2 gap-lg-3'>
            <div className='d-flex flex-row align-items-center flex-nowrap'>
              <BaselinePlaceSvg
                className='me-1'
                style={{ width: '22px', height: '22px' }}
              />
              <span
                className='fs-6 fw-semibold'
                style={{ color: COLORS.header_text }}
              >
                город
              </span>
            </div>
            <div className='d-flex flex-row align-items-center flex-nowrap'>
              <TwoCoinsSvg
                className='me-2'
                style={{ width: '22px', height: '22px' }}
              />
              <span
                className='fs-6 fw-semibold'
                style={{ color: COLORS.header_text }}
              >
                зарплата
              </span>
            </div>
          </div>
          <button
            className='w-100 px-3 py-2 btn mt-3 fw-semibold'
            onClick={() => navigate('/auth/import_pdf_resume')}
            style={{ backgroundColor: COLORS.neutral }}
          >
            Загрузить PDF
          </button>
        </div>
      </div>
      <div className='col-12 col-lg d-flex flex-column gap-4'>
        <span className='fs-3 fw-semibold'>Новое резюме</span>
        <div className='rounded-3 px-4 py-3 bg-white'>
          <div className='d-flex flex-row flex-wrap gap-3'>
            <DefaultAvatar
              type='b2c'
              styles={{ width: '60px', height: '60px' }}
            />
            <div className='flex-grow-1 d-flex flex-column justify-content-center'>
              <span className='fs-5 fw-semibold'>
                {user.firstName + ' ' + user.lastName}
              </span>
              <span
                className='fs-6 fw-semibold'
                style={{ color: COLORS.header_text }}
              >
                Новое резюме
              </span>
            </div>
          </div>
          <div className='w-100 d-flex flex-row flex-wrap mt-4 gap-2 gap-lg-3'>
            <div className='d-flex flex-row align-items-center flex-nowrap'>
              <BaselinePlaceSvg
                className='me-1'
                style={{ width: '22px', height: '22px' }}
              />
              <span
                className='fs-6 fw-semibold'
                style={{ color: COLORS.header_text }}
              >
                город
              </span>
            </div>
            <div className='d-flex flex-row align-items-center flex-nowrap'>
              <TwoCoinsSvg
                className='me-2'
                style={{ width: '22px', height: '22px' }}
              />
              <span
                className='fs-6 fw-semibold'
                style={{ color: COLORS.header_text }}
              >
                зарплата
              </span>
            </div>
          </div>
          <button
            className='w-100 px-3 py-2 btn mt-3 fw-semibold'
            onClick={() => navigate('/auth/create_resume')}
            style={{ backgroundColor: COLORS.neutral }}
          >
            Создать резюме
          </button>
        </div>
      </div>
      {state.import_resumes.length === 0 && (
        <div className='col-12 col-lg'></div>
      )}
    </div>
  )
}

async function importResumeHandler(id, access_token, navigate, dispatch, cId) {
  try {
    const deleteRes = await deleteResume(access_token, cId)
    if (!deleteRes || (!deleteRes.ok && deleteRes.status !== 404))
      return dispatch(showErrorMsg('Ошибка при обновлении резюме'))
    const res = await importHHResume(access_token, id)
    if (!res.ok && res.status !== 409) {
      dispatch(showErrorMsg('Ошибка при импортировании резюме'))
    } else {
      navigate('/auth/next', { state: { step: 'create_resume' } })
    }
  } catch (error) {
    console.log(1, error)
    dispatch(showErrorMsg('Ошибка при импортировании резюме'))
  }
}
