// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/main/tg_banner.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../images/main/tg_banner_sm.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gray {
    background-color: var(--gray);
}
.orange {
    background-color: var(--orange);
}
.yellow {
    background-color: var(--yellow);
}
.black {
    background-color: var(--black);
}
.purple {
    background-color: var(--purple);
}
.skyblue {
    background-color: var(--skyblue);
}
.primary {
    background-color: var(--primary);
}

.sand {
    background-color: var(--sand);
}

#background_image_banner_1 {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: 85% 50%;
  
}

#background_image_banner_2 {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-size: cover;
    background-position: 10% 50%;
}


@media (min-width: 1280px) {
    .fs-1 {
        font-size: 3rem !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/screens/landings/styles.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;AACA;IACI,+BAA+B;AACnC;AACA;IACI,+BAA+B;AACnC;AACA;IACI,8BAA8B;AAClC;AACA;IACI,+BAA+B;AACnC;AACA;IACI,gCAAgC;AACpC;AACA;IACI,gCAAgC;AACpC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,yDAAwD;IACxD,sBAAsB;IACtB,4BAA4B;;AAEhC;;AAEA;IACI,yDAA2D;IAC3D,sBAAsB;IACtB,4BAA4B;AAChC;;;AAGA;IACI;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":[".gray {\n    background-color: var(--gray);\n}\n.orange {\n    background-color: var(--orange);\n}\n.yellow {\n    background-color: var(--yellow);\n}\n.black {\n    background-color: var(--black);\n}\n.purple {\n    background-color: var(--purple);\n}\n.skyblue {\n    background-color: var(--skyblue);\n}\n.primary {\n    background-color: var(--primary);\n}\n\n.sand {\n    background-color: var(--sand);\n}\n\n#background_image_banner_1 {\n    background-image: url('../../images/main/tg_banner.png');\n    background-size: cover;\n    background-position: 85% 50%;\n  \n}\n\n#background_image_banner_2 {\n    background-image: url('../../images/main/tg_banner_sm.png');\n    background-size: cover;\n    background-position: 10% 50%;\n}\n\n\n@media (min-width: 1280px) {\n    .fs-1 {\n        font-size: 3rem !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
