import { Navigate, useNavigate } from "react-router"
import { COLORS, SOURCE, meetingDescriptions, meetingTypes } from "../../../constants"
import { ReactComponent as CheckmarkSvg } from "../../../icons/consultations_landing/checkmark.svg"
import { ReactComponent as ClipboardSvg } from "../../../icons/clipboard.svg"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { showErrorMsg, showSuccessMsg } from "../../../store/slices/alertSlice"
import { createMeetingAsync } from "../../../store/slices/guestSlice"
import { Loader } from "../../../components/Loader"

export const SuccessPayment = ({ key_route }) => {
	const urlParams = new URLSearchParams(window.location.search)
	const meetingPaymentId = urlParams.get('meetingPaymentId')
	const code = urlParams.get('code')
	const navigate = useNavigate()

	const dispatch = useDispatch()
	const guestSlice = useSelector(state => state.guestSlice)
	useEffect(() => {
		(async () => {
			const payment = await setPaymentStatus(
				guestSlice.guest.access_token,
				meetingPaymentId,
				code,
				'SUCCESS'
			)
            if (payment.error === 'Payment expired') navigate(key_route + '/payment/expired')
            if (payment.error === 'Payment not found') navigate(key_route + '/payment/fail')
            // console.log(payment)
			if (payment.error) return dispatch(showErrorMsg('Произошла ошибка на сервере'))
			const { payload } = await dispatch(
				createMeetingAsync({
					type: 'CONSULTATION_B2C_EXPERT',
					slotId: payment.slotId,
					code: code,
				})
			)
            if (payload.error === 'MeetingSlot already booked') navigate('/expert/payment/reload')
                
		})()
	}, [])

	return !guestSlice.meeting.loaded ? <Loader /> : (
		<div className='w-100 mx-auto pt-md-5 pb-5 my-sm-5' style={{ flex: '1' }}>
			<div
				className='d-flex flex-column align-items-center text-center mx-auto'
				style={{ maxWidth: '800px' }}
			>
				<CheckmarkSvg className='mb-2' style={{ width: 'min(142px,23vw)' }} />
				<p className='fs-1 fw-bold ' style={{ color: COLORS.light_success }}>
					Оплата прошла успешно!
				</p>
				<p className='fs-3 mb-3'>Твоя ссылка на консультацию</p>
				<div
					className='d-inline-block rounded-3 p-sm-3 p-2 d-flex flex-nowrap mb-3 bg-white w-100'
					style={{ cursor: 'pointer', }}
					onClick={() =>
						navigator.clipboard.writeText(guestSlice.meeting.roomUrl) &&
						dispatch(showSuccessMsg('Скопировано в буфер обмена'))
					}
				>
					<span className='d-inline-block text-decoration-underline text-truncate me-auto' style={{ overflow: 'hidden', maxWidth: '90%' }} >
						{guestSlice?.meeting?.roomUrl || ''}
					</span>
					<ClipboardSvg className='ms-3' width={'23px'} height='23px' />
				</div>
				<p className='fs-5 mb-sm-5 mb-4' style={{ color: COLORS.hr }}>
					Также ссылка будет выслана на твою почту
				</p>
				<button
					className='btn primary rounded-pill py-sm-3 py-2 px-5 mt-sm-5'
					onClick={() => <Navigate to={key_route} />}
				>
					Вернуться на главную страницу
				</button>
			</div>
		</div>
	)
}

export const setPaymentStatus = async (
	token,
	meetingPaymentId,
	code,
	status
) => {
	const response = await fetch(
		SOURCE.url + `meetingPayments/${meetingPaymentId}`,
		{
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: token,
			},
			body: JSON.stringify({
				code: code,
				status: status,
			}),
		}
	)
	const data = await response.json()

	if (!response.ok) {
		return {
			status: response.status,
			...data,
		}
	}
	return data
}
