import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  quotePlugin,
} from '@mdxeditor/editor'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, useNavigate, useParams } from 'react-router'
import { addViewed, applyToVacancy, getVacancy } from '../../api/vacancies'
import { Loader } from '../../components/Loader'
import { FastResponseModal } from '../../components/Modals'
import { DefaultAvatar } from '../../components/ui/DefaultAvatar'
import {
  COLORS,
  SOURCE,
  datePlusTime,
  isResumeFilled,
  labelsByEmploymentType,
  labelsByExperience,
  labelsBySalaryCurrency,
  labelsByWorkplaceModel,
} from '../../constants'
import { showErrorMsg, showSuccessMsg } from '../../store/slices/alertSlice'
import { NavbarToBackSample } from './../../components/NavbarToBackSample/NavbarToBackSample'
import CompanyCard from './components/myCompanyCard/CompanyCard'
import { NotFoundPage } from '../system/NotFoundPage'

export const Vacancy = () => {
  const { id } = useParams()
  const user = useSelector((state) => state.userSlice.user)
  const resume = useSelector((state) => state.resumeSlice.resume)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    vacancy: {},
    viewed: false,
    loaded: false,
    fastResponseModalShown: false,
    guestResponseSent: [],
    notFound: false,
  })
  const [alreadyGo, setAlreadyGo] = useState(false)
  useEffect(() => {
    const guestResponseSent = JSON.parse(
      localStorage?.getItem('guestResponseSent') || '[]'
    )
    console.log(guestResponseSent)
    setState((p) => ({ ...p, guestResponseSent }))

    if (user.loaded) {
      (async () => {
        let include = ['employer']
        if (user.id) include.push('responses')
        const vacancy = await getVacancy(user.access_token, id, {
          include: include,
        })
        if (vacancy === null) {
          return dispatch(showErrorMsg('Ошибка при загрузке данных'))
        }
        if (vacancy === 404) {
          setState((p) => ({ ...p, notFound: true }))
        }

        setState((p) => ({ ...p, vacancy: vacancy, loaded: true }))

        document.title = `Хартл | ${vacancy.name}`
      })()
    }

    if (!state.viewed && user.loaded) {
      (async () => {
        const isViewed = await addViewed(user.access_token, id)
        setState((p) => ({ ...p, viewed: isViewed }))
      })()
    }
  }, [user.loaded])

  const applyHandler = async () => {
    if (!resume.id) {
      navigate('/acccount/resume')
      return dispatch(showErrorMsg('Сначала создайте резюме'))
    }
    if (!isResumeFilled(resume))
      return dispatch(showErrorMsg('Заполните резюме'))
    const apply = await applyToVacancy(user.access_token, id)
    setAlreadyGo(true)
    if (!apply) return dispatch(showErrorMsg('Произошла ошибка на сервере'))
    return dispatch(showSuccessMsg('Отклик отправлен'))
  }

  if (state.notFound) {
    <Navigate to={<NotFoundPage />} replace />
  }

  return !state.loaded || !user ? (
    <Loader />
  ) : (
    <div className='d-flex flex-column pb-5 pt-sm-5'>
      <NavbarToBackSample>
        <h1 className='fs-3 d-none d-md-block'>{state.vacancy.name}</h1>
      </NavbarToBackSample>

      {state.fastResponseModalShown && (
        <FastResponseModal
          shown={state.fastResponseModalShown}
          set={setState}
          vacancyId={state.vacancy.id}
        />
      )}
      <div className='row gap-sm-3 gap-2'>
        <div className='col-xl-9 col-lg-8 col-md-7'>
          <div className='d-flex flex-column bg-white w-sm-max rounded p-sm-5 p-4'>
            <EmployerBlock vacancy={state.vacancy} token={user.access_token} />
            <VacancyBlock vacancy={state.vacancy} resume={resume} />
          </div>
        </div>
        <div className='col'>
          {/* About company */}
          <CompanyCard vacancy={state.vacancy} />

          {/* About location, salary... */}
          <div className='bg-white w-sm-max rounded fs-6 p-sm-5 p-4 mt-sm-3 mt-2'>
            <p className='mb-2'>
              <span className='fw-bolded'>Локация: </span>
              {state.vacancy.city && state.vacancy.city + ' + '}
              <span className='fst-italic'>
                {labelsByWorkplaceModel[state.vacancy.workplaceModel]}
              </span>
            </p>
            <p className='mb-2'>
              <span className='fw-bolded'>Занятость: </span>
              {labelsByEmploymentType[state.vacancy.employmentType]}
            </p>
            <p className='mb-2'>
              <span className='fw-bolded'>Опыт: </span>
              {labelsByExperience[state.vacancy.experience]}
            </p>
            <p>
              <span className='fw-bolded'>Зарплата: </span>
              {state.vacancy.salary
                ? `${
                    state.vacancy.salary.toLocaleString() +
                    ' ' +
                    labelsBySalaryCurrency[state.vacancy.salaryCurrency]
                  }`
                : 'З/п на собеседовании'}
            </p>
            {/* <div className='mb-2'>
                        <p className='fw-bolded'>Роль в команде:&nbsp;</p>
                        {labelsByTeamRole[state.vacancy.teamRole]}&nbsp;
                    </div>
                    <div className='mb-2'>
                        <p className={classes.VacancySideText}>Кому отчитываться:&nbsp;</p>
                        {labelsByReportingForm[state.vacancy.reportingForm]}&nbsp;
                    </div> */}
          </div>
          {!user.access_token &&
            !state.guestResponseSent.includes(state.vacancy.id) && (
              <button
                className='btn success rounded py-2 px-4 mt-4 w-100'
                onClick={() =>
                  setState((p) => ({ ...p, fastResponseModalShown: true }))
                }
              >
                Быстрый отклик
              </button>
            )}
          <button
            disabled={
              state.vacancy.responses?.filter((r) => r.candidateId === user.id)
                .length === 1 || alreadyGo
            }
            onClick={applyHandler}
            className='btn primary rounded py-2 px-4 mt-3 w-100'
          >
            {!user.access_token
              ? 'Авторизоваться'
              : state.vacancy.responses?.filter(
                  (r) => r.candidateId === user.id
                ).length === 1 || alreadyGo
              ? 'Отклик отправлен'
              : 'Откликнуться'}
          </button>
        </div>
      </div>
    </div>
  )
}

const EmployerBlock = ({ vacancy, token }) => {
  const navigate = useNavigate()
  return (
    <div className='row gy-2'>
      <div className='col-auto d-flex flex-wrap'>
        <DefaultAvatar
          src={SOURCE.url + `employers/${vacancy.employer.id}/avatar`}
          type='b2b'
          className='rounded img-fluid'
          style={{
            objectFit: 'cover',
            flexShrink: '0',
            width: '70px',
            height: '70px',
          }}
        />
      </div>
      <div className='col d-flex flex-column justify-content-center'>
        <p className='fs-4 mb-1 fw-bolded'>
          {vacancy.employer?.name || 'Компания'}
        </p>
        <p className='fs-5' style={{ color: COLORS.header_text }}>
          {vacancy.city}
        </p>
      </div>
      <div className='col-sm-auto d-flex'>
        <p className='fs-6 my-auto' style={{ color: COLORS.header_text }}>
          Опубликовано {datePlusTime(vacancy.createdAt)}
        </p>
      </div>
    </div>
  )
}

const VacancyBlock = ({ vacancy, resume }) => {
  let skills = (vacancy.keySkills || []).sort((a, b) => {
    const aIndex = resume.skills?.indexOf(a)
    const bIndex = resume.skills?.indexOf(b)
    if (aIndex === -1 && bIndex === -1) {
      return 0
    } else if (aIndex === -1) {
      return 1
    } else if (bIndex === -1) {
      return -1
    } else {
      return aIndex - bIndex
    }
  })

  return (
    <div className='d-flex flex-column'>
      <p className='fs-2 my-sm-3 my-2 fw-bolded'>{vacancy.name}</p>
      <div className='row g-2 g-md-3'>
        {skills.map((el, i) =>
          !el ? (
            <></>
          ) : (
            <div className='col-auto' key={i}>
              <p
                className='rounded fs-6 py-sm-2 py-1 px-3'
                style={{
                  backgroundColor:
                    resume?.skills?.indexOf(el) >= 0
                      ? COLORS.warning
                      : COLORS.light_neutral,
                  color: resume?.skills?.indexOf(el) >= 0 ? 'white' : '',
                }}
              >
                {el}
              </p>
            </div>
          )
        )}
      </div>
      {vacancy.description ? (
        <MDXEditor
          markdown={vacancy.description}
          style={{ padding: '.75rem 0 !important' }}
          contentEditableClassName='p-0 mt-4'
          plugins={[
            headingsPlugin(),
            quotePlugin(),
            listsPlugin(),
            markdownShortcutPlugin(),
          ]}
          readOnly
        />
      ) : (
        <p className='fs-4'>Нет описания вакансии</p>
      )}
    </div>
  )
}
