import {SOURCE} from "../constants"

export const getMyVacancyResponses = async (token, query = '?') => {
    try {
        const response = await fetch(`${SOURCE.url}vacancyResponses/my${query === '?' ?  '' : query}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            }
        })
        if (!response.ok) return null
        return await response.json()
    } catch (error) {
        console.error(error)
        return null
    }
}

export const deleteVacancyResponse = async (access_token, id) => {
    try {
        const response = await fetch(`${SOURCE.url}vacancyResponses/${id}`, {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': access_token,
            }
        })
        return response.ok
    } catch (error) {
        console.error(error)
        return false
    }
}