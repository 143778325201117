import { ReactComponent as BaselinePlace } from '../../icons/baseline-place.svg'
import { ReactComponent as TwoCoins } from '../../icons/two-coins.svg'
import { COLORS } from '../../constants'
import { SaveResumeButton } from '../SaveResumeButton/SaveResumeButton'
import { PublishResumeButton } from '../PublishResumeButton/PublishResumeButton'
import { DefaultAvatar } from '../ui/DefaultAvatar'

export default function ProfileResumeBlock({
  user,
  resume,
  avatarUrl,
  showPublishBtn = true,
}) {
  return (
    <>
      <div className='row gap-2' style={{ wordBreak: 'break-all' }}>
        {/* Personal Details Block */}
        <div className='col-auto'>
          <DefaultAvatar
            src={avatarUrl}
            alt='avatar'
            className='rounded img-fluid'
            style={{
              objectFit: 'cover',
              width: 'min(110px,20vw)',
              height: 'min(110px,20vw)',
            }}
          />
        </div>
        <div className='col d-flex'>
          <div className='d-flex flex-column my-auto'>
            <span className='fs-4 fw-semibold'>
              {user.firstName} {user.lastName}
            </span>
            <span
              className='fs-5 fw-semibold'
              style={{ color: COLORS.header_text }}
            >
              {resume.title || 'Специальность не указана'}
            </span>
            <ExtraInfo display='d-sm-flex d-none mt-2' resume={resume} />
          </div>
        </div>
      </div>
      <ExtraInfo display='d-sm-none d-flex' resume={resume} />

      {/* About block */}
      {resume.summary && <div className='row'>
        <span className='fs-4 fw-semibold mb-3'>О себе</span>
        <div
          className='d-flex flex-row flex-wrap gap-3'
          style={{
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            hyphens: 'auto',
            whiteSpace: 'pre-wrap',
          }}
        >
          {resume.summary}
        </div>
      </div>}

      {/* Skills Block */}
      {(resume.skills || []).length > 0 && (
        <div className='row'>
          <span className='fs-4 fw-semibold mb-3'>Навыки</span>
          <div className='d-flex flex-row flex-wrap gap-2 gap-md-3'>
            {(resume.skills || []).map(
              (s, i) =>
                s && (
                  <span
                    key={i}
                    className='fs-6 fw-medium px-3 py-2 rounded'
                    style={{ backgroundColor: COLORS.light_neutral }}
                  >
                    {s}
                  </span>
                )
            )}
          </div>
        </div>
      )}
      {/* Experience Block */}
      {(resume.experience || []).length > 0 && (
        <div className='row gap-3'>
          <span className='fs-4 fw-semibold'>Опыт работы</span>
          {(resume.experience || []).map((e, i) => (
            <div className='row' key={i}>
              <div className='col-12 col-sm-5 col-md-4 col-lg-5 col-xl-4 d-flex flex-column'>
                <span
                  className='fs-6 fw-medium mb-1'
                  style={{ color: COLORS.primary }}
                >
                  {e.company}
                </span>
                <span className='fs-6 fw-medium'>{e.position}</span>
                <span className='fs-6 fw-medium'>
                  {e.startMonth}.{e.startYear} -{' '}
                  {e.endMonth ? e.endMonth + '.' + e.endYear : 'по н.в.'}
                </span>
              </div>
              <div className='col'>
                <span
                  className='fs-6 fw-medium'
                  style={{
                    whiteSpace: 'pre-wrap',
                    overflow: 'visible',
                    display: 'block',
                    wordWrap: 'break-word',
                  }}
                >
                  {e.description}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
      {/* Education Block */}
      {(resume.education || []).length > 0 ? (
        <div className='row gap-3'>
          <span className='fs-4 fw-semibold'>Образование</span>
          {(resume.education || []).map((e, i) => (
            <div className='row' key={i}>
              <div className='col-12 col-sm-5 col-md-4 col-lg-5 col-xl-4 d-flex flex-column'>
                <span
                  className='fs-6 fw-medium'
                  style={{ color: COLORS.primary }}
                >
                  {e.name}
                </span>
                <span className='fs-6 fw-medium'>
                  {e.degree}, {e.startYear}
                  {e.endYear ? '-' : ''}
                  {e.endYear}
                </span>
              </div>
              <div className='col col-md-6'>
                <span
                  className='fs-6 fw-medium'
                  style={{
                    whiteSpace: 'pre-wrap',
                    overflow: 'visible',
                    display: 'block',
                    wordWrap: 'break-word',
                  }}
                >
                  {e.description}
                </span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
      {/* Certificates Block */}
      {(resume.certificates || []).length > 0 && (
        <div className='row gap-3'>
          <span className='fs-4 fw-semibold'>Курсы и сертификаты</span>
          {(resume.certificates || []).map((e, i) => (
            <div className='row' key={i}>
              <div className='col-12 col-sm-5 col-md-4 col-lg-5 col-xl-4 d-flex flex-column'>
                <span
                  className='fs-6 fw-medium'
                  style={{ color: COLORS.primary }}
                >
                  {e.name}
                </span>
                <span className='fs-6 fw-medium'>{e.year}</span>
              </div>
              <div className='col'>
                <span
                  className='fs-6 fw-medium'
                  style={{
                    whiteSpace: 'pre-wrap',
                    overflow: 'visible',
                    display: 'block',
                    wordWrap: 'break-word',
                  }}
                >
                  {e.description}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
      {/* Languages Block */}
      {(resume.languages || []).length > 0 && (
        <div className='row'>
          <span className='fs-4 fw-semibold mb-3'>Языки</span>
          <div className='d-flex flex-row flex-wrap gap-3'>
            {(resume.languages || []).map((e, i) => (
              <span
                key={i}
                className='fs-6 fw-medium px-3 py-2 rounded'
                style={{ backgroundColor: COLORS.light_neutral }}
              >
                {e.name} -{' '}
                <span style={{ color: COLORS.primary }}>{e.level}</span>
              </span>
            ))}
          </div>
        </div>
      )}
      {/* Resume controls */}
      <div className='row gap-2 gap-sm-4'>
        <SaveResumeButton resume={resume} user={user} />
        {false && showPublishBtn && (
          <PublishResumeButton
            access_token={user.access_token}
            resume={resume}
          />
        )}
      </div>
    </>
  )
}

const ExtraInfo = ({ display, resume }) => {
  return (
    <div
      className={'flex-column flex-sm-row flex-wrap gap-2 gap-sm-4 ' + display}
    >
      <div className='c d-flex flex-row gap-2'>
        <BaselinePlace />
        <span
          className='fs-6 fw-semibold'
          style={{ color: COLORS.header_text }}
        >
          {resume.city || 'Город не указан'}
        </span>
      </div>
      <div className='c d-flex flex-row gap-2'>
        <TwoCoins />
        <span
          className='fs-6 fw-semibold'
          style={{ color: COLORS.header_text }}
        >
          {resume.desiredSalary?.toLocaleString() || 'з/п не указана'}
          {resume.desiredSalary?.toLocaleString() && ' руб/мес'}
        </span>
      </div>
    </div>
  )
}
